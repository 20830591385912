import React from 'react'
import "./Politics.css"
import "./InterestingStories.css"
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import NewPlayIcon from '../../NewPlayIcon'
import PlayIcon from '../../PlayIcon';



const InterestingStories = (props) => {
  var ournews = props.ourdata;
  var ourvideos = ournews.video_list;
  const channel_seo_name = props.channelseoname;
  //console.log(ournews);
  // if(ournews.video_list){
  if (ournews.video_list_cnt > 0) {
    return (
      <div>  <div>
        <div className='polic'>
          <Row><Col lg={9} md={9} sm={9} xs={9}><h2 className="head headd">{ournews.tag_title}</h2></Col><Col lg={3} md={3} sm={3} xs={3}><Link to={`/AllNewsPage/${channel_seo_name}/${ournews.menu_cat_name}/${ournews.seo_tag_title}`} className='moed'>More +</Link></Col></Row>



          <Row>
            {ourvideos &&
              ourvideos.map(function (sublist, subindex) {
                if(subindex <= 5){
                return (

                  <Col lg={6} md={4} sm={4} xs={6}>
                    <div className='topnew'>
                      <Link to={`/NewsView/${channel_seo_name}/${ournews.menu_cat_name}/${ournews.seo_tag_title}/${sublist.sv_title_seo}`}>
                        <div className='newsf'>
                          <img src={sublist.medium_img} />
                          <PlayIcon />
                        </div>
                        <h4>{sublist.sv_title}</h4>
                      </Link>



                    </div>
                  </Col>
                )
                }
              })
            }

          </Row>


        </div>
      </div></div>
    )
  }
}

export default InterestingStories