import React from 'react'
import { Container, Row ,Col } from 'react-bootstrap'
import "./WeekSchedule.css"

const WeekScheduleHeading = () => {
  return (
    <div>
      <div className='SubpageTitleBar'>
        <Container fluid className='pad-left-80 pad-right-80'>
          <Row className='shoed'>
          <Col lg={6} md={6}>
          <h4 className='allshiw'>SCHEDULE</h4>
            </Col>
          </Row>
        </Container>
        </div>
    </div>
  )
}

export default WeekScheduleHeading