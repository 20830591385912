import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaLandmark, FaMapMarkerAlt, FaPhone,  } from 'react-icons/fa'

const PrivacyPolicy = () => {
  return (
    <div>  
       <div className='SubpageTitleBar'>
        <Container>
          <Row className='shoed'>
          <Col lg={6} md={6}>
          <h4 className='allshiw'>PrivacyPolicy</h4>
            </Col>
          </Row>
        </Container>
        </div>

        <div className='cms-content'>
          <Container>
            <div className='page-card'>
              <Row>
          <Col>
          <p>Etv.co.in is website of Eenadu Television Private Ltd (ETPL) havs a privacy policy for all users of the website with regard to the information we collect from them and other concerns of privacy of the users. For all online transactions we use third party services. If you have questions or concerns regarding thispolicy, please email to us on “privacypolicy</p>
          <p>All Etv.co.in visitors will be able to browse the site, search classifiedsand view any articles or features posted on our site, without entering any personal information.</p>
          <p>Some of the essential information gathered from users and guests who visit Etv.co.in includes, but may not be limited to, email address, first name, last name, a user-specified password, mailing address, zip code, mobile number, telephone number or fax number.

</p>
<p>If users wish to buy any online products frometv.co.in, we may collect some additional information from them, including a billing address, a credit card number and a credit card expiration date and tracking information from This information collected from users is NOT shared:</p>

<p>The information gathered from Etv.co.in users is shared with any individual or organization only with the consent of the user. Users registering on "Etv.co.in" shall be automatically consenting for receiving calls or Text Messages and emails.

</p>
<p>Except where users are expressly informed, Etv.co.in does not sell, rent, or loan any identifiable information at the individual level regarding its customers to any third party. All information provided by users is held with the utmost care and security. However, Etv.co.in is also bound to cooperate fully should a situation arise where we are required by law or legal process to provide information about a customer.

</p> 
<p></p>      

<h3>All visitors need NOT pay:</h3>
<p>Visitors of Etv.co.in may browse the site, search the ads and view any articles or features our site has to offer without entering any personal information or paying money.</p>

<h3>Links to external websites:

</h3>


<p>Etv.co.in may contain links to external websites. Etv.co.inshall not be responsible for the privacy practices of external Web sites and users are advised to check the privacy policy of such third party websites. We request you to be fully aware of this when you leave Etv.co.in. Do read the privacy statements of each and every web site that collects personally identifiable information.

</p>  
<p>This privacy statement applies solely to information collected by Etv.co.in</p>
<h3>Cookies on Etv.co.in:</h3>
<p>Cookies on Etv.co.in are used to deliver various services and keep track of your personal preferences. If you do not want cookies to collect information on Etv.co.in, most browsers contain a function that allows a user to deny cookies on websites. Alternatively you can also delete all cookie files on your computer. Please note that disabling the cookie feature on your browser or deleting cookie files from your computer will render you unable to access certain features on Etv.co.in, as the use of cookies is a necessary part of the Etv.co.in technology. Etv.co.in uses cookies only to:</p>
<ul><li>Provide its services</li>
<li>Deliver content specific to your interests</li>
<li>Save your password (so you do not have to re-enter it each time you visit different pages on our site) and for other purposes.</li>

</ul>
<p>Please note: Cookies are used only to recollect information sent to your computer from Etv.co.in. We CANNOT access any information not sent by Etv.co.in site. Some of our partners may use cookies on our site (for example, advertisers). However, we have no access to or control over these cookies.</p>
<p>Changes in Etv.co.in privacy policy: ETPL reserves right to amend the privacy policy of Etv.co.in. Users are advised to periodically check our privacy policy for any amends.</p>
<p>Any sensitive information (such as a credit card number) provided during registration/order process information, is encrypted and is protected with the standard encryption software in the industry – SSL as practiced by respective payment gateway.</p>
<p>Apart from using SSL encryption, Etv.co.in takes every measure in its power to protect sensitive information Some of the staff users at Etv.co.in are granted access to such personally identifiable information, needed for performing specific tasks (for example, a billing clerk or a customer service representative.)</p>
<p>Etv.co.in uses email extensively to users may receive emails for the following services:</p>
<ul>
  <li>Newsletters with updates on the latest features on our site.

</li>
<li>Announcements of special events

</li>
</ul>
<h3>Contact Etv.co.in:</h3>
<p> If you have any questions about this privacy statement, the practices of Etv.co.in, or your dealings with this Web site, please email us or contact us at.</p>

<div className='address-block'>
  <h3>Eenadu Television Private Limited</h3>
  <p><FaLandmark/>Ramoji Film City</p> 

  <p><FaPhone/>08415-246555</p>               
    <div className='d-flex'>
      <FaMapMarkerAlt className='me-3'/>
        <p>Ramoji Film City Hyderabad</p>
    </div>
    </div>

<p>You are also advised to peruse terms and conditions.

</p>

</Col>
              </Row>
            </div>
          </Container>
        </div> 

 </div>
 
  )
}

export default PrivacyPolicy