import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
const Disclaimer = () => {
  return (
    <div>  
      <div className='SubpageTitleBar'>
        <Container>
          <Row className='shoed'>
          <Col lg={6} md={6}>
          <h4 className='allshiw'>Disclaimer</h4>
            </Col>
          </Row>
        </Container>
        </div>

        <div className='cms-content'>
          <Container>
            <div className='page-card'>
              <Row>
                <Col lg={12}>
                  <h1>Disclamier Info</h1>
                  <p>All rights in this web site are reserved by Eenadu Television Private Limited (ETPL). Neither this website nor any part of it may be downloaded, reproduced, stored, distributed, adapted, translated or transmitted in any form or by any means or medium, whether now known or hereinafter invented, without prior permission of ETPL. Any breach of this condition would entail civil and criminal penalties. ETPL neither warrants nor represents that your use of materials displayed on the Site will not infringe rights of third parties not owned by or affiliated with Universe.</p>
                  <p>All material provided in this site are for the purpose of information only. While all efforts have been taken to ensure accuracy of the information, anyone who acts on the basis of information provided will have to exercise caution and judgment when acting on the basis of information provided. ETPL and its associates cannot be held responsible for inaccuracies, if any, in the information provided. The company shall not be liable for loss or damage or other inconvenience suffered by any one as a result of viewing this site or using any information provided in this site. This site should be viewed purely as a source of information and should not be taken to guarantee or warranty for performance of any activity. Information in some sections of this website has been obtained/compiled from sources that are considered reliable. ETPL does not warranty that the contents of the sites are free from infection by viruses or anything else, which has contaminating or destructive properties. ETPL cannot be responsible for any information contained in any website linked from this website. ETPL and its associates will not be responsible for any loss or damage that could result by third parties of any information available to you through this site. All material provided in this site are subject to Indian laws prevailing from time to time and shall not be applicable to any countries/regions where any of the material provided herein is prohibited or ineffective. While all procedures and tips provided are expected to convey general information, they may not be exhaustive or complete in all respects. The actual procedures/documents to be submitted may vary. ETPL does not guarantee its accuracy, timeliness or completeness.
                  <p>By accessing and browsing the Site, you accept, without limitation or qualification, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use the Site.</p>
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div> 
 </div>
  )
}

export default Disclaimer