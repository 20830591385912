import React from 'react'
import { FaChevronLeft,FaChevronRight } from 'react-icons/fa'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick"; 
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
 import "./WeekSchedule.css"



const WeekSchedule = (props) => {

  const SampleNextArrow = (props)  => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}

      ><div style={{background:"#ffffff",width:"32px",height:"32px",lineHeight:"20px",borderRadius:"20px",display:"inline-block", boxShadow:"0px 3px 5px rgba(0, 0, 0, 0.1)"}}>
        
       <FaChevronRight style={{ ...style, color: "black", fontSize: "14" ,margin:'9px 0px 0px 10px'}} /></div> </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><div style={{background:"#ffffff",width:"32px",height:"32px",lineHeight:"20px",borderRadius:"20px",display:"inline-block", boxShadow:"0px 3px 5px rgba(0, 0, 0, 0.1)"}}>
 < FaChevronLeft style={{ ...style, color: "black", fontSize: "14" ,margin:'9px 0px 0px 10px'}} />
      </div>
       </div>
    );
  }
  
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll:1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]


  };
  return (
 <div className='colod'>
  <div  className='colod-in'>
 <Container >

    <h2 className='head'>{props.name}</h2>

    <div className='feature-main'>
    <div className='feature'>
    <div className='fea-contact'>
      <Slider {...settings}>
      <div >
        <div className='carousel-card'>
            <Link to="/WeekScheduleViewVideo">
              <img src="../assets/images/show-01.jpg" alt='' />
              <h4>Manasantha Nuve | 10th November | Fu....</h4>
            </Link>
        </div>
      </div>
      <div >
        <div className='carousel-card'>
        <Link to="/WeekScheduleViewVideo">
          <img src="../assets/images/show-02.jpg" alt='' />
            <h4>Manasantha Nuve | 10th November | Fu....</h4>
          </Link>
        </div>
      </div>
      <div >
        <div className='carousel-card'>
        <Link to="/WeekScheduleViewVideo">
          <img src="../assets/images/show-03.jpg" alt='' />
            <h4>Manasantha Nuve | 10th November | Fu....</h4>
            </Link>
        </div>
      </div>
      <div >
        <div className='carousel-card'>
        <Link to="/WeekScheduleViewVideo">
          <img src="../assets/images/show-04.jpg" alt='' />
            <h4>Manasantha Nuve | 10th November | Fu....</h4>
          </Link>
        </div>
      </div>
      <div >
        <div className='carousel-card'>
        <Link to="/WeekScheduleViewVideo">
            <img src="../assets/images/show-05.jpg" alt='' />
            <h4>Manasantha Nuve | 10th November | Fu....</h4>
          </Link>
        </div>
      </div>
      <div >
        <div className='carousel-card'>
        <Link to="/WeekScheduleViewVideo">
            <img src="../assets/images/show-05.jpg" alt='' />
            <h4>Manasantha Nuve | 10th November | Fu....</h4>
          </Link>
        </div>
      </div>
   
    </Slider>
    </div>
    </div>
    </div>

    </Container>

    </div>
    </div>

  )
}

export default WeekSchedule