import React,{useState,useEffect, useRef} from "react";
 import  "./Latestnews.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link} from "react-router-dom";
import Marquee from "react-fast-marquee";
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import {  FaFileAlt, FaLink,FaVideo } from "react-icons/fa";
import NewPop from "./NewPop";
import { NewsPopVideo } from "./NewsPopVideo";
import { gettajanewsurl,tajanewstagsid,getchannelbyseoname} from "../../../globalURL";
import NewsAds from "./NewsAds";

const Latestnews = (obj) => {

  
  const chnl_id = obj.chnlId;
  const channel_seo_name = obj.channel_seo_name;
  //alert(channel_seo_name);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [shows, setShowd] = useState(false);
  const handleClos = () => setShowd(false);

  const [ourTajanews, ourSetTajanews] = useState([]);
  const [ourtagdata,ourSettagdata] = useState([]);
  const [ourtagvideodata,ourSettagvideodata] = useState([]);
  const [ourchannelinfo, ourchannelInfoList] = useState([]);



  useEffect(() => {
    setCurrentIndex(0);
  }, [ourTajanews]);

 




  useEffect(() => {
    // getChannelInfo();
    getChannelFetchInfo(channel_seo_name);
    // fetchTajanews(chnl_id);
   }, [chnl_id])
   const getChannelFetchInfo = async (channel_seo_name) => {
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {

        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourchannelInfoList(resp_datalist);
          fetchTajanews(resp_datalist[0].chnl_id);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const handleShow = async (tag_id) => {
    setShow(true);
  const responseBody = { tag_id:tag_id };
    await axios
      .post(tajanewstagsid, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourSettagdata(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
 
};

const handleSho = async (tag_id) => {
  setShowd(true);
const responseBody = { tag_id:tag_id };
  await axios
    .post(tajanewstagsid, JSON.stringify(responseBody), {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
      //console.log(res.data);
      var resp_data = res.data;
      if (resp_data.status === 'true' && resp_data != null) {
        var resp_datalist = resp_data.data;
        ourSettagvideodata(resp_datalist);
      }

    })
    .catch((error) => {
      //setIsLoading(false);
      console.log(error);
    });

};

  const fetchTajanews = async (chnl_id) => {
    const responseBody = { channelid: chnl_id };
    await axios
      .post(gettajanewsurl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourSetTajanews(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  } 
  var weburl = "/"+channel_seo_name+"/latestnews-viewmore";
  
  setTimeout(() => {
    var marqueetag = document.getElementsByClassName('marquee');
    marqueetag[0].style = '--play: running; --direction: normal; --duration: 230.35s; --delay: 0s; --iteration-count: infinite;';
  }, 1000);
  
    return (
      <div> 
        <div className="latestNews">
          <Container fluid className="pad-left-80">
                <Row className="align-items-center" style={{margin:"0px" , justifyContent:"space-between"}}>
                    <Col lg="auto" md="auto" sm="auto" xs="auto" className="d-none">
                        <div className="latentNews">
                            <p>తాజా వార్తలు</p>
                        </div>
                    </Col>
               
                    <Col lg={12} md={12} sm={12} xs={12} >
                      {/* <Marquee behavior="scroll" direction="left" pauseOnHover={true} speed={70} >
                        <ul className="newslist"> */}
                        {/* <div className="marquee-container">
      <ul className="marquee-text newslist"> */}



<div className="d-flex align-self-center">
<div className="latentNews">
                            <p>తాజా వార్తలు</p>
                        </div>


<Marquee
    
      className="custom-marquee"
      autoFill={true}
      play={true}
      pauseOnHover={true}
      pauseOnClick={false}
      direction="left"
      speed={15}
      delay={1}
      loop={0}
      gradient={false}
      gradientColor="white"
      gradientWidth={0}
      onFinish={() => console.log('Marquee finished scrolling')}
      onCycleComplete={() => console.log('Marquee finished a loop')}
      onMount={() => console.log('Marquee mounted')}
    >

     {/* <p>
        
        
         <span className="mobile-divs-done">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> <span className="small-devices">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
   </p> */}

                        {ourTajanews && ourTajanews.map((tnews, subindex) => {
                            if(tnews.tn_type === "image"){
                              return( <p  onClick={() => {
                                handleShow(tnews.tn_id);
                              }}  ><FaFileAlt   className="forsize" /> {tnews.tn_title}. </p> ) 
           
                            }else if(tnews.tn_type === "video"){
                              return(  <p  onClick={() => {
                                handleSho(tnews.tn_id);
                              }}  ><FaVideo className="forsize"/> {tnews.tn_title}. </p>  ) 

                            }else if(tnews.tn_type === "clip"){
                              return(  <p to={tnews.tn_file} target="blank" ><FaLink className="forsize"/> {tnews.tn_title}.</p> )  

                            }
                                                    
                            
                         })}
                          {/* <li><Link to="/"  onClick={handleShow}><FaFileAlt className="forsize"/> రైల్వే ప్రయాణికులకు బిగ్ అలర్ట్.</Link></li>
                          <li><Link to="/" onClick={handleSho} ><FaVideo className="forsize"/> జాతీయ స్థాయి గిరిజన జాతర.</Link></li>   
                          <li><Link to="http://etv.co.in/newsclips/clipHome?clipId=378672" target="blank" ><FaLink className="forsize"/> జాతీయ స్థాయి గిరిజన జాతర.</Link></li>    */}
                     
                  

      
        
    </Marquee>
    <div className="buto-style-clas">
                        <Link to={weburl} >MORE</Link>
                        <div className="backcolo-clas">
                          <p></p>
                        </div>
                       </div>
</div>
    












                    </Col>
                    { ourTajanews.length > 0 && (
                    <Col lg="auto" md="auto" sm="auto" xs="auto" className="d-none">
                       <div className="buto-style-clas">
                        <Link to={weburl} >MORE</Link>
                        <div className="backcolo-clas">
                          <p></p>
                        </div>
                       </div>
                    </Col>
                    ) }
                </Row>
              
                {ourtagdata && ourtagdata.map((tagdata, subindex) => {
          return(
               <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
       
        <Modal.Header closeButton>
          <Modal.Title className="headtile"><h4>{tagdata.tn_title}</h4><h6 className='time-class-style'>Updated:{tagdata.datedisplay}</h6></Modal.Title>
          
        </Modal.Header>
        <Modal.Body>
       
          <NewPop outtagdata = {tagdata}  />
       
        </Modal.Body>
        <Modal.Footer className="popup-footer">
        <NewsAds/>
        </Modal.Footer>
         
      </Modal>
      )
    })}






{ourtagvideodata && ourtagvideodata.map((tagvideodata, subindex) => {
          return(
      <Modal
        show={shows}
        onHide={handleClos}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="headtile"><h4>{tagvideodata.tn_title}</h4><h6 className='time-class-style'>Updated:10 Nov 2023 11:12 IST</h6></Modal.Title>
          
        </Modal.Header>
        <Modal.Body>
       <NewsPopVideo  outtagvideodata = {tagvideodata} />
        
    
        </Modal.Body>
        <Modal.Footer>
        <NewsAds/>

        </Modal.Footer>
       
      </Modal>

)
})}



            </Container>



        </div>

  
  </div>
    )
  }
  
  export default Latestnews