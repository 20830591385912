import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'


const CsrPolicy = () => {
  return (
    <div> 
        <div className='SubpageTitleBar'>
        <Container>
          <Row className='shoed'>
          <Col lg={6} md={6}>
          <h4 className='allshiw'></h4>
            </Col>
          </Row>
        </Container>
        </div>

        <div className='cms-content'>
          <Container>
            <div className='page-card'>
              <Row>
                <Col lg={12}>
                  <h1>CSR Policy</h1>
                  <h4>Introduction</h4>
                  <p>
                  ETV Telugu is a 24 x 7 satellite channel comprising general entertainment programming from South India containing serials, both fiction and non-fiction, reality show oriented programs, devotional programs, feature films, tele-films, musical programs, film based programs, youth based programs, women's infotainment programs, special ground events including events for some of the film based programs, musical based programs (reality show oriented and other similar) and special programs relating to major festivals specific to Telugu language speaking community and the Indian State of Andhra Pradesh and Telangana.
                  </p>
                  <h4> Applicability</h4>
                  <p>
                  This Corporate Social Responsibility Policy (“CSR Policy”) has been adopted by the Company in consonance with Section 135 of the Companies Act 2013 (“Act”), the Companies (Corporate Social Responsibility Policy) Rules 2014 (“CSR Rules”), and subsequent amendments / notifications/circulars issued by the Ministry of Corporate Affairs, Government of India, from time to time.

              
                  </p>
                  <p>The Policy shall apply to all CSR projects/programmes undertaken by the Company in India as per Schedule VII of the Act.</p>
                  <h4>  CSR Budget</h4>
                  <p>
                  The CSR Committee of the Board constituted under Section 135 of the Act will recommend eligible projects to be taken up as sustainable projects and project wise annual budgeted expenditure to the Board for its consideration and approval.
                  </p>
                  <p>The total CSR budget for any financial year commencing from 2014- 15shall be for an amount equivalent to 2% of the average net profits of the Company made during the three immediately preceding financial years.</p>
                  <p>The Board of Directors shall spend the CSR obligation amounts on various projects recommended by the CSR Committee. In case where the obligated amount has not been spent in any particular year, the Board shall in its Report to the shareholders, explain the reasons for not being able to spend the required amount.</p>
                  <p>The Company can undertake projects which may involve implementation period of 3 to 4 years as “Ongoing Projects” and allocate resources accordingly.  </p>
                  <h4>Scope</h4>
                  <p>
                  Eenadu Television Pvt Ltd is part of Ramoji group which is a diversified group with substantial business interests in Media (print as well as electronic), Entertainment (Film & TV Software programming), Foods, Finance, Retail, Hospitality & Education. Supporting Eenadu Television is the stupendous infrastructure of Ramoji Film City, the world's largest.
                  </p>
                  <ul>
                    <li>Rural development</li>
                    <li>village roads</li>
                    <li>renewable energy installations</li>
                    <li>preventive healthcare</li>
                    <li>sanitation</li>
                    <li>provision of safe drinking water</li>
                    <li>livelihood enhancement projects</li>
                    <li>imparting vocational skills, skill development</li>
                    <li>agro forestry, soil conservation, rain water harvesting</li>
                    <li>construction of hostels for destitute women and children</li>
                    <li>schools</li>
                    <li>old age homes</li>
                    <li>development of traditional arts and crafts etc.</li>
                    <li>restoration of old temples</li>
                  </ul>
                  <p>The Company may take up such other activities which are broadly covered under Schedule VII of the Act (capturing the essence of the subjects enumerated therein, with liberal interpretation) and any additions which may be made hereafter. Relief and rehabilitation works in case of any natural calamities and major disasters, will also be taken up under CSR.</p>
                  <h4>  CSR Committee</h4>
                  <p>
                  Composition of the CSR committee: The CSR Committee of the Board will consist of Two directors. </p>
                  <ul>
                    <li>Sri RamojiRao</li>
                    <li>Sri Ch.Kiron</li>
                  </ul>
                  <h3>
                  The CSR committee will be responsible for:
                  </h3>
                  <ul>
                    <li>formulating the CSR policy in compliance to Section 135 of the Act read with the rules made thereunder</li>
                    <li> Identifying activities to be undertaken as per Schedule VII of the Companies Act 2013.</li>
                    <li>Recommending to Board the CSR expenditure to be incurred.</li>
                    <li>Recommending to Board, modifications to the CSR policy as and when required.</li>
                    <li>regularly monitoring the implementation of the CSR policy and various projects undertaken thereunder.  </li>
                  </ul>
                  <h4>IMPLEMENTATION</h4>
                  <p>
                  The investment and duration of programme will depend on its nature, extent of coverage and intended impact of the programme. It may be ensured that majority of the CSR activities are undertaken in both Andhra Pradesh and Telangana.
                  </p>
                  <p>Implementation may be taken up through designated teams of the company or at corporate level under the programs as approved by the CSR Committee and the Board of the company. The company may also implement the CSR schemes in collaboration with its associate companies or other companies, wherever feasible.  Company may also appoint an Implementation Agency in the form of a Registered Trust to carry out the approved CSR Projects and make contributions to the Corpus of the Trust from time to time.</p>
                  <h3>Execution of Programmes:</h3>
                  <p>Project activities identified under CSR are to be implemented by employees, selected volunteers, specialized agencies, which include Voluntary Organisations (NGOs), Elected local bodies such as Panchayats, Institutes / Academic Organisations, Trusts, Missions, Self Help Groups, Govt./Semi Govt. / Autonomous Organisations, MahilaMandals/ Samitis, Professional Consultancy Organization etc.,</p>
                  <p>Initiatives of State Governments, District administration, Local Administration as well as Central Government Departments/ Agencies, Self-Help Groups etc., would be dovetailed / synergized with the initiatives taken by the Company.</p>
                  <p>Activities shall, as far as possible, be implemented in a project mode. For easy implementation, long-term CSR projects shall be broken down into medium-term and short-term plans. Each plan shall specify the CSR activities planned to be undertaken for each year. Accordingly, the budget shall be allocated for the implementation of these activities and achievement of targets set for each successive year, till the final completion of the project.</p>
                  <p>All the CSR activities shall be monitored regularly by Chairman and the CSR Committee.

</p>
                 
                  <h4> Roles and Responsibilities</h4>
                  <p>
                  The Board of the Company will be responsible for:
                  </p>
                  <ul>
                    <li> approving the CSR policy as recommended and formulated by the CSR Committee</li>
                    <li> ensuring that in each financial year the Company spends at least 2% of the average net profit before taxation made during the three immediate preceding financial years on the CSR activities specified in Schedule VII of the Act.</li>
                    <li> ensuring that every financial year funds committed by the Company for CSR activities are utilised effectively, and regularly monitoring implementation.</li>
                    <li>  disclosing in its Annual Report the names of CSR Committee members, the content of the CSR policy and ensure annual reporting of its CSR activities on the Company website.</li>
                    <li>The surplus arising out of the CSR activities, if any, will not be considered as a part of the business profits of the company.</li>
          
                  </ul>
                  <p>As per Section 135 of the Act, the reasons for under spending of the allocated CSR budget shall be specified in the Board’s Report.</p>
                  <p>The surplus arising out of the CSR activities, if any, will not be considered as a part of the business profits of the company.</p>
                  <h4>General</h4>
                  <ul>
                    <li>The CSR Policy shall be recommended by the CSR Committee to The Board of Directors for its approval.</li>
                    <li>The Company reserves the right to modify, cancel, add, or amend any of the above rules/guidelines, with the approval of CSR Committee & ratification of Board of Directors.</li>
                    <li>  Any or all provisions of the CSR policy shall be subject to revision/ amendment in accordance with the guidelines on the subject as may be issued from Government of India, from time to time.</li>
                    <li> In case of doubt with regard to any of the provision of the policy and also in respect of matters not covered herein, the interpretation & decision of the CSR Committee shall be final.</li>
                    <li>   Certain projects/activities which are not approved by CSR Committee but are required to be implemented on an urgent basis in unforeseen circumstances relating to Natural calamity, can be taken up for implementation after approval from the Chairman. The CSR Committee and Board of Directors would be appraised about such projects/activities during their next meeting.</li>
                  </ul>
                <h4>EENADU TELEVISION PRIVATE LIMITED - CSR PROJECTS</h4>
                <p>The Company is implementing the CSR activities under collaboration with eligible Group Companies through a Registered Trust Ramoji Foundation, the Implementation Agency. </p>
                <ul>
                    <li> Rural Development, Sanitation & Hygiene (Public Toilets), Water Supply, School Infrastructure taken up at Pedaparupudi Village, Krishna District, Andhra Pradesh State.</li>
                    <li>Rural Development, livelihoods improvement, Protected Water Supply, Anganwadi School Infrastrcture, Dwacra Building, Public Toilets taken up at Naganpally, Ranga Reddy District, Telangana State.</li>
                    <li> Rural Development, school infrastructure works at Anajpur, Hayatnagar Mandal, Ranga Reddy District.</li>
                    <li> Disaster relief extended to Kerala flood victims by way of construction of houses for the displaced people in Alleppey district.</li>
                    <li>Contribution to provide assistance / preventive care and relief measures towards Covid 19 Pandemic, in the States of Andhra Pradesh & Telengana States.

</li>
                    <li> Skill development initiative under Ramoji Academy of Film & Television by way of providing Vocational courses and training in film and video services.</li>
                </ul>
                <div className='office'>
                   <h3>EENADU TELEVISION PVT. LTD.</h3>
                   <p>CIN: U92111TG1991PTC012643</p>
                   <p>Regd. Office: 1-10-76, Fair Fields, Hyderabad-500 016. Telangana. India</p>
                   <p>Corporate Office: Ramoji Film City, Hyderabad - 501512. Telangana. India</p>
                   <p>Tel & Fax: 08415 - 246408, Website: www.etv.co.in</p>

                </div>
                
                </Col>
              </Row>
            </div>
          </Container>
        </div> 
 </div>
  )
}

export default CsrPolicy