import React from 'react'
import { Link } from 'react-router-dom'
import {Bling as GPT} from "react-gpt";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick"; 
import "./AddBannerBottom.css"

const AddBannerBottom = (props) => {
  const bottombannerdata = props.bottombanner;
      
     
  return (
    <div className='sum shope d-none d-lg-block d-xl-block'>
    {/* {bottombannerdata && bottombannerdata.map(function (ads, videoindex) {
      return(
      <div>
        <div className='schedule-car'>
        <Link to="" className='mb-3 d-block'>
        <img src={ads.image_url} alt=""/> 
      </Link>
        </div>
      </div>
  )
})}
   */}
 {bottombannerdata && bottombannerdata.map(function (ads, videoindex) {
         if(ads.ad_type === "Imageupload"){

     return(
      <div>
        <div className='schedule-car'>
        <Link to={ads.redirection_link} target='_blank' className='mb-3 d-block'>
        <img src={ads.image_url} alt=""/> 
      </Link>
        </div>
      </div>
      )
     }else{
     
      return(
        <>
                  <div
                    id="sidevideoAdsecoundAd"
                    className=''
                  >
                    <GPT
                      adUnitPath='/21769336530/ETV_Rectangle_AD'
                      slotSize={[300,600]}
                    />
                  </div>
  
                 
                </>
        )

    }
    })}
      

   
    
    </div>
  )
}
export default AddBannerBottom