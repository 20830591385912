import React from 'react'
import { NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "./Show.css"
import { useState } from 'react'

const Show = ({toggleMenu}) => {
  

  // const [menuOpen, setMenuOpen] = useState(false)
  //   const toggleMenu = () => {
  //     setMenuOpen(!menuOpen)
  //   }
    
  return (
     <div className='showed'>
      
    <Link to="/Showview" onClick={toggleMenu}>
      <img src='../assets/images/show-05.jpg' alt='' />
      <span>Jabardasth</span>
    </Link>
    <Link to="/Showview"  onClick={toggleMenu}>
      <img src='../assets/images/show-03.jpg' alt='' />
      <span>Extra Jabardasth</span>
    </Link>
    <Link to="/Showview" onClick={toggleMenu}>
      <img src='../assets/images/show-04.jpg' alt='' />
      <span>Dhee 15</span>
    </Link>
    <Link to="/Showview" onClick={toggleMenu}>
      <img src='../assets/images/show-02.jpg' alt='' />
      <span>Annadata</span>
    </Link>
    <Link to="/Showview" onClick={toggleMenu}>
      <img src='../assets/images/show-01.jpg' alt='' />
      <span>Alitho Saradaga</span>
    </Link>

    
    </div>

  )
}

export default Show