import React from 'react'
import { Link } from 'react-router-dom'
import "./Show.css"

const Serials = ({toggleMenu}) => {
  return (
<div className='showed'>     
    <Link to="/Showview" onClick={toggleMenu}>
      <img src='../assets/images/show-01.jpg' alt='' />
      <span>Anupallavi</span>
    </Link>
    <Link to="/Showview" onClick={toggleMenu}>
      <img src='../assets/images/show-02.jpg' alt='' />
      <span>Guvva Gorinka</span>
    </Link>
    <Link to="/Showview" onClick={toggleMenu}>
      <img src='../assets/images/show-03.jpg' alt='' />
      <span>Gangotri</span>
    </Link>
    <Link to="/Showview" onClick={toggleMenu}>
      <img src='../assets/images/show-04.jpg' alt='' />
      <span>Maa Atha Bangaram</span>
    </Link>
    <Link to="/Showview" onClick={toggleMenu}>
      <img src='../assets/images/show-05.jpg' alt='' />
      <span>Manasantha Nuvve</span>
    </Link>
  

    </div>

  )
}

export default Serials