import React,{useState,useEffect}  from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaFileAlt, FaLink, FaVideo } from 'react-icons/fa'
import { Link,useParams } from 'react-router-dom'
import NewPop from "./NewPop";
import { NewsPopVideo } from "./NewsPopVideo";
import HeaderAdd from "./HeaderAdd";

import Modal from 'react-bootstrap/Modal';
import { gettajanewsurl,tajanewstagsid,getchannelbyseoname} from "../../../globalURL";
import axios from 'axios';
const LatestNewsViewMore = () => {
  const params = useParams();
  var channel_seo_name = params.channelseo;
  if(channel_seo_name == undefined){
    channel_seo_name = "etv-telugu";
  }
  const [ourTajanews, ourSetTajanews] = useState([]);
  const [ourchannelinfo, ourchannelInfoList] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [shows, setShowd] = useState(false);
  const handleClos = () => setShowd(false);

  const [ourtagdata,ourSettagdata] = useState([]);
  const [ourtagvideodata,ourSettagvideodata] = useState([]);
  useEffect(() => {
    getChannelFetchInfo(channel_seo_name);
  }, [channel_seo_name])
  const getChannelFetchInfo = async (channel_seo_name) => {
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {

        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourchannelInfoList(resp_datalist);
          fetchTajanews(resp_datalist[0].chnl_id);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const fetchTajanews = async (chnl_id) => {
    const responseBody = { channelid: chnl_id };
    await axios
      .post(gettajanewsurl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourSetTajanews(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  } 
  const handleShow = async (tag_id) => {
    setShow(true);
  const responseBody = { tag_id:tag_id };
    await axios
      .post(tajanewstagsid, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourSettagdata(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
 
};

const handleSho = async (tag_id) => {
  setShowd(true);
const responseBody = { tag_id:tag_id };
  await axios
    .post(tajanewstagsid, JSON.stringify(responseBody), {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
      //console.log(res.data);
      var resp_data = res.data;
      if (resp_data.status === 'true' && resp_data != null) {
        var resp_datalist = resp_data.data;
        ourSettagvideodata(resp_datalist);
      }

    })
    .catch((error) => {
      //setIsLoading(false);
      console.log(error);
    });

};
  return (
    <div> 
          <Container fluid className="pad-left-80 pad-right-80">
      <Row >
          <Col lg={6} md={6}>
          <h4 className='allshiw'>Taja News</h4>
            </Col>
          </Row>

        </Container>
    <div className="fordf">
      <Container fluid className="pad-left-80 pad-right-80">
           <Row >
          <Col lg={12} md={6}>
            <div className='new-list-style-class'>
            <ul>
            {ourTajanews && ourTajanews.map((tnews, subindex) => {
                
                // <li ><h4><FaFileAlt className="forsize"/>లక్షలాది పిల్లల అనారోగ్య సమస్యలకు చెక్​.. 'సిలికాన్​' లూసీతో వైద్యులకు ట్రైనింగ్​ </h4>
                // </li>
  if(tnews.tn_type === "image"){
    return( <li ><h6 className='time-class-style'>Updated:{tnews.datedisplay}</h6> <Link  onClick={() => {
      handleShow(tnews.tn_id);
    }}  ><FaFileAlt   className="forsize" /> {tnews.tn_title}. </Link></li>  ) 

  }else if(tnews.tn_type === "video"){
    return(  <li><h6 className='time-class-style'>Updated:{tnews.datedisplay}</h6> <Link  onClick={() => {
      handleSho(tnews.tn_id);
    }}  ><FaVideo className="forsize"/> {tnews.tn_title}. </Link></li>  ) 

  }else if(tnews.tn_type === "clip"){
    return(   <li><h6 className='time-class-style'>Updated:{tnews.datedisplay}</h6><Link to={tnews.tn_file} target="blank" ><FaLink className="forsize"/> {tnews.tn_title}.</Link></li> )  

  }

              })}
          </ul>


            </div>
            </Col>
          </Row>
        

          {ourtagdata && ourtagdata.map((tagdata, subindex) => {
          return(
               <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"

      >
       
        <Modal.Header closeButton>
          <Modal.Title className="headtile"><h4>{tagdata.tn_title}</h4><h6 className='time-class-style'>{tagdata.datedisplay}</h6></Modal.Title>
        </Modal.Header>
        <Modal.Body>
       
          <NewPop outtagdata = {tagdata}  />
       
        </Modal.Body>
        <Modal.Footer className='popup-footer'>
        <HeaderAdd/>
        </Modal.Footer>
         
      </Modal>
      )
    })}






{ourtagvideodata && ourtagvideodata.map((tagvideodata, subindex) => {
          return(
      <Modal
        show={shows}
        onHide={handleClos}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="headtile"><h4>{tagvideodata.tn_title}</h4><h6 className='time-class-style'>Updated:10 Nov 2023 11:12 IST</h6></Modal.Title>
        </Modal.Header>
        <Modal.Body>
       <NewsPopVideo  outtagvideodata = {tagvideodata} />
        
    
        </Modal.Body>
        <Modal.Footer>
        <HeaderAdd/>
        </Modal.Footer>
       
      </Modal>

)
})}


        </Container>
        

        </div>
        </div>
  )
}



export default LatestNewsViewMore