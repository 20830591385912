import React from 'react'
import { FaPlay } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const NewPlayIcon = () => {
  return (
    <div> <div className='iconcame'><FaPlay /></div></div>
  )
}

export default NewPlayIcon