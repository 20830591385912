import React , { useState, useEffect } from 'react'

import "./Cms.css"
import { Row, Container, Col, } from 'react-bootstrap'
import { Link,useParams } from 'react-router-dom'
import { getfootercontentbyseo,getchannelbyseoname } from "../globalURL";
// import { use } from 'video.js/dist/types/tech/middleware';
import axios from 'axios';

const AboutUs = () => {
  const [chnlId, setChnId] = useState(1);
  const params = useParams();
  var channel_seo_name = params.channelseo;
  if(channel_seo_name == undefined){
    channel_seo_name = "etv-telugu";
  }
  var seo_name = params.pagename;
  //var channelseo_name = param.channelname;
//alert(JSON.stringify(channelseo_name));
  const [storeFooterContent, setStoreFooterContent] = useState([]);
  const [ourChannelinfo, ourSetChannelinfo] = useState([])
  useEffect(() => {
    getChannelInfo(seo_name,channel_seo_name)
   // getTagsInfo();
  }, [seo_name,channel_seo_name])
  const getFooterContent = async (seo_name,channel_id) => {
    const responseBody = { seo_name: seo_name,channel_id:channel_id };
    await axios
      .post(getfootercontentbyseo, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
      
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setStoreFooterContent(resp_datalist);
  
  
        }
  
      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const getChannelInfo = async (seo_name,channel_seo_name) => {
    if(channel_seo_name == undefined){ channel_seo_name = "etv-telugu";}
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          if(resp_datalist.length > 0){
            getFooterContent(seo_name,resp_datalist[0].chnl_id);

          }
          ourSetChannelinfo(resp_datalist);
        
        //  
        }else{
        
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  //alert(JSON.stringify(storeFooterContent));
  const DynamicContent = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };
  if(storeFooterContent.length > 0){
    const content = storeFooterContent[0].footer_content;

  return (
    <div> 
      <div className='SubpageTitleBar'>
      <Container fluid className='pad-left-80 pad-right-80'>
          <Row className='shoed'>
          <Col lg={6} md={6}>
          <h4 className='allshiw'>{storeFooterContent[0].name}</h4>
            </Col>
          </Row>
        </Container>
        </div>

        <div className='cms-content'>
        <Container fluid className='pad-left-80 pad-right-80'>
            <div className='page-card'>
              <Row>
                <Col lg={12}>
                  {/* <h1>{storeFooterContent[0].name}</h1> */}
                  <p>
                  <DynamicContent htmlContent={content} />    
                  
                              </p>
                  
                </Col>
              </Row>
            </div>
          </Container>
        </div>
 </div>
  )
}else{
  return (
    <div> 
      <div className='SubpageTitleBar'>
        <Container>
          <Row className='shoed'>
          <Col lg={6} md={6}>
          <h4 className='allshiw'>{seo_name}</h4>
            </Col>
          </Row>
        </Container>
        </div>

        <div className='cms-content'>
          <Container>
            <div className='page-card'>
              <Row>
                <Col lg={12}>
                  <h1></h1>
                  <p>
                  
                              </p>
                  
                </Col>
              </Row>
            </div>
          </Container>
        </div>
 </div>
  )
}
}
export default AboutUs