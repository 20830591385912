import React, {  useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { getchannels_list_url,getshow_url,savefeedbackurl } from "../globalURL";
import axios from 'axios';


const Feedback = () => {
  const [ourChannels, ourChannelsList] = useState([]);
  const [ourShows, ourShowList] = useState([]);

  // raji implemneted 
  useEffect(() => {
    fetchChannelsData();

  }, [])

  const fetchChannelsData = async () => {
    //   setIsLoading(true);
    const responseBody = {};
    await axios
      .post(getchannels_list_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourChannelsList(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };
  const handleDropdown = (event) => {
    var channel_id = event.target.value;
    const responseBody = {chnl_id:channel_id};
     axios
      .post(getshow_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourShowList(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };
  
  const [formData, setFormData] = useState({
    selectedchannel: '',
    txtshow: '',
    txtemail: '',
    txtcomments: '',
  });
  const handleReset = () => {
    // Reset the form data to empty values
    setFormData({
      selectedchannel: '',
      txtshow: '',
      txtemail: '',
      txtcomments: '',
      // Reset other form fields as needed
    });
  };
  const [errors, setErrors] = useState({
    txtemail: '',
  });

  const handleDropdownChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (formData.selectedchannel.trim() === '') {
      newErrors.selectedchannel = 'Please select a channel';
      valid = false;
    }

    if (formData.txtshow.trim() === '') {
      newErrors.txtshow = 'Please select a show';
      valid = false;
    }

    if (formData.txtemail.trim() === '') {
      newErrors.txtemail = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.txtemail)) {
      newErrors.txtemail = 'Invalid email address';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const savefeedback = () => {
    if (validateForm()) {
        axios
      .post(savefeedbackurl, JSON.stringify(formData), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
            console.log("form submitted successfully..");
            alert('Successfully saved!');

            // Redirect to the same path after form submission
           window.location.href = window.location.pathname;
                       // Display a success message (optional)
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
    }
  };

  return (
    <div> 
 
<div className='SubpageTitleBar'>
<Container fluid className='pad-left-80 pad-right-80'>
          <Row className='shoed'>
          <Col lg={6} md={6}>
          <h4 className='allshiw'>Feedback</h4>
            </Col>
          </Row>
        </Container> 
        </div>

        <div className='cms-content feedback-bg-clor'>
        <Container fluid className='pad-left-80 pad-right-80'>
          <Row className='justify-content-center'>
          <Col lg={7} md={9} sm={9} xs={10}>
            <div className='page-card'>

              
                
                {/* <h1>Feedback</h1> */}
               
                <p>Your opinions, your feedback, your suggestions, let them flow. This is your space. It will help us in improving the website.</p>
                <hr/>
                <Form className='form-new-styling'>
      <div className='mb-3'>
        <Form.Label>Select Channel :</Form.Label>
        <Form.Select
        className='fousclass'
          aria-label="Default select example"
          id="selectedchannel"
          name="selectedchannel"
          size="lg"
          onChange={(event) => {
            handleDropdownChange(event);
            handleDropdown(event);
            // Call your second function here
            // yourSecondFunction();
          }}
          value={formData.selectedchannel}
        >

          <option value="">Select</option>
          {ourChannels &&
                      ourChannels.map(function (list, index) {
                        return (
                            <option value={list.chnl_id} > {list.chnl_name}</option>
                        )})}
						
        </Form.Select>
        {errors.selectedchannel && <span className='erroeclass'>{errors.selectedchannel}</span>}
      </div>
      <div className='mb-3'>
        <Form.Label>Select Any Show :</Form.Label>
        <Form.Select
          aria-label="Default select example"
          id="txtshow"
          name="txtshow"
          size="lg"
          className='fousclass'
          onChange={(event) => {
            handleDropdownChange(event);
            // Call your second function here
            // yourSecondFunction();
          }}
          value={formData.txtshow}
        >
          <option value="">Select</option>
          {ourShows &&
                      ourShows.map(function (lists, index) {
                        return (
                            <option value={lists.show_id} > {lists.show_name}</option>
                        )})}
        </Form.Select>
        {errors.txtshow && <span className='erroeclass'>{errors.txtshow}</span>}
      </div>
      <div className="mb-3">
        <Form.Label>Email Id :</Form.Label>
        <Form.Control
          type="email"
          id="txtemail"
          name="txtemail"
          placeholder="Enter email Id"
          value={formData.txtemail}
          onChange={handleChange}
          className='fousclass'
        />
        {errors.txtemail && <span className='erroeclass'>{errors.txtemail}</span>}
      </div>
      <div className="mb-3">
        <Form.Label>Comments :</Form.Label>
        <Form.Control
          as="textarea"
          id="txtcomments"
          name="txtcomments"
          rows={3}
          placeholder='Enter Comments here'
          value={formData.txtcomments}
          onChange={handleChange}
          className='fousclass'
        />
      </div>
      <Row>
      <Col lg={6} md={6} sm={6} xs={6}>
        <Button variant="primary" onClick={savefeedback} className='wid-100'>SUBMIT</Button>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6}>
        <Button variant="secondary" onClick={handleReset} className='wid-100'>RESET</Button>

        </Col>
      </Row>
  
    </Form>
                
            </div>
            </Col>
              </Row>




          </Container>
        </div> 

 </div>
  )
}

export default Feedback