import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap'
import { getfootertechnical } from "../globalURL";
import axios from 'axios';
import { useParams } from 'react-router-dom'
const TechnicalInfo = () => {
  const param = useParams();
  var seo_name = param.pagename;
  const [technicalFooterContent, setTechnicalFooterContent] = useState([]);
  useEffect(() => {
    getFooterTechincal(seo_name);
  }, [seo_name])
  const getFooterTechincal = async (seo_name) => {
    const responseBody = { seo_name: seo_name };
    await axios
      .post(getfootertechnical, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
      
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setTechnicalFooterContent(resp_datalist);
  
  
        }
  
      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  return (
    <div> 
      <div className='SubpageTitleBar'>
        <Container>
          <Row className='shoed'>
          <Col lg={6} md={6}>
          <h4 className='allshiw'>Technical Info</h4>
            </Col>
          </Row>
        </Container>
        </div>

        <div className='cms-content'>
          <Container>
            <div className='page-card'>
              <Row>
                <Col lg={12}>
                  <h1>Technical Info</h1>
                  <h3>Few of ETV channels are encrypted. To receive the signal of any of the ETV's channel, tune in to the given frequency.</h3>
                  <div className='mt-4'>
                  <Table striped bordered responsive>
                    <thead>
                      <tr>
                        <th>Channel</th>
                        <th>Satelite Name</th>
                        <th>Downlink Freq (Mhz)</th>
                        <th>Polarisation</th>
                        <th>Symbol Rate (MSPS)</th>
                        <th>FEC</th>
                        <th>Signal Type</th>
                      </tr>
                    </thead>
                    <tbody>
                    
                    {technicalFooterContent &&
        technicalFooterContent.map(function (sublist, index) {
          return(
                      <tr>
                        <td>
                          <img src={sublist.chnl_logo} alt='' height={30} /> {sublist.chnl_name}
                        </td>
                        <td>{sublist.satelite_name}</td>
                        <td>{sublist.downlink_freq}</td>
                        <td>{sublist.polarisation}</td>
                        <td>{sublist.symbol_rate}</td>
                        <td>{sublist.fec}</td>
                        <td>{sublist.signal_type}</td>
                      </tr>
                    )  })}
                    </tbody>
                  </Table>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
 </div>
  )
}

export default TechnicalInfo