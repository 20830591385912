import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "./Business.css"
import NewPlayIcon from '../../NewPlayIcon'
import PlayIcon from '../../PlayIcon';
import {Bling as GPT} from "react-gpt";

const Business = (obj) => {
    const databuss = obj.databussiness;
    const video_list = databuss.video_list;
    const channel_seo_name = obj.channelseoname;
    var mymenuads = obj.mymenuads;
    const zeroindex = []; const uptofinaldata = [];
    if (databuss.video_list_cnt > 0) {
        for (let index = 0; index < databuss.video_list_cnt; index++) {
            //rows.push(ObjectRow());
            if (index == 0) {
                zeroindex.push(video_list[index]);
            } else if (index >= 1) {
                uptofinaldata.push(video_list[index]);
            }
        }
    }
    // alert(mymenuads);
    if (databuss.video_list_cnt > 0) {
        return (
            <div className='hebusin'>
                <Row>
                    <Col lg={8}>
                        <div className='policits busoin'>
                            <Row><Col lg={9} md={9} sm={9} xs={9}><h2 className="head headd">{databuss.tag_title}</h2></Col><Col lg={3} md={3} sm={3} xs={3}><Link to={`/AllNewsPage/${channel_seo_name}/${databuss.menu_cat_name}/${databuss.seo_tag_title}`} className='moed'>More +</Link></Col></Row>


                            <Row>

                                <Col lg={6}>
                                    <Row>
                                        <Col lg={12}>
                                            <div className='topn facd responive-img'>
                                                <Link to={`/NewsView/${channel_seo_name}/${databuss.menu_cat_name}/${databuss.seo_tag_title}/${zeroindex[0].sv_title_seo}`}  >
                                                    <div className='newsf'>
                                                        <img src={zeroindex[0].medium_img} />
                                                        <PlayIcon />
                                                    </div>

                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>


                                </Col>

                                <Col lg={6}>
                                    <div className='newsLinks'>
                                        <ul>
                                            {uptofinaldata &&
                                                uptofinaldata.map(function (listdata, index) {
                                                    if(index <= 5){
                                                    return (
                                                        <li>
                                                            <Link to={`/NewsView/${channel_seo_name}/${databuss.menu_cat_name}/${databuss.seo_tag_title}/${listdata.sv_title_seo}`}>{listdata.sv_title}</Link>
                                                        </li>
                                                    )
                                                    }
                                                })}

                                        </ul>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                    </Col>
                    <Col lg={4} >
                        <div className='noise '>
                            {mymenuads && mymenuads.map(function (ads, videoindex) {
                                if (videoindex == 0) {
                                    if (ads.ad_type == "Imageupload") {
                                        return (
                                            <Link to={ads.redirection_link}  target='_blank' >
                                            <img src={ads.image_url} />
                                            </Link>
                                        )
                                    }else{
                                        return(
                                  
                                            <div
                                              id="newhomepagebussinessad"
                                              className='w-100 text-center pb-10' 
                                            >
                                              <GPT
                                                adUnitPath='/21769336530/ETV_Horizontal_AD'
                                                slotSize={[350, 280]}
                                              />
                                            </div>
                            
                                  )
                                    }
                                }else{
                                   
                                }

                            })}
                        </div>

                    </Col>

                </Row>



            </div>
        )
    }
}

export default Business