import React, { useEffect, useState, useRef } from 'react';
import { Container } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./ChanelCategories.css";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useLocation,useParams } from 'react-router-dom';
import LogoImg from './LogoImg';
import Accordion from 'react-bootstrap/Accordion';
import ChanelList from './ChanelList';
import LiveChanels from './LiveChanels';

import Archive from './Archive';
import Events from './Events';
import { FaGooglePlay, FaApple } from 'react-icons/fa';
import Show from './Show';
import Serials from './Serials';
import { getchannels_list_url,getlivechannel,get_main_menu_url } from "../../../globalURL";
import axios from 'axios';
function ChanelCategories({ setChnId }) {
    const linkRefs = useRef([]);
    const [ourChannels, ourChannelsList] = useState([]);
    const [ourLiveChannels, ourLiveChannelsList] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuOpenTwo, setMenuOpenTwo] = useState(false);
    const paramss = useParams();
    const [ourCategories, ourCategoriesList] = useState([])








    const handleToggleClick = () => {
        toggleMenu();
        toggleMenuTwo();
      };

      const toggleMenuTwo = () => {
        setMenuOpenTwo(!menuOpen)
    }



    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }
    const handleClose = () => setMenuOpen(false)


    const [show, setShow] = useState(false);
    const showDropdown = (e) => {
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }
    const location = useLocation();
    // raji implemneted 
    useEffect(() => {
        fetchChannelsData();
        fetchLivechannel();

    }, [])
    const menuChangeByClick = (chnl_id) => {
     
        localStorage.setItem("session_chnl_id", chnl_id);
        // window.location.reload(false);
        setChnId(chnl_id);
       // alert(JSON.stringify(chnl_id));
       fetchCategoryData(chnl_id);

    }
    
  const fetchCategoryData = async (chnlId) => {
    const responseBody = { chnl_id: chnlId };

    await axios
      .post(get_main_menu_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourCategoriesList(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
    function handleClick(index) {
        // alert(index);
        if (index === 0) {
            linkRefs.current[0]?.click();
        }
    }
    const fetchChannelsData = async (setChnId) => {
        //   setIsLoading(true);
        localStorage.setItem("session_chnl_id", setChnId);

        const responseBody = { chnl_id: "", limit: 10 };
        await axios
            .post(getchannels_list_url, JSON.stringify(responseBody), {
                headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
                //console.log(res.data);
                var resp_data = res.data;
                if (resp_data.status === 'true' && resp_data != null) {
                    var resp_datalist = resp_data.data;
                    ourChannelsList(resp_datalist);
                }

            })
            .catch((error) => {
                //setIsLoading(false);
                console.log(error);
            });
    };
    const fetchLivechannel = async () => {
        //   setIsLoading(true);
        const responseBody = {};
        await axios
            .post(getlivechannel, JSON.stringify(responseBody), {
                headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
                //console.log(res.data);
                var resp_data = res.data;
                if (resp_data.status === 'true' && resp_data != null) {
                    var resp_datalist = resp_data.data;
                    ourLiveChannelsList(resp_datalist);
                }

            })
            .catch((error) => {
                //setIsLoading(false);
                console.log(error);
            });
    };
    //end
    return (
        <>
            {[false].map((expand) => (
                <Navbar key={expand} expand="lg" className="chanel-categories">
                    <Container fluid className='pad-left-80 pad-right-80'>
                        <Navbar.Toggle onClick={handleToggleClick}/>
                        <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} placement="start" className='MobleSideNav'
                            restoreFocus={false}
                            show={menuOpen && menuOpenTwo}
                            onHide={handleClose}>
                            <Offcanvas.Header closeButton style={{ color: "#fff" }}>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    <LogoImg />
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1">
                                    <Nav className="me-xl-auto me-lg-auto desktop-menu">
                                        <div className='telufu'>
                                            {ourChannels &&
                                                ourChannels.map(function (list, index) {
                                                    let tosuburl = '';let  ourmetaurl = '';
                                                    if (list.chnl_type_template === "NonNews") {
                                                        tosuburl = "/" + list.channel_seo_url;ourmetaurl = list.metachannel ;
                                                    } else {
                                                        tosuburl = "/" + list.channel_seo_url + "/news"; ourmetaurl = list.metachannel + "/news" ;
                                                    }
                                                    

                                                    return (
                                                        <div key={index} className={location.pathname === '/' + ourmetaurl ? 'active etv' : 'etv'}>
                                                            <Link to={tosuburl}  state={{ id:list.chnl_id,menu_cat_id:list.chnl_menu_cat_ids[0] }} onClick={() => {
                                                                menuChangeByClick(list.chnl_id); handleClick(index);
                                                            }} ref={(el) => linkRefs.current[index] = el}  ><img src={list.chnl_logo} />{list.chnl_name}</Link>
                                                        </div>
                                                    )
                                                })

                                            }

                                        </div>
                                    </Nav>
                                    <Nav className="d-block d-lg-none d-xl-none mobile-menu">
                                         {/* mobile menu start */}

                                    {ourCategories.map((item, index) => {

                                    if (item.showslistcount > 0) {
                                    return (
                                        
                                    <Accordion>
                                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header >{item.menu_cat_name}</Accordion.Header>
                                    {item.showslist && item.showslist.map((subitem, subindex) => {
                                    // let tosuburl = '';
                                    // if (item.menu_cat_name == "Home" && item.chnl_type_template == "NonNews") {
                                    // tosuburl = "/" + item.channel_seo_url;
                                    // }else if (item.menu_cat_name == "Home" && item.chnl_type_template == "News") {
                                    // tosuburl = "/" + item.channel_seo_url + "/news";
                                    // } else if (item.menu_cat_name === "Schedule") {
                                    // tosuburl = "/schedule/" + item.channel_seo_url + "/" + item.menu_cat_name;
                                    // } else {
                                    // tosuburl = "/"+item.channel_seo_url + "/"+item.menu_cat_name+"/"+subitem.show_name_seo_url;
                                    // }


                                    let tosuburl = '';
                                    if (item.menu_cat_name == "Home" && item.chnl_type_template == "NonNews") {
                                      tosuburl = "/" + item.channel_seo_url;
                                    }else if (item.menu_cat_name == "Home" && item.chnl_type_template == "News") {
                                      tosuburl = "/" + item.channel_seo_url + "/news";
                                    } else if (item.menu_cat_name === "Schedule") {
                                      tosuburl = "/schedule/" + item.channel_seo_url + "/" + item.menu_cat_name;
                                    } else {
                                      tosuburl = "/"+item.channel_seo_url + "/showview/"+subitem.show_name_seo_url;
                                    }
                                    
                                    return (
                            
                                    <Accordion.Body>
                                    <div className='showed'>

                                    <Link  to={tosuburl}  state={{id:item.chnl_id ,menu_cat_id:item.menu_cat_id }}  onClick={() => {
                                            menuChangeByClick(item.chnl_id);  handleClick(index);   toggleMenu();
                                        }}   >
                                    <img src={subitem.show_image}   />
                                    <span>{subitem.show_name}</span>
                                    </Link>



                                    </div>
                                    </Accordion.Body>
                                
                                    );
                                    })}
                                        </Accordion.Item>
                                    </Accordion>
                                    );
                                    } else {
                                        let tourl = '';
                                        if (item.menu_cat_name == "Home" && item.chnl_type_template == "NonNews") {
                                        tourl = "/" + item.channel_seo_url;
                                        }else if (item.menu_cat_name == "Home" && item.chnl_type_template == "News" ) {
                                        tourl = "/" + item.channel_seo_url + "/news";
                                        } else if (item.menu_cat_name == "Schedule") {
                                        tourl = "/schedule/" + item.channel_seo_url+ "/" + item.menu_cat_name;
                                        // } else if (item.menu_cat_name == "Shows") {
                                        //   tourl = "/shows/" + item.channel_seo_url+ "/" + item.menu_cat_name;
                                        }else if(item.showslistcount == 0 && (item.menu_cat_name !== "Home" || item.menu_cat_name != "Schedule" || item.menu_cat_name != "Shows")){
                                        tourl = "#";
                                        }
                                        return (
                                        <Link to={tourl}  state={{id:item.chnl_id, menu_cat_id:item.menu_cat_id }} onClick={() => {
                                        menuChangeByClick(item.chnl_id); handleClick(index);  toggleMenuTwo();
                                        }} key={index}  >{item.menu_cat_name}</Link>
                                        )
                                    }
                                    })}

                                        <Accordion className='sub-accordion'>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Etv Channels</Accordion.Header>
                                                <Accordion.Body>
                                                <div className='telufu'>
                                                            {ourChannels &&
                                                            ourChannels.map(function (list, index) {
                                                                let tosuburl = '';
                                                                if (list.chnl_type_template === "NonNews") {
                                                                    tosuburl = "/" + list.channel_seo_url;
                                                                } else {
                                                                    tosuburl = "/" + list.channel_seo_url + "/news";
                                                                }

                                                                return (   <div className={location.pathname === '/' + list.metachannel ? 'active etv' : 'etv'}>

                                                            <Link to={tosuburl}   state={{ id:list.chnl_id,menu_cat_id:list.chnl_menu_cat_ids[0] }} onClick={() => {
                                                                            menuChangeByClick(list.chnl_id); handleClick(index);  toggleMenuTwo();
                                                                        }} ref={(el) => linkRefs.current[index] = el}  ><img src={list.chnl_logo} />{list.chnl_name}</Link>
                                                            </div>
                                                                )
                                                            })

      }
         </div>

                                                    {/* <ChanelList ourmblChannels = {ourChannels}  toggleMenu={toggleMenu} /> */}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="5" className='live-list'>
                                                <Accordion.Header>live tv</Accordion.Header>
                                                <Accordion.Body>
                                                    <LiveChanels mylivechannels = {ourLiveChannels} toggleMenu={toggleMenu} />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Nav>
                                    <Nav className='d-none d-lg-block d-xl-block'>
                                        <NavDropdown title="live tv" id="collasible-nav-dropdown" show={show} onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
                                            <LiveChanels mylivechannels = {ourLiveChannels} />
                                        </NavDropdown>
                                    </Nav>
                                </Nav>

                                <div className='offcanvas-footer'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='title'>Download App</div>
                                        <div className='links'>
                                            <Link to=""><FaGooglePlay /></Link>
                                            <Link to=""><FaApple /></Link>
                                        </div>
                                    </div>
                                </div>

                            </Offcanvas.Body>

                        </Navbar.Offcanvas>
                    </Container>
                </Navbar >

            ))
            }
        </>
    )
}

export default ChanelCategories