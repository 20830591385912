import React , { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { headeradurl } from "../../../globalURL";
//import { useGooglePublisherTag } from 'react-google-publisher-tag';
import {Bling as GPT} from "react-gpt";


function HeaderAdd(props) {

  useEffect(() => {
    getHeaderAd();
   
  }, [])
//alert(JSON.stringify(props));
  const [ourAds, ourSetAds] = useState([])
  
  const getHeaderAd = async () => {
    const responseBody = { };
    await axios
      .post(headeradurl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourSetAds(resp_datalist);
        }
      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }

  if(ourAds.length > 0){
  
      

 // "ad_size":"\"[728, 90]\""
    if(ourAds[0].type == "Imageupload"){
      if(props.whichad === "headerad"){
        return (

          <>
              <Link target="_blank" to={ourAds[0].redirection_link} ><img src={ourAds[0].image_url}  className='w-100'  /></Link>
          </>
        )
      }else{
        return (

          <>
              <Link target="_blank" to={ourAds[0].mbl_redirection_link} ><img src={ourAds[0].image_url_mbl}   className='w-100'  /></Link>
          </>
        )
      }
    }else{
      //const parsedAdsize = JSON.parse(JSON.parse(ourAds[0].ad_size));
    
    if(props.whichad === "headerad"){
      return (
        
        <div id={props.whichad} >
     <GPT
                adUnitPath="/21769336530/ETV_Horizontal_AD"
                sizeMapping={[
                  // { viewport: [0, 0], slot: [320, 50] },        // Mobile
                  // { viewport: [750, 0], slot: [728, 90] },      // Small tablet
                  // { viewport: [970, 0], slot: [970, 90] },      // Large tablet/small desktop
                  // {viewport: [1050, 0], slot: [1024, 120]}
                  { viewport: [1024, 768], slot: [[970, 90], [728, 90], [468, 60]] }, // Large desktop
                  { viewport: [750, 0], slot: [[728, 90], [468, 60]] }, // Tablet
                  { viewport: [300, 0], slot: [[300, 50]] } // Mobile
             
                ]}
            />
    </div>
    
      )
    }else{
      return (
        
        <div id={props.whichad} >
     <GPT
                adUnitPath="/21769336530/ETV_Horizontal_AD"
                slotSize={[350,80]}
             
            />
    </div>
    
      )
    }
      
    }
}
}

export default HeaderAdd