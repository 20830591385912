import React from 'react'
import { FaChevronLeft,FaChevronRight,} from 'react-icons/fa'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick"; 
import { Link } from 'react-router-dom';
import "./feature.css"
import PlayIcon from '../../PlayIcon';



const Feature = (props) => {

  const SampleNextArrow = (props)  => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}

      ><div className='dskfmdskf'>
        
       <FaChevronRight className='sfdsf' /></div> </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><div className='fdsf'>
 < FaChevronLeft  />
      </div>
       </div>
    );
  }
  
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay:false,
    delay: 3000,
    slidesToShow: 4,
    slidesToScroll:1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]


  };
  return (
    <div>
      
 
    <>
    <div class="row"><div class="col-lg-6 col-md-6 col-9"><h2 class="head">{props.name}</h2></div><div class="text-end col-lg-6 col-md-6 col-3"><Link class="more" to="/Episodes">More +</Link></div></div>


    <div className='feature-main'>
    <div className='feature'>
    <div className='fea-contact '>
      <Slider {...settings}>
      <div >
        <div className='carousel-card extraicon'>
            <Link to="/WeekScheduleViewVideo" >
            <div className='extra'>
              <img src="../assets/images/show-01.jpg" alt='' />
                <PlayIcon/>
              </div>
              
           
              <h4>Manasantha Nuve | 10th November | Fu....</h4>
            </Link>
        
        </div>
      </div>
      <div >
        <div className='carousel-card extraicon'>
        <Link to="/WeekScheduleViewVideo">
        <div className='extra'>
              <img src="../assets/images/show-02.jpg" alt='' />
                <PlayIcon/>
              </div>
    
            <h4>Manasantha Nuve | 10th November | Fu....</h4>
          </Link>
          
        </div>
      </div>
      <div >
        <div className='carousel-card'>
        <Link to="/WeekScheduleViewVideo">
        <div className='extra'>
              <img src="../assets/images/show-03.jpg" alt='' />
                <PlayIcon/>
              </div>
    
            <h4>Manasantha Nuve | 10th November | Fu....</h4>
            </Link>
        </div>
      </div>
      <div >
        <div className='carousel-card'>
        <Link to="/WeekScheduleViewVideo">
        <div className='extra'>
              <img src="../assets/images/show-05.jpg" alt='' />
                <PlayIcon/>
              </div>
            <h4>Manasantha Nuve | 10th November | Fu....</h4>
          </Link>
        </div>
      </div>
      <div >
        <div className='carousel-card'>
        <Link to="/WeekScheduleViewVideo">
        <div className='extra'>
              <img src="../assets/images/show-06.jpg" alt='' />
                <PlayIcon/>
              </div>
            <h4>Manasantha Nuve | 10th November | Fu....</h4>
          </Link>
        </div>
      </div>
   
    </Slider>
    </div>
    </div>
    </div>

    
    </>
    </div>
  )
}

export default Feature