import React from 'react'
import { FaPlay } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const PlayIcon = () => {
  return (
    <div> <div className='iconcame'><p><FaPlay /></p></div></div>
  
  )
}


export default PlayIcon