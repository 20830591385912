import React from 'react'
import FooterBottom from './Components/FooterBottom'
import FooterTop from './Components/FooterTop'

const Footer = () => {
  return (
    <div className='footer-total-styl'>
        
        <FooterTop/>
<FooterBottom/>
    </div>
  )
}

export default Footer