import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import "./hotsummer.css"
import {pagewiseads_url } from "../../globalURL";
import axios from 'axios';
import {Bling as GPT} from "react-gpt";

const Hotsummer = (props) => {
  var mymenuads = props.mymenuads;
  var chnl_id = props.channel_id;
  var page = props.page;
  
  useEffect(() => {
    loadPageAds(chnl_id);

    // window.googletag = window.googletag || { cmd: [] };
  
    // window.googletag.cmd.push(() => {
    //   const adSlot = window.googletag
    //     .defineSlot('/21769336530/ETV_Rectangle_AD', [[300, 250], [250, 250], [200, 200], [336, 280], [300, 200], [300, 100]], 'rightfirstposition')
    //     .addService(window.googletag.pubads());

    //   const mapping = window.googletag.sizeMapping()
    //     .addSize([1024, 768], [[300, 250], [250, 250], [200, 200], [336, 280]])
    //     .addSize([640, 480], [[300, 200], [250, 250], [200, 200], [300, 100]])
    //     .build();

    //   adSlot.defineSizeMapping(mapping);

    //   window.googletag.enableServices();
    //   window.googletag.display('rightfirstposition');
    // });

  }, [chnl_id])
  const [pageFData, setPageFData] = useState([]);
  const [pageSData, setPageSData] = useState([]);
  const [pageTData, setPageTData] = useState([]);
  const loadPageAds = async (chnl_id) => {
    //  const responseBody = { menu_cat_name: menu_cat_id, channel_id: chnl_id };
    const responseBody = { channel_id: chnl_id,page:page,position:1 };
      await axios
        .post(pagewiseads_url, JSON.stringify(responseBody), {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          var resp_data = res.data;
          if (resp_data.status === 'true' && resp_data != null) {
            var resp_datalist = resp_data.data;
            setPageFData(resp_datalist);
          }
  
        })
        .catch((error) => {
          //setIsLoading(false);
          console.log(error);
        });


        const responseBodys = { channel_id: chnl_id,page:page,position:2 };
        await axios
          .post(pagewiseads_url, JSON.stringify(responseBodys), {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            var resp_data = res.data;
            if (resp_data.status === 'true' && resp_data != null) {
              var resp_datalist = resp_data.data;
              setPageSData(resp_datalist);
            }
    
          })
          .catch((error) => {
            //setIsLoading(false);
            console.log(error);
          });


          const responseBodyT = { channel_id: chnl_id,page:page,position:3 };
          await axios
            .post(pagewiseads_url, JSON.stringify(responseBodyT), {
              headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
              var resp_data = res.data;
              if (resp_data.status === 'true' && resp_data != null) {
                var resp_datalist = resp_data.data;
                setPageTData(resp_datalist);
              }
      
            })
            .catch((error) => {
              //setIsLoading(false);
              console.log(error);
            });

    };
   // alert(JSON.stringify(pageFData));
  return (
    <div className='sum'>
         {pageFData && pageFData.map(function (ads, videoindex) {
          if(ads.ad_type === "Imageupload"){
           return (
          <Link to={ads.redirection_link}  target='_blank' className='mb-3 d-block'>
            <img src={ads.image_url} alt=""/> 
          </Link>
           )
          }else{
            return (
            <div id="rightfirstposition" className='w-100 text-center'  >
         <GPT adUnitPath="/21769336530/ETV_Rectangle_AD" 
                  slotSize={[300, 250]}
                
              />

             
           </div>
            )
          }
      })}
       {pageSData && pageSData.map(function (ads, videoindex) {
    
    if(ads.ad_type === "Imageupload"){
     return (
      <div className='d-none d-lg-block d-xl-block'><Link to={ads.redirection_link}  target='_blank' className='mb-3 '>
      <img src={ads.image_url} alt=""/> 
    </Link>
    
 
    </div>
      
     )
    }else{
      return (
      
      <div id="rightsecondposition" className='w-100 text-center'  >
          <br></br>
          <GPT
                  adUnitPath="/21769336530/ETV_Rectangle_AD"
                  slotSize={[300, 600]}
                
              />
              
   </div>
      )
    }
})}
   

    </div>
  )
}

export default Hotsummer