//export const BaseURL = "http://localhost/etv_news_backend/";
 //export const BaseURL = "http://preprodadmin.etv.co.in/";    
 export const BaseURL = "https://admin.etv.co.in/"; 
//export const BaseURL = "https://devcmoon.etv.co.in/";
//export const BaseURL = "https://admin.etv.co.in/";

export const gettajanewsurl = BaseURL + "api/tajanews";
export const getchannels_list_url = BaseURL + "api/channelslist";
export const get_main_menu_url = BaseURL + "api/channelscategories";
export const getslider_url = BaseURL + "api/channelsliders";
export const get_tvschedules = BaseURL + "api/schedulelistbychannel";
export const gettagshome_url = BaseURL + "api/channelwisetags";
export const getshow_url = BaseURL+"api/showslist";
export const getshowall_url = BaseURL+"api/showslistall";
export const showsvideoslist_url = BaseURL+"api/showsvideoslist";
export const getTagsByShows_url = BaseURL+"api/getTagsByShows";
export const getloadheading_url = BaseURL+"api/getHeadings";
export const getloadheadingpage_url = BaseURL+"api/getHeadingsBypage";
export const getHeadingsBypagenews_url = BaseURL+"api/getHeadingsBypagenews";
export const schedulelist_url = BaseURL + "api/schedulelist";
export const schedulelistall_url = BaseURL + "api/allshedules";
export const tvshedules_url = BaseURL + "api/tvshedules";
export const getallheadings_url = BaseURL + "api/getallheadingvideos";
export const getrelatedvideos_url = BaseURL + "api/getrelatedvideos";
export const getchannelbyseoname = BaseURL + "api/channelslistbyseo";
export const getnewsHeadings_url = BaseURL + "api/getnewsHeadings";
export const getnewsHeadingsDetailed_url = BaseURL + "api/getnewsHeadingDetailed";
export const getnewsHeadingsseo_url = BaseURL + "api/getnewsHeadingsseo";
export const getnewsHeadingsseomore = BaseURL + "api/getnewsHeadingsseomore";

export const getlivechannel = BaseURL + "api/getlivechannels";
export const tajanewstagsid = BaseURL + "api/tajanewstagsid";
export const getvideoseo = BaseURL + "api/getVideoBySeo";
export const getfootermenu = BaseURL + "api/getfootermenu";
export const getfootercontentbyseo = BaseURL + "api/getfootercontentbyseo";
export const getfootercontact = BaseURL + "api/getfootercontact";
export const getfootertechnical = BaseURL + "api/getfootertechnical";
export const savefeedbackurl = BaseURL + "api/savefeedback";
export const getsettingsurl = BaseURL + "api/settings";
export const getmenudataseourl = BaseURL + "api/getmenudataseo";
export const getshowseourl = BaseURL + "api/getshowseo";
export const getchannelvideoseourl = BaseURL + "api/getchannelvideoseo";
export const getTagMenuidurl = BaseURL + "api/getTagMenuidurl";
export const getMenuInfo = BaseURL + "api/getMenuInfo";
export const getnewsHeadingsGenere = BaseURL + "api/getnewsHeadingsGenere";
export const getloadmenuadsurl = BaseURL + "api/menuwiseads";
export const menuwiseshowadsurl = BaseURL + "api/sideourshows";
export const searchvideourl = BaseURL + "api/searchvideo";
export const searchshowurl = BaseURL + "api/searchshow";
export const headeradurl = BaseURL + "api/headerad";
export const tajanewsads_url = BaseURL + "api/tajanewsad";
export const get_seasons_by_show_url = BaseURL + "api/get_seasons_by_show";
export const getSeasonShowVideos_url = BaseURL + "api/getSeasonShowVideos";
export const savecareerform_url = BaseURL + "api/savecareerform";
export const savecomplaint_form = BaseURL + "api/savecomplaintform";
export const pagewiseads_url = BaseURL + "api/pagewiseads";
export const ourbaseurl = BaseURL;
