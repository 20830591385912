import React from 'react'
import "./AllShow.css"
import { Tabs} from 'react-tabs';
import AllShowView from './AllShowView';
import { Col, Container, Row } from 'react-bootstrap';



const AllShow = (heading) => {
  return (
    <div>
        
      <Tabs>
    <div className='SubpageTitleBar'>
    <Container fluid className='pad-left-80 pad-right-80'>
                  
      <Row className='shoed'>
          <Col lg={6} md={6} sm={6} xs={3}>
          <h4 className='allshiw'>{heading.name}</h4>
          </Col>
          <Col lg={6} md={6} sm={6} xs={9}> 
          {/* <TabList className="title all">
      <Tab>All {heading.name}</Tab>
      <Tab>BY GENERE</Tab>


      </TabList> */}

          </Col>
      </Row>
      </Container>
    </div>
  
    <div className='colof'>
    <Container fluid className='pad-left-80 pad-right-80'>

        <AllShowView/>
  

  
    </Container>
    </div> 
  </Tabs>
     

    </div>
  )
}

export default AllShow