import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "./AllShowView.css"
import { Link } from 'react-router-dom'

const AllShowView = () => {
  return (

    <div className='shiwd'>
 
        
<Row>

    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
      <Link to="/Showview">
      <img src="../assets/images/trending-show-06.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col  lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-04.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-02.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col  lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-01.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-06.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-05.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-02.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-01.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-06.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-03.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-04.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-02.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-06.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-04.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-02.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-01.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-06.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-05.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-02.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-01.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-06.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-03.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-04.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-02.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>

    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-06.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-04.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-02.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-01.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-06.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-05.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-02.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-01.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-06.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-03.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-04.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>
    <Col lg={2} md={3} sm={4} xs={6}>
        <div className='showforimg'>
        <Link to="/Showview">
      <img src="../assets/images/trending-show-02.jpg" alt=''/>
      </Link>
   
    </div>
    </Col>



  
</Row>
    </div>
  )
}

export default AllShowView