import React from 'react'


const Subscriptions = () => {
  return (
    <div>   
 </div>
  )
}

export default Subscriptions