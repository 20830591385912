import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { savecareerform_url } from "../globalURL";

const Careers = () => {
  const [formData, setFormData] = useState({
    txtname: '',
    mobile: '',
    txtemail: '',
    eduqualification: '',
    txtexperience: '',
  });

  const [errors, setErrors] = useState({
    txtname: '',
    mobile: '',
    txtemail: '',
    eduqualification: '',
    txtexperience: '',
    txtresume: '',
  });

  const handleReset = () => {
    // Reset the form data to empty values
    setFormData({
      txtname: '',
      mobile: '',
      txtemail: '',
      eduqualification: '',
      txtexperience: '',
    });
    // Reset validation errors
    setErrors({
      txtname: '',
      mobile: '',
      txtemail: '',
      eduqualification: '',
      txtexperience: '',
      txtresume: '',
    });
    // Clear file input value
    document.getElementById('txtname').value = '';
    document.getElementById('mobile').value = '';
    document.getElementById('txtemail').value = '';
    document.getElementById('eduqualification').value = '';
    document.getElementById('txtexperience').value = '';
    document.getElementById('txtresume').value = '';
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (formData.txtname.trim() === '') {
      newErrors.txtname = 'Please enter name';
      valid = false;
    }

    if (formData.mobile.trim() === '') {
      newErrors.mobile = 'Please enter mobile';
      valid = false;
    }

    if (formData.txtemail.trim() === '') {
      newErrors.txtemail = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.txtemail)) {
      newErrors.txtemail = 'Invalid email address';
      valid = false;
    }

    if (formData.eduqualification.trim() === '') {
      newErrors.eduqualification = 'Please enter education qualification';
      valid = false;
    }

    if (formData.txtexperience.trim() === '') {
      newErrors.txtexperience = 'Please enter experience';
      valid = false;
    }

    // Resume validation
    const resumeInput = document.getElementById('txtresume');
    const resumeFile = resumeInput.files[0];

    if (!resumeFile) {
      newErrors.txtresume = 'Please select a resume file';
      valid = false;
    } else {
      const allowedFileTypes = ['pdf', 'doc', 'docx'];
      const fileName = resumeFile.name.toLowerCase();
      const fileExtension = fileName.substr(fileName.lastIndexOf('.') + 1);

      if (allowedFileTypes.indexOf(fileExtension) === -1) {
        newErrors.txtresume = 'Invalid file type. Allowed file types: pdf, doc, docx';
        valid = false;
      }

      const maxFileSizeInBytes = 10 * 1024 * 1024; // 10 MB
      if (resumeFile.size > maxFileSizeInBytes) {
        newErrors.txtresume = 'File size exceeds the maximum allowed size (10 MB)';
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };

  const savefeedback = () => {
    if (validateForm()) {
      const formDataToSend = new FormData();
      formDataToSend.append('txtname', formData.txtname);
      formDataToSend.append('mobile', formData.mobile);
      formDataToSend.append('txtemail', formData.txtemail);
      formDataToSend.append('eduqualification', formData.eduqualification);
      formDataToSend.append('txtexperience', formData.txtexperience);
      formDataToSend.append('txtresume', document.getElementById('txtresume').files[0]);

      axios
        .post(savecareerform_url, formDataToSend)
        .then((res) => {
          console.log(res.data);
          var resp_data = res.data;
          if (resp_data.status === 'true' && resp_data !== null) {
            console.log('Form submitted successfully.');
            alert('Successfully saved!');

            // Redirect to the same path after form submission
            window.location.href = window.location.pathname;
            // Display a success message (optional)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <Container fluid className="pad-left-80 pad-right-80">
        <Row className="shoed">
          <Col lg={6} md={6}>
            <h4 className="allshiw">Careers</h4>
          </Col>
        </Row>
      </Container>
      <div className="cms-content feedback-bg-clor">
        <Container fluid className="pad-left-80 pad-right-80">
        <Row className="justify-content-center">
        <Col lg={7} md={9} sm={9} xs={10}>
          <div className="page-card ">
           
              <Form className='form-new-styling'>
              <div className='mb-3'>
                  <Form.Label>Name :</Form.Label>
                  <Form.Control
                  className='fousclass'
                    type="text"
                    id="txtname"
                    name="txtname"
                    placeholder="Name"
                    onChange={(e) => setFormData({ ...formData, txtname: e.target.value })}
                  />
                  {errors.txtname && <p className="erroeclass">{errors.txtname}</p>}
                  </div>
                  <div className='mb-3'>
                  <Form.Label>Mobile No :</Form.Label>
                  <Form.Control
                  className='fousclass'
                    type="text"
                    id="mobile"
                    name="mobile"
                    placeholder="Mobile No"
                    onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
                  />
                  {errors.mobile && <p className="erroeclass">{errors.mobile}</p>}
                  </div>
                  <div className='mb-3'>
                  <Form.Label>Email Id :</Form.Label>
                  <Form.Control
                  className='fousclass'
                    type="email"
                    id="txtemail"
                    name="txtemail"
                    placeholder="Enter email Id"
                    onChange={(e) => setFormData({ ...formData, txtemail: e.target.value })}
                  />
                  {errors.txtemail && <p className="erroeclass">{errors.txtemail}</p>}
                  </div>
                  <div className='mb-3'>
                  <Form.Label>Education qualification :</Form.Label>
                  <Form.Control
                  className='fousclass'
                    type="text"
                    id="eduqualification"
                    name="eduqualification"
                    placeholder="Education qualification"
                    onChange={(e) => setFormData({ ...formData, eduqualification: e.target.value })}
                  />
                  {errors.eduqualification && <p className="erroeclass">{errors.eduqualification}</p>}
                  </div>
                  <div className='mb-3'>
                  <Form.Label>Work Experience :</Form.Label>
                  <Form.Control
                  className='fousclass'
                    type="text"
                    id="txtexperience"
                    name="txtexperience"
                    placeholder="Work Experience"
                    onChange={(e) => setFormData({ ...formData, txtexperience: e.target.value })}
                  />
                  {errors.txtexperience && <p className="erroeclass">{errors.txtexperience}</p>}
                  </div>
                  <div className='mb-3'>
                  <Form.Label>Resume Upload :</Form.Label>
                  <Form.Control
                  className='fousclass'
                    type="file"
                    id="txtresume"
                    name="txtresume"
                    placeholder=""
                  />
                  {errors.txtresume && <p className="erroeclass">{errors.txtresume}</p>}
                  </div>
                  <div className="pading-class">
                   
                  <Row>
                  <Col lg={6} md={6} sm={6} xs={6}>
        <Button variant="primary" onClick={savefeedback} className='wid-100'>SUBMIT</Button>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6}>
        <Button variant="secondary" onClick={handleReset} className='wid-100'>RESET</Button>
        </Col>
      </Row>
                  </div>


                </Form>
          </div>
              </Col>
            </Row>
        </Container>
      </div>
    </div>
  );
};

export default Careers;
