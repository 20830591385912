import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap'
import ModaySchedule from './ModaySchedule';
import ViewAllSchedule from '../viewallschedule/viewAllSchedule';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useParams } from 'react-router-dom';
import { getchannels_list_url,getchannelbyseoname,schedulelistall_url,tvshedules_url } from "../../globalURL";
import axios from 'axios';
import { Link } from 'react-router-dom'

const TvSchedule = () => {
  const params = useParams();
  var channel_seo_name = params.channelseo;
  if(channel_seo_name == undefined){
    channel_seo_name = "etv-telugu";
  }
  const [urlviewall, urlSetviewall] = useState("/schedule/"+channel_seo_name+"/Schedule");
  const [ourchannelinfo, ourchannelInfoList] = useState([]);
  const [tvchannelid, srtTvchannelid] = useState();
  const [objslidersetting, setObjslidersetting] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0); // State variable to track the selected tab

  // Function to handle tab selection
  const handleTabSelect = (index) => {
    setSelectedTab(index);

  };
    useEffect(() => {
      fetchChannelsData();
    
      getChannelFetchInfo(channel_seo_name);
    }, [channel_seo_name])

  var chnl_id = localStorage.getItem('session_chnl_id');
  // console.log(chnl_id);
  const [ourChannels, ourChannelsList] = useState([]);
  // raji implemneted 
  const getChannelFetchInfo = async (channel_seo_name) => {
      const responseBody = { chnl_seo_name: channel_seo_name };
      await axios
        .post(getchannelbyseoname, JSON.stringify(responseBody), {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
        
          var resp_data = res.data;
          if (resp_data.status === 'true' && resp_data != null) {
           var resp_datalist = resp_data.data;
            ourchannelInfoList(resp_datalist);
            chnl_id = resp_datalist[0].chnl_id; 
            srtTvchannelid(resp_datalist[0].chnl_id);  
            fetchShedules(resp_datalist[0].chnl_id);  
          }
  
        })
        .catch((error) => {
          //setIsLoading(false);
          console.log(error);
        });
    }
  const fetchChannelsData = async () => {
    //   setIsLoading(true);
    const responseBody = {};
    await axios
      .post(getchannels_list_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourChannelsList(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };


const funSelectedChannel = event => {
  srtTvchannelid(event.target.value);  
  fetchShedules(event.target.value);  
  fetchChannelsById(event.target.value);
};
const fetchChannelsById = async (chnl_id) => {
   //   setIsLoading(true);
    const responseBody = {chnl_id:chnl_id};
    await axios
      .post(getchannels_list_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
            var url = "/schedule/"+resp_datalist[0].channel_seo_url+"/Schedule";
            urlSetviewall(url);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };
  const isCurrentTimeInRange = (fromTime, toTime) => {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentPeriod = currentHours >= 12 ? 'pm' : 'am';
  
    const [fromHours, fromMinutes, fromPeriod] = fromTime.split(/:|\s/);
    const [toHours, toMinutes, toPeriod] = toTime.split(/:|\s/);
  
    const fromHour = parseInt(fromHours, 10);
    const fromMinute = parseInt(fromMinutes, 10);
    const toHour = parseInt(toHours, 10);
    const toMinute = parseInt(toMinutes, 10);
  
    // Convert 12-hour format to 24-hour format
    const convertedFromHour = fromPeriod === 'pm' ? fromHour + 12 : fromHour;
    const convertedToHour = toPeriod === 'pm' ? toHour + 12 : toHour;
  
    if (
      (currentPeriod === 'am' && fromPeriod === 'am' && toPeriod === 'am') || // All times are in AM
      (currentPeriod === 'pm' && fromPeriod === 'pm' && toPeriod === 'pm') || // All times are in PM
      (currentPeriod === 'am' && fromPeriod === 'am' && toPeriod === 'pm' && currentHours < 12) || // AM to PM range
      (currentPeriod === 'pm' && fromPeriod === 'am' && toPeriod === 'pm' && currentHours >= 12) // AM to PM range
    ) {
      if (
        (currentHours > convertedFromHour || (currentHours === convertedFromHour && currentMinutes >= fromMinute)) &&
        (currentHours < convertedToHour || (currentHours === convertedToHour && currentMinutes <= toMinute))
      ) {
        return true;
      }
    }
  
    return false;
  };

  const fetchShedules = async (channel_id) => {
    //   setIsLoading(true);
  //
  
    const responseBody = { show_channel_id: channel_id };

    await axios
      .post(tvshedules_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setObjslidersetting([]);
        //console.log(res.data);
        var resp_data = res.data;var objarray=[]; 
       // alert(JSON.stringify());
        if (resp_data.status === 'true' && resp_data != null) {
       
          var resp_datalist = resp_data.data;  
          resp_datalist.forEach((list, index) => {
  
            // Perform any desired operation with each item
            const ourweeklydata = list[list.day]; var  obj = {};
            

          // if(list.day == "mon"){
          //   ourmontimings(initialSlide);
          // }
          // if(list.day == "tue"){
          //   ourtuetiming(initialSlide);
          // }
         // if(list.day == "wed"){
            const dayMap = {}; // Initialize a map to track selected days
            let initialSlide = 2;
            const currentWeekday = new Date().toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();
            const isToday = list.day.toLowerCase() === currentWeekday;
            if(isToday){
              {ourweeklydata &&
                ourweeklydata.forEach(function (weekdata, weekindex) {
                  let isNowPlaying = false;
                    // Check if the day has already been selected
                    if (!dayMap[currentWeekday]) {
                      isNowPlaying = isCurrentTimeInRange(weekdata.fromtime, weekdata.totime);
                  //    console.log("isNowPlaying"+weekdata.fromtime+"-"+weekdata.totime);
                      if (isNowPlaying) {
                        initialSlide = weekindex;
                        dayMap[currentWeekday] = true; // Mark the day as selected
                        return true; // Exit the loop
                      }else{
                         
                            // if (weekdata.ourindex === 1) {
                            //     initialSlide = weekindex;
                            //   //  dayMap[currentWeekday] = true;
                            //     return true; // Stop searching after the first occurrence
                            // }
                            // return false;
                       
                      }
                    }
                   
                })}
            }else if (ourweeklydata) {
              const foundWeekdata = ourweeklydata.find((weekdata, weekindex) => {
                  if (weekdata.ourindex === 1) {
                      initialSlide = weekindex;
              
                      return true; // Stop searching after the first occurrence
                  }
                  return false;
              });
          
              if (!foundWeekdata) {
                  // Handle the case where no item with ourindex equal to 1 was found
              }
          }
          
           obj.weekday = list.day.toLowerCase();
           obj.initialSlide = initialSlide;
           objarray.push(obj);
       // }
          });
          setObjslidersetting(objarray);
          const date = new Date();
          const weekdayIndex = date.getDay(); // 0 for Sunday, 1 for Monday, etc.
          //alert(weekdayIndex);
          setSelectedTab(weekdayIndex-1);
          
         // alert(JSON.stringify(objarray));
        
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };


  //alert(JSON.stringify(objslidersetting));

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });

  const getCurrentWeekday = () => {
    const options = { weekday: 'short' };
    const currentWeekday = new Date().toLocaleDateString('en-US', options);
    return currentWeekday;
  };
  const weekdayTab = getCurrentWeekday();
 
  return (

    <>
      <div className='tv-schedule'>

        <Container fluid className='pad-left-80'>
          <Row className=''>
            <Col lg={3} md={3} className="chanel">
              <div className='schedule-list'>
                <h4 className='mb-2'>Tv Schedule</h4>
                <Form>
                  <Form.Select className='mb-2'   onChange={funSelectedChannel} id="txtOurchannels" name="txtOurchannels" >
                    <option value="">Select TV Schedule</option>
                    {ourChannels &&
                      ourChannels.map(function (list, index) {
                      
                        const selectedText = list.chnl_id === tvchannelid ? "selected" : "";
                        return (
                          <option value={list.chnl_id} selected={selectedText} key={index}  > {list.chnl_name}</option>
                        )

                      })

                    }

                  </Form.Select>
                </Form>
                <p>{formattedDate}</p>
              </div>
            </Col>
            <Col lg={9} md={9} className="frid pad-right-80">
              <div className='scheduled-data'>
                <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
                  <TabList className="title moaday pading-top">
                    <Tab className={`react-tabs__tab ${selectedTab === 0 ? 'react-tabs__tab--selected' : ''}`}
 >Monday</Tab>
                    <Tab className={`react-tabs__tab ${selectedTab === 1 ? 'react-tabs__tab--selected' : ''}`}
 >Tuesday</Tab>
                    <Tab className={`react-tabs__tab ${selectedTab === 2 ? 'react-tabs__tab--selected' : ''}`}
 > Wednesday </Tab>
                    <Tab className={`react-tabs__tab ${selectedTab === 3 ? 'react-tabs__tab--selected' : ''}`}
>Thursday</Tab>
                    <Tab className={`react-tabs__tab ${selectedTab === 4 ? 'react-tabs__tab--selected' : ''}`}
 >Friday</Tab>
                    <Tab className={`react-tabs__tab ${selectedTab === 5 ? 'react-tabs__tab--selected' : ''}`}
>Saturday</Tab>
                    <Tab className={`react-tabs__tab ${selectedTab === 6 ? 'react-tabs__tab--selected' : ''}`}
>Sunday</Tab>
                  </TabList>
  
                  <TabPanel>
                    <ModaySchedule weekdays="mon" val={tvchannelid}  objslidersetting = {objslidersetting} />
                  </TabPanel>
                  <TabPanel>
                    <ModaySchedule weekdays="tue" val={tvchannelid}  objslidersetting = {objslidersetting} />
                  </TabPanel>
                  <TabPanel>
                    <ModaySchedule weekdays="wed" val={tvchannelid} objslidersetting = {objslidersetting}  />
                  </TabPanel>
                  <TabPanel>
                    <ModaySchedule weekdays="thu" val={tvchannelid} objslidersetting = {objslidersetting}  />
                  </TabPanel>
                  <TabPanel>
                    <ModaySchedule weekdays="fri" val={tvchannelid} objslidersetting = {objslidersetting} />
                  </TabPanel>
                  <TabPanel>
                    <ModaySchedule weekdays="sat" val={tvchannelid}  objslidersetting = {objslidersetting} />
                  </TabPanel>
                  <TabPanel>
                    <ModaySchedule weekdays="sun" val={tvchannelid} objslidersetting = {objslidersetting} />
                  </TabPanel>
                </Tabs>

              </div>
              <div>
              <div className='pad-12'>
      <div className='button weekbutton'>

     <Link to={urlviewall}  > <p>VIEW ALL SCHEDULE</p></Link>  

      </div>

    </div>
              </div>
            </Col>
          </Row>

        </Container>
      </div >
    </>
  )
}

export default TvSchedule