import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NewPlayIcon from './NewPlayIcon';
import { FaUndo } from 'react-icons/fa';
import PlayIcon from './PlayIcon';

const Videotabs = () => {
  return (
    <div>   
    <Tabs>
<div className=''>
<Container>
     
<Row className='shoed'>
{/* <Col lg={6} md={6} sm={6} xs={3}>
<h4 className='allshiw'>heding</h4>
</Col> */}
<Col lg={12} md={12} sm={12} xs={12}> 
<TabList className="title all seacon">
<Tab>Season 1 </Tab>
<Tab>Season 2</Tab>
<Tab>Season 3</Tab>
<Tab>Season 4</Tab>
<Tab>Season 5</Tab>
<Tab>Season 6</Tab>


</TabList>

</Col>
</Row>
</Container>
</div>

<div className='colof'>
<Container>


{/* 
<TabPanel>
<p>season 2</p>
</TabPanel>
<TabPanel>
<p>season 3</p>
</TabPanel>
<TabPanel>
<p>season 4</p>
</TabPanel>
<TabPanel>
<p>season 5</p>
</TabPanel>
<TabPanel>
<p>season 6</p>
</TabPanel> */}



</Container>
</div> 
</Tabs>
</div>
  )
}

export default Videotabs