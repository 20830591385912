import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import PlayIcon from '../PlayIcon';
import "../../App.css"
import { Link,useParams } from 'react-router-dom';
import { FaUndo, } from 'react-icons/fa';
import { getallheadings_url,getchannelbyseoname,getTagMenuidurl,getMenuInfo } from "../../globalURL";
import axios from 'axios';
const Episodes = () => {
  const param = useParams();
  var channel_seo_name = param.channelseo;
  var menuname = param.menuname;
  var seo_tag_title = param.seo_tag_title;
  var menu_cat_id = 0;
  const [storechannel, setStorechannel] = useState([]);
  const [ourhometags, setOurHeadings] = useState([]);
  //const [currentPage, setCurrentPage] = useState(1);
  //const [totalPages, setTotalPages] = useState(0);
  var totalPages = 0;
  var currentPage = 1;
  const [showScroller, setShowScroller] = useState(false);
var ourchannel_id=0;
var tagid = 0;
useEffect(() => {
  getMenuInfo(menuname);
  getChannelInfo(channel_seo_name,seo_tag_title);


}, [channel_seo_name,seo_tag_title,menuname])
const getChannelInfo = async (channel_seo_name,seo_tag_title) => {
  const responseBody = { chnl_seo_name: channel_seo_name };
  await axios
    .post(getchannelbyseoname, JSON.stringify(responseBody), {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
      var resp_data = res.data;
      if (resp_data.status === 'true' && resp_data != null) {
        var resp_datalist = resp_data.data;
        setStorechannel(resp_datalist);
       // getChannelVideoId(resp_datalist[0].chnl_id,videtitle,seo_tag_title);
       getMenuid(resp_datalist[0].chnl_id,seo_tag_title)


      }

    })
    .catch((error) => {
      //setIsLoading(false);
      console.log(error);
    });
}
//currently not using .. need to check 
// const handleScroll = () => {
//   const distanceToBottom = document.documentElement.offsetHeight - (window.innerHeight + window.scrollY);
//   const loadThreshold = 100;

//   if (distanceToBottom < loadThreshold) {
//     if (currentPage < totalPages) {
//       setCurrentPage(prevPage => prevPage + 1);
//     }
//   }
// };
const handleScroll = () => {
  const distanceToBottom = document.documentElement.offsetHeight - (window.innerHeight + window.scrollY);
  const loadThreshold = 100;

  if (distanceToBottom < loadThreshold) {
   
     // alert(JSON.stringify(currentPage));
    if (currentPage <= totalPages) {
      const nextPage = currentPage + 1;
  
      // Increment currentPage after calling loadAllHeadings
      //setCurrentPage(nextPage);
      currentPage = nextPage;

      // Call loadAllHeadings with the new page
      loadAllHeadings(tagid, ourchannel_id, nextPage);

    }
  }
};

const getMenuInfo = async (menuname) => {
  const responseBody = { menu_cat_name: menuname };
  await axios
    .post(getMenuInfo, JSON.stringify(responseBody), {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
      var resp_data = res.data;
      if (resp_data.status === 'true' && resp_data != null) {
        var resp_datalist = resp_data.data;
     // console.log(resp_datalist);
        menu_cat_id= resp_datalist[0].menu_cat_id;

      }

    })
    .catch((error) => {
      //setIsLoading(false);
      console.log(error);
    });
}
const getMenuid = async (channel_id,seo_tag_title) => {
  const responseBody = { channel_id: channel_id,seo_tag_title:seo_tag_title };
  await axios
    .post(getTagMenuidurl, JSON.stringify(responseBody), {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
      var resp_data = res.data;
      if (resp_data.status === 'true' && resp_data != null) {
        var resp_datalist = resp_data.data;
        ourchannel_id = channel_id; tagid = resp_datalist[0].tag_id;
        loadAllHeadings(resp_datalist[0].tag_id,ourchannel_id,currentPage);
      }

    })
    .catch((error) => {
      //setIsLoading(false);
      console.log(error);
    });
}

 // const tag_id = param.id;
 // const chnl_id = localStorage.getItem('session_chnl_id');

 const loadAllHeadings = async (tag_id, chnl_id, page) => {
  //const responseBody = { tag_id, channel_id: chnl_id, menu_cat_id, page };
   const responseBody = { tag_id: tag_id, channel_id: chnl_id,page:page};

  try {
    const response = await axios.post(getallheadings_url, JSON.stringify(responseBody), {
      headers: { "Content-Type": "application/json" },
    });
    const resp_data = response.data;
    if (resp_data.status === 'true' && resp_data.data != null) {
      const resp_datalist = resp_data.data;
      const newTotalPages = resp_datalist[0].totalPages;
     // console.log(newTotalPages);
   
      // Append the new data to the existing array
      setOurHeadings(prevHeadings => [...prevHeadings, ...resp_datalist]);

      // Update the total pages
      totalPages = newTotalPages;

      // Update the scroller visibility
      //setShowScroller(newTotalPages > currentPage);
       // Add an event listener to listen for scroll events
 window.addEventListener('scroll', handleScroll);

 // Remove the event listener when the component unmounts
 return () => {
   window.removeEventListener('scroll', handleScroll);
 };
    }
  } catch (error) {
    console.log(error);
  }
};

//   const loadAllHeadings = async (tag_id,chnl_id,currentPage) => {
  
//     const responseBody = { tag_id: tag_id, channel_id: chnl_id,menu_cat_id:menu_cat_id,page:currentPage};
//  //console.log(responseBody);
//     await axios
//       .post(getallheadings_url, JSON.stringify(responseBody), {
//         headers: { "Content-Type": "application/json" },
//       })
//       .then((res) => {
     
//         var resp_data = res.data;
//         if (resp_data.status === 'true' && resp_data != null) {

//           var resp_datalist = resp_data.data;
//           const totalPages = resp_datalist[0].totalPages;
//           // After fetching data, update total pages
//           setTotalPages(totalPages);
//           // Set scroller visibility based on total pages
//           setShowScroller(totalPages > 1);
//           setOurHeadings(resp_datalist);
      
//         }

//       })
//       .catch((error) => {
//         //setIsLoading(false);
//         console.log(error);
//       });
//   };
// alert(ourhometags);
  const handleScrollerClick = () => {
    // Increment the current page
   //setCurrentPage(currentPage + 1);
   // setCurrentPage(prevPage => prevPage + 1);

    // Fetch data for the next page
     loadAllHeadings(tagid,ourchannel_id,currentPage + 1);
  };
  
  return (
    <>
    <div className='subtile'><Container fluid ><h4 className='allshiw'> {seo_tag_title.replace('-', ' ')}</h4></Container></div>
    <div className='alledid'>
      <div className='custem-container'>
    <Container fluid >    
    <Row className="row-cols-auto" style={{margin:"0px"}}>
  
    {ourhometags &&
          ourhometags.map(function (listdata, index) {
           // alert(JSON.stringify(listdata.taginfo));
            let tosuburl = "/"+listdata.channel_seo_url +"/showview/"+ listdata.show_name_seo_url;
          
            var taginfo = listdata.taginfo;           
            if(taginfo.tag_type == "show"){
                return (
                     <Col key={index}  className='pe-0'>
                        <div className='img-size epidos-add' style={{borderRadius:"0px",padding:"0px"}} >
                            <Link to={tosuburl} >
                                <div className='extra exa-mar'>
                                {/* <img src={listdata.show_image} alt='' /> */}
                                {taginfo.layout_type === "horizontal" ? (
                                  <img src={listdata.show_image} alt='' />
                                ) : (
                                  <img src={listdata.show_image2} alt='' />
                                )}

                                </div>

                            {/* <h4>{listdata.sv_title}</h4> */}

                            </Link>

                        </div>
                      </Col>
               
                );
            }
   
   })}
    </Row>





    <Row style={{margin:"0px"}}>
    {ourhometags &&
          ourhometags.map(function (listdata, index) {
           // alert(JSON.stringify(listdata.taginfo));
            let tosuburl = "/"+listdata.channel_seo_url +"/showview/"+ listdata.show_name_seo_url;
            var taginfo = listdata.taginfo;           
            if(taginfo.tag_type == "video"){

              if(taginfo.layout_type === "horizontal"  ){ 
//horizontal
                  let tosuburl = "/video/"+listdata.channel_seo_url +"/"+ listdata.seo_tag_title+"/"+ listdata.sv_title_seo;
                  return (
                    <Col lg={2} md={2} sm={4} xs={6} key={index} style={{padding:"0px"}}>
                  <div className='carousel-card epidos' style={{borderRadius:"0px"}}>
                  <Link to={tosuburl} >
                  <div className='extra'>
              
                    {listdata.custom_youtube_type === 'Youtube' ? (
                                      <img src={listdata.medium_img} alt='' />
                                      ) : (
                                        taginfo.layout_type === 'horizontal' ? (
                                      <img src={listdata.image_path} alt='' />
                                      ) : (
                                      <img src={listdata.vertical_image} alt='' />
                                      )
                                      )}

                  <PlayIcon/>
                  </div>

                  <h4>{listdata.sv_title} </h4>

                  </Link>

                  </div>
                  </Col>
                  );

              }else{
                //vertical divs
                let tosuburl = "/video/"+listdata.channel_seo_url +"/"+ listdata.seo_tag_title+"/"+ listdata.sv_title_seo;
                return (
                  <Col lg={2} md={2} sm={4} xs={4} key={index} style={{padding:"0px"}}>
                      <div className='carousel-card epidos' style={{borderRadius:"0px"}}>
                      <Link to={tosuburl} >
                      <div className='extra'>
                      {/* <img src={listdata.image_path} alt='' /> */}
                            {listdata.custom_youtube_type === 'Youtube' ? (
                            <img src={listdata.medium_img} alt='' />
                            ) : (
                              taginfo.layout_type === 'horizontal' ? (
                            <img src={listdata.image_path} alt='' />
                            ) : (
                            <img src={listdata.vertical_image} alt='' />
                            )
                            )}
                          
                                
                      <PlayIcon/>
                      </div>

                      <h4>{listdata.sv_title} </h4>

                      </Link>

                      </div>
                      </Col>
                      );

              }
          
            }
   
   })}
    







  
    </Row>



    {/* <div className='text-center paddin-25'><Button > Load More  <FaUndo /> </Button></div> */}
{/* 
    {showScroller && (
          <div className='page-scroller'>
            <button onClick={handleScrollerClick}>Load More <FaUndo /> </button>
          </div>
        )}
     */}
    </Container>
    </div>
    </div>

    </>
  )
}

export default Episodes