import React,{useEffect} from 'react'
import { FaChevronLeft,FaChevronRight,} from 'react-icons/fa'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick"; 
import { Link } from 'react-router-dom';
import PlayIcon from './PlayIcon';



const Episodes = (props) => {
  const seasonsVideoList = props.ourSeasonsVideoList;
  //console.log(seasonsVideoList);
  useEffect(() => {

    const tabElement = document.getElementById('tab:r0:0');
    
    // Check if the element exists before triggering a click event
    if (tabElement) {
      tabElement.click();
    }
  }, [])
//alert(JSON.stringify(seasonsVideoList));
 const SampleNextArrow = (props)  => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            onClick={onClick}
    
          ><div className='dskfmdskf'>
            
           <FaChevronRight className='sfdsf' /></div> </div>
        );
      }
    
      const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            onClick={onClick}
          ><div className='fdsf'>
     < FaChevronLeft  />
          </div>
           </div>
        );
      }
      
      var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        autoplay:false,
        delay: 3000,
        slidesToShow: 4,
        slidesToScroll:1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        
        
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
    
    
      };
      if(seasonsVideoList.length > 0){
      return (

        <div>
          
     
        <>
        <div class="row"><div class="col-lg-12 col-md-12 "><h2 class="head ">Episodes</h2></div></div>
    
    
        <div className='feature-main'>
        <div className='feature'>
        <div className='fea-contact '>
          <Slider {...settings}>
          {seasonsVideoList && seasonsVideoList.map(function (videolist, videoindex) {
               let tosuburl = "/video/" + videolist.channel_seo_url + "/" + videolist.show_name_seo_url + "/" + videolist.sv_title_seo;
               return (
          <div >
            <div className='carousel-card extraicon'>
                <Link to={tosuburl} >
                <div className='extra'>
                  {/* <img src={videolist.image_path} alt='' /> */}
                 
                       {videolist.custom_youtube_type === 'Youtube' ? (
                                          <img src={videolist.medium_img} alt='' />
                                          ) : (
                                             <img src={videolist.image_path} alt='' />
                                          )}
                    <PlayIcon/>
                  </div>
                  
               
                  <h4>{videolist.sv_title} </h4>
                </Link>
            
            </div>
          </div>
               )
              })}
          
       
        </Slider>
        </div>
        </div>
        </div>
    
        
        </>
        </div>
      )
            }
            
    }
    


export default Episodes