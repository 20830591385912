import React, { useState, useEffect } from 'react'
import { Container, Row, Col, } from 'react-bootstrap'
import {  FaEnvelope, FaLandmark,  FaMapMarkerAlt, FaPhone, FaUserTie } from 'react-icons/fa'
import { getfootercontact,getchannelbyseoname } from "../globalURL";
import axios from 'axios';
import { useParams } from 'react-router-dom'


const Contact = () => {
  const param = useParams();
 // 
  var seo_name = param.pagename;
  var channelseo = param.channelseo;
  //alert(JSON.stringify(channelseo));
  const [contactFooterContent, setContactFooterContent] = useState([]);
  useEffect(() => {
    getChannelFetchInfo(channelseo,seo_name)
  }, [channelseo,seo_name])


  const getChannelFetchInfo = async (channel_seo_name,seo_name) => {
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {

        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
         // chnl_id = resp_datalist[0].chnl_id;
          //fetchShedules(props.val, chnl_id);
          getFootercontact(resp_datalist[0].chnl_id,seo_name);

        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const getFootercontact = async (chnl_id,seo_name) => {
  //  alert(chnl_id);
    const responseBody = { chnl_id:chnl_id,seo_name: seo_name };
    await axios
      .post(getfootercontact, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
      
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          console.log(resp_datalist);
          setContactFooterContent(resp_datalist);
  
  
        }
  
      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  return (
    <div>  
      <div className='SubpageTitleBar'>
        <Container fluid className='pad-left-80 pad-right-80'>
          <Row className='shoed'>
          <Col lg={6} >
          <h4 className='allshiw'>Contact Us</h4>
            </Col>
          </Row>
        </Container>
        </div>

        <div className='cms-content'>
          <Container fluid className='pad-left-80 pad-right-80'>
            <Row>
            {contactFooterContent &&
        contactFooterContent.map(function (sublist, index) {
          return(
              <Col lg={3} md={6} sm={6}>
                <div className='address-block'>
                    <div className='chanelLogo'>
                      <img src={sublist.chnl_logo} alt='etv telugu' />
                    </div>
                    <h5>{sublist.chnl_name}</h5>
                    <div className='d-flex'>
                    <FaLandmark/>
                    <p>{sublist.designation1}</p>
                    </div>
                    <div className='d-flex'>
                    <FaUserTie/>
                    <p>{sublist.designation2}</p>
                    </div>
                    <div className='d-flex'>
                    <FaPhone/>
                    <p>{sublist.mobile_no}</p>
                    </div>
                    <div className='d-flex'>
                    <p><FaEnvelope/>{sublist.email}</p>
                    </div>
                    <div className='d-flex'>
                      <FaMapMarkerAlt className='me-3'/>
                      <p>{sublist.address}</p>
                    </div>
                </div>
              </Col>
          )
        })}

             

            </Row>
          </Container>
        </div>
 </div>
  )
}

export default Contact