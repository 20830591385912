import React, { useEffect, useState, useRef } from 'react'

import { Link, useLocation } from 'react-router-dom'


const ChanelList = (props,{ toggleMenu }) => {
  const linkRefs = useRef([]);
const ourmblChannels = props.ourmblChannels;
  const location = useLocation();
  const menuChangeByClick = (chnl_id) => {
    localStorage.setItem("session_chnl_id", chnl_id);
    // window.location.reload(false);
    //setChnId(chnl_id);
}
function handleClick(index) {
  // alert(index);
  if (index === 0) {
      linkRefs.current[0]?.click();
  }
}
  return (

    <div className='telufu'>
      {ourmblChannels &&
      ourmblChannels.map(function (list, index) {

          let tosuburl = ''; let  ourmetaurl = '';
          if (list.chnl_type_template === "NonNews") {
              tosuburl = "/" + list.channel_seo_url; ourmetaurl = list.metachannel ;
          } else {
              tosuburl = "/" + list.channel_seo_url + "/news"; ourmetaurl = list.metachannel
          }
          

          return (  
             <div className={location.pathname === '/' + ourmetaurl ? 'active etv' : 'etv'}>

      <Link to={tosuburl}   state={{ id:list.chnl_id,menu_cat_id:list.chnl_menu_cat_ids[0] }} onClick={() => {
                      menuChangeByClick(list.chnl_id); handleClick(index);toggleMenu();
                  }} ref={(el) => linkRefs.current[index] = el}  >
                    <img src={list.chnl_logo} />{list.chnl_name}
                    
                    </Link>
      </div>
          )
      })

      }
      {/* <div className={location.pathname === '' ? 'active etv' : 'etv'}>
        <Link to="/" onClick={toggleMenu}><img src="./assets/images/etv-plus.png" />etv plus</Link>
      </div>
      <div className={location.pathname === '' ? 'active etv' : 'etv'}>
        <Link to="/" onClick={toggleMenu}><img src="./assets/images/etv-cinema.png" />etv cinema</Link>
      </div>
      <div className={location.pathname === '' ? 'active etv' : 'etv'}>
        <Link to="/" onClick={toggleMenu}><img src="./assets/images/etv-abhiruchi.png" />etv abhiruchi</Link>
      </div>
      <div className={location.pathname === '' ? 'active etv' : 'etv'}>
        <Link to="/" onClick={toggleMenu}><img src="./assets/images/etv-life.png" />etv life</Link>
      </div>
      <div className={location.pathname === '/NewsHome' ? 'active etv' : 'etv'}>
        <Link to="/NewsHome" onClick={toggleMenu} ><img src="./assets/images/etv-andhra.png" />etv andhra pradesh</Link>
      </div>
      <div className={location.pathname === '' ? 'active etv' : 'etv'}>
        <Link to="/NewsHome" onClick={toggleMenu}><img src="./assets/images/etv-telangana.png" />etv telangana</Link>
      </div>
 */}








    </div>
  )
}

export default ChanelList