import React, { useState, useEffect }  from 'react'
import "./AllShow.css"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Col, Container, Row } from 'react-bootstrap';
import AllNewsView from './AllNewsView';
import ByGenereNews from './ByGenereNews';
import { Link,json,useParams } from "react-router-dom";
import { getchannelbyseoname,getmenudataseourl,getnewsHeadingsGenere} from "../../../globalURL";
import axios from 'axios';
import PlayIcon from '../../PlayIcon';

const AllNews = (taginfo) => {

  const params = useParams();
  const channel_seo_name = params.channelseo;
  const menuname = params.menuname;
  const [ourgenerHeadings, getSetOurHeadings] = useState([]);
  var ournewstagsdata = taginfo.ourdatatags;
  
  useEffect(() => {
    getChannelInfo(channel_seo_name,menuname);
  }, [channel_seo_name,menuname])
  const getChannelInfo = async (channel_seo_name,menuname) => {
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
      
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          var channel_id = resp_datalist[0].chnl_id;
          // var menucatidsdata = resp_datalist[0].chnl_menu_cat_ids;
          // var firstmenu_id = menucatidsdata[0];
          getMenuInfo(menuname,channel_id);   
         // getlivechannelsdata(channel_id)
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const getMenuInfo = async (menuname,chnl_id) => {
    const responseBody = { menuname: menuname };
    await axios
      .post(getmenudataseourl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          getOurHeadings(resp_datalist[0].menu_cat_id,chnl_id);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }

  const getOurHeadings = async (menu_cat_id,chnl_id) => {
    const responseBody = { channel_id: chnl_id,tag_menu_id:menu_cat_id};
    await axios
      .post(getnewsHeadingsGenere, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          getSetOurHeadings(resp_datalist);
          
        }
  
      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const [selectedValue, setSelectedValue] = useState();
  const [selectedPrevValue, setPrevSelectedValue] = useState();
  const menuGeneClick = (title) => {
     
    //localStorage.setItem("session_chnl_id", chnl_id);
    // window.location.reload(false);
    setSelectedValue(title);
   // alert(JSON.stringify(chnl_id));
  }
//alert(selectedValue);
  // Callback function to receive data from ChildComponent
  // const handleValueSelection = (value) => {
  //   setSelectedValue(value);
  // };
  if(ournewstagsdata.length > 0){
   // alert(selectedValue);
   // var [selectedValue, setSelectedValue] = useState(ournewstagsdata[0].tag_title);
    //setSelectedValue(ournewstagsdata[0].tag_title);
    //const [chnlId, setChnId] = useState(1);
   //setSelectedValue(ournewstagsdata[0].tag_title);
   const ourtitle = (selectedValue === "" || selectedValue === undefined) ? ournewstagsdata[0].tag_title : selectedValue;

  return (
    <div>
        
                 <Tabs>
    <div className='SubpageTitleBar'>
    <Container fluid className="pad-left-80 pad-right-80">
                  
      <Row className='shoed'>
          <Col lg={6} md={6} sm={12} xs={12}>
          <h4 className='allshiw'>{ourtitle}</h4>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}> 
          <TabList className="title all">
      <Tab>All {ournewstagsdata[0].tag_title}</Tab>
      <Tab>BY Category</Tab>


      </TabList>

          </Col>
      </Row>
      </Container>
    </div>
  
    <div className='colof'>
    <Container fluid >
    <TabPanel>
      <AllNewsView ourallnews = {ournewstagsdata}  channel_seo_name={channel_seo_name} />
    </TabPanel>
    <TabPanel>
      
    {/* <ByGenereNews setSelectedValue={setSelectedValue}   ourallheadings = {ourgenerHeadings}  channel_seo_name={channel_seo_name} /> */}

    <div className='colored'>
 
        <Tabs >

          <TabList className="title comedy"   >
            <Row>
              <Col lg={4}></Col>
            </Row>
            {ourgenerHeadings &&
              ourgenerHeadings.map(function (list, subindex) {
                return (
                  <Tab  value={list.tag_title}  onClick={() => {
                    menuGeneClick(list.tag_title); 
                }}  >
                    {list.tag_title} <span>{list.video_list_cnt}</span>
                    
                  </Tab>
                )
              })}

          </TabList>


          {ourgenerHeadings &&
            ourgenerHeadings.map(function (list, subindex) {
              const video_list = list.video_list;


              return (
                <TabPanel key={subindex}>


                  <div className='shiwd'>


                    <Row>
                      {video_list &&
                        video_list.map(function (subdata, subindex) {
                          const seo_tag_titles = list.seo_tag_title;
                          return (
                            <Col lg={2} md={2} sm={4} xs={6}>
                              <div className='topnew'>
                                <Link to={`/NewsView/${channel_seo_name}/${list.menu_cat_name}/${seo_tag_titles}/${subdata.sv_title_seo}`} >
                                  <div className='newsf'>
                                    <img src={subdata.medium_img} />
                                    <PlayIcon />
                                  </div>
                                  <div className='contant-limit'>
                                  <h4>{subdata.sv_title}</h4>
                                  </div>
                                </Link>

                              </div>
                            </Col>
                          )
                        })}

                    </Row>
                    {/* <div className='text-center paddin-25'><Button > Load More  <FaUndo /> </Button></div> */}
                  </div>

                </TabPanel>

              )
            })}

          {/* 
<TabPanel>
<AllNewsView/>
</TabPanel>
<TabPanel>
<AllNewsView/>
</TabPanel> */}


        </Tabs>


    </div>
  
    
    
    </TabPanel>
    </Container>
    </div> 
  </Tabs>
     

    </div>
  )
  }
}

export default AllNews