import React from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import "./General.css"
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import NewPlayIcon from '../../NewPlayIcon';
import PlayIcon from '../../PlayIcon';
const GeneralNext = (props) => {
  const datageneral = props.datageneralnext;
  const channel_seo_name = props.channelseoname;
  const video_list = datageneral.video_list;
  //console.log(video_list);
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}

      ><div className='dskfmdskf'>

          <FaChevronRight className='sfdsf' /></div> </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><div className='fdsf'>
          < FaChevronLeft />
        </div>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    delay: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]


  };
  if (datageneral.video_list_cnt > 0) {
    return (



      <>



        <div className='hebusin'>
          <div class="row">
            <div class="col"><h2 class="head headd">{datageneral.tag_title}</h2></div><div class="col"><a class="moed" href={`/AllNewsPage/${channel_seo_name}/${datageneral.menu_cat_name}/${datageneral.seo_tag_title}`}  >More +</a></div></div>
          <div className='fea-contact fea-gender'>
            <Slider {...settings}>
              {video_list &&
                video_list.map(function (listdata, index) {
                  return (
                    <div >
                      <div className='carousel-card extrasty'>
                        <Link to={`/NewsView/${channel_seo_name}/${datageneral.menu_cat_name}/${datageneral.seo_tag_title}/${listdata.sv_title_seo}`} >
                          <div className='newsf'>
                            <img src={listdata.medium_img} />
                            <PlayIcon />
                          </div>

                          <h4>{listdata.sv_title}</h4>
                        </Link>
                      </div>
                    </div>
                  )
                })}

              {/*        
        <div >
          <div className='carousel-card extrasty'>
          <Link to="/NewsView">
          <div className='newsf'>
                    <img src="../assets/images/g-news-03.jpg"/>
                    <NewPlayIcon/>
                    </div>
     
              <h4>2016 నవంబర్ 8న కేంద్రప్రభుత్వం తీసుకున్న పెద్దనోట్ల రద్దును</h4>
              </Link>
          </div>
        </div>
        <div >
          <div className='carousel-card extrasty'>
          <Link to="/NewsView">
          <div className='newsf'>
                    <img src="../assets/images/g-news-04.jpg"/>
                    <NewPlayIcon/>
                    </div>
        
              <h4>దేశ రాజకీయాల్లో కీలకంగా మారనున్న 2023</h4>
            </Link>
          </div>
        </div>
        <div >
          <div className='carousel-card extrasty'>
          <Link to="/NewsView">
          <div className='newsf'>
                    <img src="../assets/images/g-news-05.jpg"/>
                    <NewPlayIcon/>
                    </div>
    
              <h4>పెండింగ్ బకాయిలు చెల్లించాలంటూ అనంతపురంలో ఆమరణ</h4>
            </Link>
          </div>
        </div>
        <div >
          <div className='carousel-card extrasty'>
          <Link to="/NewsView">
          <div className='newsf'>
                    <img src="../assets/images/g-news-06.jpg"/>
                    <NewPlayIcon/>
                    </div>
          
              <h4>ప్రముఖ హీరో నందమూరి బాలకృష్ణ వ్యాఖ్యాతగా వ్యవహరిస్తున్న టాక్</h4>
            </Link>
          </div>
        </div>
        <div >
          <div className='carousel-card extrasty'>
          <Link to="/NewsView">
          <div className='newsf'>
                    <img src="../assets/images/g-news-05.jpg"/>
                    <NewPlayIcon/>
                    </div>
              <h4>ప్రముఖ హీరో నందమూరి బాలకృష్ణ వ్యాఖ్యాతగా వ్యవహరిస్తున్న టాక్</h4>
            </Link>
          </div>
        </div>
        <div >
          <div className='carousel-card extrasty'>
          <Link to="/NewsView">
          <div className='newsf'>
                    <img src="../assets/images/g-news-06.jpg"/>
                    <NewPlayIcon/>
                    </div>
              <h4>నటుడు చలపతిరావు కన్నుమూత - ప్రముఖుల నివాళి</h4>
            </Link>
          </div>
        </div> */}

            </Slider>
          </div>
        </div>



      </>

    )
  }
}
export default GeneralNext