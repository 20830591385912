import React from 'react'
import {Bling as GPT} from "react-gpt";
import "./AddBannerTop.css"
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick"; 
const AddBannerTop = (props) => {
  const topbannerdata = props.topbanner;
  //alert(JSON.stringify(topbannerdata));
  
  return (
    <div className='sum shope  d-lg-block d-xl-block'>
    

    {topbannerdata && topbannerdata.map(function (ads, videoindex) {
         if(ads.ad_type == "Imageupload"){


     return(
      <div>
        <div className='schedule'>
        <Link to={ads.redirection_link} target='_blank' className='mb-3 d-block'>
        <img src={ads.image_url} alt=""/> 
      </Link>
        </div>
      </div>
      )
     }else{
      return(
      <>
                <div
                  id="sidevideoAd"
                  className=''
                >
                  <GPT
                    adUnitPath='/21769336530/ETV_Rectangle_AD'
                    slotSize={[props.adwidth, props.adheight]}
                  />
                </div>

               
              </>
      )
    }
    })}

  {/* <div>
  <div className='schedule'>
  <Link target='_blank' to="https://www.etvwin.com/win-exclusive/dil-se" className='mb-3 d-block'>
  <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/ads1697456610WhatsApp-Image-2023-10-16-at-17_07_52.jpeg" alt="" style={{borderRadius:"8px"}}/> 
  </Link>
  </div>
  </div>
    */}

    
    </div>
  )
}


export default AddBannerTop