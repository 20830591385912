import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaFacebookF ,FaTwitter ,FaInstagram, FaYoutube, FaGooglePlus } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import AppLinks from '../../Header/Components/AppLinks'
import "./footertop.css"
import axios from 'axios';
import { useParams } from "react-router-dom";
import { getsettingsurl,getchannelbyseoname } from "../../../globalURL";

const FooterTop = () => {
  const params = useParams();
  const [ourChannelinfo, ourSetChannelinfo] = useState([])
  var channel_seo_name = params.channelseo;
  if(channel_seo_name == undefined){
    channel_seo_name = "etv-telugu";
  }
  //alert(JSON.stringify(channel_seo_name));

  useEffect(() => {
    getSettings();
    getChannelInfo(channel_seo_name)

  }, [channel_seo_name])
  const [oursettings, oursettingslist] = useState([])
  const getSettings = async () => {
    const responseBody = { };
    await axios
      .post(getsettingsurl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          oursettingslist(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }

  const getChannelInfo = async (channel_seo_name) => {
    if(channel_seo_name == undefined){ channel_seo_name = "etv-telugu";}
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
            ourSetChannelinfo(resp_datalist);
        
        //  
        }else{
        
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  if(ourChannelinfo.length > 0){
  return (
    <div className='footermain '>
        
        <Container fluid className='pad-left-80 pad-right-80'>
        
    <Row flued className='align-items-center'>
    <Col lg={6} md={6} sm={12} sx={12} className='displa-mobile-none'>
    
    <AppLinks download="Dowload App"  oursettings={oursettings} className="parent"/>
   
   </Col>
   <Col lg={6} md={6} sm={12} sx={12}>
    

    <ul className='cont'>
    {
  ourChannelinfo[0].chnl_facebook_fanpage === "" ||
  ourChannelinfo[0].chnl_twitter_hashtag === "" ||
  ourChannelinfo[0].chnl_youtube_link === "" ||
  ourChannelinfo[0].chnl_instapage === "" ||
  ourChannelinfo[0].chnl_googleplus === ""
    ? <li></li>
    : <li>Connect with us</li>
}  
 
        {ourChannelinfo[0].chnl_facebook_fanpage!="" && <li><div className='soci'><Link to={ourChannelinfo[0].chnl_facebook_fanpage} target='_blank' className='conte'><FaFacebookF /></Link></div></li> }
        {ourChannelinfo[0].chnl_twitter_hashtag!="" &&  <li> <div className='soci'><Link to={ourChannelinfo[0].chnl_twitter_hashtag} target='_blank' className='conte'><FaTwitter /></Link></div></li> }
      {ourChannelinfo[0].chnl_youtube_link!="" &&  <li><div className='soci'><Link to={ourChannelinfo[0].chnl_youtube_link} target='_blank' className='conte'><FaYoutube /></Link></div></li> }
      {ourChannelinfo[0].chnl_instapage!="" &&  <li><div className='soci'><Link to={ourChannelinfo[0].chnl_instapage} target='_blank' className='conte'><FaInstagram /></Link></div></li> }
      {ourChannelinfo[0].chnl_googleplus!="" &&  <li><div className='soci'><Link to={ourChannelinfo[0].chnl_googleplus} target='_blank' className='conte'><FaGooglePlus /></Link></div></li> }

             
                  </ul>
  
   
    </Col>

    </Row>
    </Container>
    </div>
  )
}
}
export default FooterTop