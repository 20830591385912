import React, { useEffect,useState } from 'react'
import { Link } from 'react-router-dom'
import "./applinks.css"
import { getsettingsurl } from "../../../globalURL";
import axios from 'axios';

function AppLinks(props) {
  //const appsettings = props.oursettings;
  //console.log(props)
  useEffect(() => {
    getSettings();

  }, [])
  const [oursettings, oursettingslist] = useState([])
  const getSettings = async () => {
    const responseBody = { };
    await axios
      .post(getsettingsurl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          oursettingslist(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  if(oursettings.length > 0){
  return (
    <>
        <ul className='app-links'>
       
            <li className='me-2'>
                <Link to={oursettings[0].android_download_url} target="_blank">
                    <img src={oursettings[0].playstoreimage} alt='Google Play' />
                </Link>
            </li>

            <li>
                <Link to={oursettings[0].ios_download_url} target="_blank">
                    <img src={oursettings[0].appstoreimage} alt='App Store' />
                </Link>
            </li>
            {/* <li className='me-2'>
                <Link to={props.oursettings[0].android_download_url} target="_blank">
                    <img src='./assets/images/google-play.png' alt='Google Play' />
                </Link>
            </li>

            <li>
                <Link to={props.oursettings[0].ios_download_url} target="_blank">
                    <img src='./assets/images/app-store.png' alt='App Store' />
                </Link>
            </li> */}
        </ul>
    </>
  )
  }
}

export default AppLinks