import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "./TodayShedules.css"
import NewPlayIcon from '../../NewPlayIcon'
import PlayIcon from '../../PlayIcon'
import { FaChevronLeft,FaChevronRight } from 'react-icons/fa'
import Slider from 'react-slick'

const TodayShedules = (obj) => {

  const ourtodayheadlines = obj.ourtodayheadlines;
  //alert(JSON.stringify(ourtodayheadlines));
  const video_list = ourtodayheadlines.video_list;
  const channel_seo_name = obj.channelseoname;
  const SampleNextArrow = (props)  => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}

      ><div className='dskfmdskf'>
        
       <FaChevronRight className='sfdsf' /></div> </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><div className='fdsf'>
 < FaChevronLeft  />
      </div>
       </div>
    );
  }
  
  
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    delay: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]


  };

  return (
    <div>
      
 
    <div className='mar-t-20'>
    <Row><Col><h2 className="head headdtoday">{ourtodayheadlines.tag_title}</h2></Col><Col><Link to={`/AllNewsPage/${channel_seo_name}/${ourtodayheadlines.menu_cat_name}/${ourtodayheadlines.seo_tag_title}`} className='moed'>More +</Link></Col></Row>


    <div className='feature-main'>
    <div className='feat'>
    <div className='fea-contact iconrood'>
      <Slider {...settings}>
        
                    {ourtodayheadlines.video_list &&
                                ourtodayheadlines.video_list.map(function (sublist, subindex) {
                                 

                                 return (
      <div >
        <div className='carousel-card extraicon'>
        <div className='colid-in'>
        <Row className='colodbackfunf'>

<Col lg={6} className='pad-0d'>
<Link to={`/NewsView/${channel_seo_name}/${ourtodayheadlines.menu_cat_name}/${ourtodayheadlines.seo_tag_title}/${sublist.sv_title_seo}`}>
<div className='newsf'>
<img src={sublist.medium_img}/>
<PlayIcon/>
</div>

</Link>
</Col>

<Col lg={6}>
<Link to={`/NewsView/${channel_seo_name}/${ourtodayheadlines.menu_cat_name}/${ourtodayheadlines.seo_tag_title}/${sublist.sv_title_seo}`} className='fortextc'>
<h4>{sublist.sv_title}</h4>
</Link>
</Col>
</Row>
</div>

        
        </div>
      </div>
   )
                                  
                    })
                 }

   
    </Slider>
    </div>
    </div>
    </div>

    
    </div>
    </div>

  )
}

export default TodayShedules