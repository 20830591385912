import React, { useState } from "react";

import ChanelCategories from "./Components/ChanelCategories";
import Latestnews from "./Components/Latestnews";
import Logo from "./Components/LogoBar";
import MainMenu from "./Components/MainMenu";
import { useParams } from "react-router-dom";


const Heade = () => {
  const [chnlId, setChnId] = useState(1);
  const params = useParams();
  var channel_seo_name = params.channelseo;
  if(channel_seo_name == undefined){
    channel_seo_name = "etv-telugu";
  }
 //  alert(JSON.stringify(channel_seo_name));

  return (
    <div>
      <Latestnews chnlId={chnlId} channel_seo_name={channel_seo_name}  />
      <Logo chnlId={chnlId} channel_seo_name={channel_seo_name}  />
      {/* <ChanelCategories setChnId={setChnId} /> */}

      <ChanelCategories setChnId={setChnId}    />

      <MainMenu chnlId={chnlId} channel_seo_name={channel_seo_name} />


    </div>
  )
}

export default Heade