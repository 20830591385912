import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaEnvelope, FaLandmark, FaMapMarkerAlt, FaPhone, FaUserTie } from 'react-icons/fa'



const TermsConditions = () => {
  return (
    <div>
      
        
        <div className='SubpageTitleBar'>
        <Container>
          <Row className='shoed'>
          <Col lg={6} md={6}>
          <h4 className='allshiw'>Terms & Conditions</h4>
            </Col>
          </Row>
        </Container>
        </div>

        <div className='cms-content'>
          <Container>
            <div className='page-card'>
              <Row>
              <Col>
              <p>This is a legal agreement ("Agreement") between the visitor and Eenadu Television Private Limited, a Company incorporated in A P, India, under Companies Act 1956, having its registered office at Eenadu Television Private Limited, Ramoji Film City, Hyderabad, Telangana, India, hereinafter referred to as ETPL, the owner, /operator of the portal, hereinafter referred jointly and severally as portal.</p>
              <ul>
                <li>
                Access to the site cannot be had unless and until these terms and conditions are read and consented to.


                </li>
                <li>
                Ownership: The portal is owned and operated by Eenadu Television Private Limited a Company in Ramoji Group. The Company hereby reserves the right to change or discontinue any service, feature, offer, gift, contest, design, layout, content, platform, equipment, systems, program, software.


                </li>
                <li>   ERRORS AND DISCREPANCIES: While ETPL takes every care and caution to make the website free of errors and discrepancies, it is possible for some mistakes to creep into the website by inadvertence, oversight, human error, innocence and honest belief in the information in good faith. ETPL is not liable in respect of innocent dissemination of such information.</li>

                <li>While every endeavor is made to make the portal free of any bugs, errors, viruses, failures, delay in transmission etc. ETPL does not guarantee that the site or any of the services offered would be free of bugs, errors, viruses, failures and ETPL does not accept any responsibility or liability for any such bugs, errors, viruses, failures delay in transmission etc in the site.</li>
                <li> ETPL reserves the right to make such additions, alterations, modifications, insertions, improvements, decorations for the betterment and quality enhancement of the material displayed on the site. The users shall also agree for such modifications to the material for compatibility with the technological advancement and shall, without demur, agree for any changes that may be required to be made at the discretion of ETPL and also provide such information or material that may be required for updation or up gradation of the site in view of any technological advancement.</li>
                <li> ETPL does not give any warranty whatsoever either express or implied, material displayed on the site as to the quality, quantity, utility, originality, genuineness, legality, punctuality of any goods or services offered by ETPL or its agents, business affiliates, associates, agencies, franchisees etc. ETPL also does not give any warranty whatsoever either express or implied, of any disruption, interruption, breakdown of the site or services offered by ETPL or its agents, business affiliates, associates, agencies, franchisees etc.</li>
                <li>ETPL is not liable for any damages to any users or visitors or user or third parties for any injury or damage or hardship that they might have been sustained by them on account of any error, failure, discrepancy, defect, shortfall, mis-description; any bugs, errors, viruses, failures, delay in transmission etc., in the site; any defamatory, offensive, excessive, exaggerated, false, malafide, inaccurate, erroneous, blasphemous, obscene, profane, pirated, offensive, salacious, ghastly, indecent, racial or illegal material on the site. The visitors/users of the site specifically agree to this and discharge ETPL from any liability whatsoever for the said acts.</li>
                <li>ETPL or its staff or Directors shall not be liable for any of the claims, losses, damages, costs, expenses that any one has sustained in any manner whatsoever or likely to sustain in any manner whatsoever by the publication/display of any advertisement</li>
                <li> ETPL, their staff and directors shall be indemnified by the user for the costs and consequences, including but not limited to reasonable advocates fees and all expenses at actuals for defending themselves in any litigation that may arise out of website.</li>
                <li>The advertisements will be billed at the rate prevailing on the date of publication.</li>
                <li> Placing of an order for the insertion of an advertisement shall amount to an acceptance of the above conditions and no separate acceptance of these conditions is necessary.</li>
                <li>Artwork made by us is our property. Use of our artwork in other publications or any other medium is strictly prohibited.

</li>
 <li></li>     
 
         </ul>
         <h4>GRIEVANCE REDRESSAL</h4>
         <p>Any complaint relating to content on any of the above ETV Channels must be made within a reasonable time not exceeding 7 (seven) days from the date of the posting of the content on the portal to the following person appointed by the Company as the Grievance Officer under Rule 11(2)(a) of Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021.</p>

         <div className='address-block'>
                 <h4>Eenadu Television Private Limited</h4>
                    
                    <p><FaLandmark/>Smt P Madhavi</p>
                    <p><FaUserTie/>Grievance Officer</p>
                    <p><FaPhone/>08415-246555   Extn:6754</p>
                    <p><FaEnvelope/>grievance@etv.co.in

</p>
                    <div className='d-flex'>
                      <FaMapMarkerAlt className='me-3'/>
                      <p>II Floor, ETV Building
Ramoji Film City, Abdullapurmet Mandal,
Ranga Reddy District 501 512 , Telangana</p>
                    </div>
                    
                </div>

                <p>Only Courts at Hyderabad shall have jurisdiction in case of any dispute or any legal proceedings.</p>
              </Col>
              </Row>
            </div>
          </Container>
        </div> 




    </div>
  )
}

export default TermsConditions