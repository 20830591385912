import React , { useState, useEffect } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import "./AllShowView.css"
import { Link } from 'react-router-dom'
import { FaUndo, } from 'react-icons/fa';
import NewPlayIcon from '../../NewPlayIcon';
import PlayIcon from '../../PlayIcon';
import { getchannelbyseoname,getnewsHeadingsseomore } from "../../../globalURL";
import axios from 'axios';
const AllNewsView = (ourallnews) => {

  const ourallnewsdata = ourallnews.ourallnews;
  const channel_seo_name = ourallnews.channel_seo_name;
  const video_list = ourallnewsdata[0].video_list;
  const seo_tag_titles = ourallnewsdata[0].seo_tag_title;
  const tag_channel_id = ourallnewsdata[0].tag_channel_id;
  const [ourhometags, setOurHeadings] = useState([]);
  var totalPages = 0;
  var currentPage = 1;
  useEffect(() => {
    getTagsInfo(tag_channel_id,seo_tag_titles,currentPage);
 // getTagsInfo();
}, [tag_channel_id,seo_tag_titles,currentPage])
const handleScroll = () => {
  const distanceToBottom = document.documentElement.offsetHeight - (window.innerHeight + window.scrollY);
  const loadThreshold = 100;

  if (distanceToBottom < loadThreshold) {
   
     // alert(JSON.stringify(currentPage));
    if (currentPage <= totalPages) {
      const nextPage = currentPage + 1;
  
      // Increment currentPage after calling loadAllHeadings
      //setCurrentPage(nextPage);
      currentPage = nextPage;

      // Call loadAllHeadings with the new page
      getTagsInfo(tag_channel_id,seo_tag_titles,currentPage);

    }
  }
};

  const getTagsInfo = async (channel_id,seo_tag_titles,currentPage) => {
    const responseBody = { channel_id:channel_id,seo_tag_title: seo_tag_titles,page:currentPage };
    //console.log(responseBody);
    await axios
      .post(getnewsHeadingsseomore, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          if(resp_datalist.length > 0){
            var video_list_cnt = resp_datalist[0].video_list_cnt;
            if(video_list_cnt > 0){
                // Append the new data to the existing array
                const newTotalPages = resp_datalist[0].totalPages;
                setOurHeadings(prevHeadings => [...prevHeadings, ...resp_datalist[0].video_list]);
                // Update the total pages
                totalPages = newTotalPages;
                window.addEventListener('scroll', handleScroll);
                // Remove the event listener when the component unmounts
                return () => {
                   window.removeEventListener('scroll', handleScroll);
                };
            }
           
          }
         
        }
  
      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  return (

    <div className='shiwd'>


      <Row>
        {ourhometags &&
          ourhometags.map(function (subdata, subindex) {
            return (
              <Col lg={2} md={4} sm={6} xs={6}>
                <div className='topnew'>
                  <Link to={`/NewsView/${channel_seo_name}/${ourallnewsdata[0].menu_cat_name}/${seo_tag_titles}/${subdata.sv_title_seo}`}>
                    <div className='newsf'>
                      <img src={subdata.medium_img} />
                      <PlayIcon />
                    </div>
                    <div className='contant-limit'>
                    <h4>{subdata.sv_title}</h4>
                    </div>
                  </Link>

                </div>
              </Col>
            )
          })}







      </Row>
      {/* <div className='text-center paddin-25'><Button > Load More  <FaUndo /> </Button></div> */}
    </div>
  )
}

export default AllNewsView