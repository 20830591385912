import React from 'react'
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaChevronLeft,FaChevronRight } from 'react-icons/fa'
import "./AddBannerMiddle.css"
const AddBannerMiddle = (props) => {
  const middilebannerdata = props.middilebanner;
  const ourheadinginfo = props.ourheadinginfo;

  //alert(JSON.stringify(middilebannerdata));
  const SampleNextArrow = (props)  => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}

      ><div className='arrow-left'>
        
       <FaChevronRight className='arrow-right-show'/></div> </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><div className='arrow-right'>
 < FaChevronLeft className='arrow-right-show'/>
      </div>
       </div>
    );
  }

      var setting = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll:1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    
    
      };
      if(ourheadinginfo.length > 0){
  return (
    <div className='sum shope  d-lg-block d-xl-block'>
     <h6><b>{ourheadinginfo && ourheadinginfo[0].tag_title}</b></h6>
    <Slider {...setting}>
        {middilebannerdata && middilebannerdata.map(function (ads, videoindex) {
     //console.log(ads);
        let tosuburl = "/"+ads.channel_seo_url + "/showview/"+ads.show_name_seo_url;
      return(
      <div>
        <div className='addmiddle' key={videoindex}>
        <div className='addmiddle-in'>
        <Link to={tosuburl} className='mb-3 d-block'>
            
        <img src={ads.show_image2}  alt="" width="239"/> 
        </Link>
        {/* <p>{ads.show_name}</p> */}

        </div>
        </div>
      </div>
        )
      })}
      <div>

   
      </div>
      
      
   
    </Slider>
    
    </div>
  )
    }
}

export default AddBannerMiddle