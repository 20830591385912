import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory,useParams } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import { getslider_url,getchannelbyseoname } from "../../globalURL";

import axios from 'axios';
import PlayIcon from '../PlayIcon';

const Slider = (obj) => {
  const params = useParams();
  var channel_seo_name = params.channelseo;  
  if(channel_seo_name == undefined){
    channel_seo_name = "etv-telugu";
  }
  const [ourchannelinfo, ourchannelInfoList] = useState([]);
    useEffect(() => {
      getChannelFetchInfo(channel_seo_name);
    }, [channel_seo_name])
    // alert(chnl_id);

  // raji implemneted 


    const getChannelFetchInfo = async (channel_seo_name) => {
    
      const responseBody = { chnl_seo_name: channel_seo_name };
      await axios
        .post(getchannelbyseoname, JSON.stringify(responseBody), {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
        
          var resp_data = res.data;  
          if (resp_data.status === 'true' && resp_data != null) {
           var resp_datalist = resp_data.data;
        
            ourchannelInfoList(resp_datalist);
            fetchSliders(resp_datalist[0].chnl_id);   
               
          }
  
        })
        .catch((error) => {
          //setIsLoading(false);
          console.log(error);
        });
    }

  const [ourSliders, ourSlidersList] = useState([]);
  const fetchSliders = async (chnl_id) => {
    //   setIsLoading(true);
    const responseBody = { chnl_id: chnl_id, limit: 10 };
    await axios
      .post(getslider_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourSlidersList(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };
  return (
    <>
      <div className='main-slider'>
        <Carousel>

          {ourSliders.map((item, index) => {
            
            let tourl = "";
             if(item.type == "video"){
              tourl = "/video/"+item.channel_seo_url +"/"+ item.show_name_seo_url+"/"+ item.sv_title_seo;
              return (
         
                <Carousel.Item key={index}>
                  <div className='postiv'>
                  <Link to={tourl}>
                  <img className="d-block w-100" src={item.imagevideo} alt="slider 01" />
                  <PlayIcon/>
                  </Link>
                  </div>
                </Carousel.Item>
              )

               }else if(item.type == "show"){
               tourl = "/"+item.channel_seo_url+"/showview/"+item.show_name_seo_url;
               return (
         
                <Carousel.Item key={index}>
                  <div className='postiv'>
                  <Link to={tourl}>
                  <img className="d-block w-100" src={item.slider_path} alt="slider 01" />
      
                  </Link>
                  </div>
                </Carousel.Item>
              )

             }
           
          })}
        

        </Carousel>
      </div>
    </>
  )
}

export default Slider