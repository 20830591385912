import React from 'react'
import { Link } from 'react-router-dom'
function Events({toggleMenu}) {
  return (
    <div className='showed'>
      <Link to="/Showview" onClick={toggleMenu}>
        <img src='../assets/images/show-01.jpg' alt='' />
        <span>2020 Anukunnadi Okati Ayyinadi Okati</span>
      </Link>
      <Link to="/Showview" onClick={toggleMenu}>
        <img src='../assets/images/show-02.jpg' alt='' />
        <span>Aadavari Partilaku Arthale Verule</span>
      </Link>
      <Link to="/Showview" onClick={toggleMenu}>
        <img src='../assets/images/show-03.jpg' alt='' />
        <span>Aha Naa Pellanta</span>
      </Link>
      <Link to="/Showview" onClick={toggleMenu}>
        <img src='../assets/images/show-04.jpg' alt='' />
        <span>Akka Evare Athagadu</span>
      </Link>
      <Link to="/Showview" onClick={toggleMenu}>
        <img src='../assets/images/show-05.jpg' alt='' />
        <span>Amma Nanna O Sankranthi</span>
      </Link>
    
    </div>
  )
}

export default Events