import React from 'react'

export const NewsPopVideo = (tagvideodata) => {
  const outtagvideodata = tagvideodata.outtagvideodata;
  return (
    <div>
    <div className="newpic">

    <iframe
  width="100%"
  height="315"
  src={`https://www.youtube.com/embed/${outtagvideodata.tn_file}`}
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
></iframe>

       </div>
       <p> {outtagvideodata.tn_desciption}
       </p>
 
</div>
  )
}
