// import logo from './logo.svg';
import './App.css';
import { Route, Routes,Navigate } from 'react-router-dom';
import { render } from 'react-dom';
import Home from './Home';

import Heade from './Components/Header/Heade';
import Footer from './Components/Footer/Footer';
import ShowView from './ShowView';
import WeekSchedulePage from './WeekSchedulePage';
import WeekScheduleViewVideo from './WeekScheduleViewVideo';
import AllShowPage from './AllShowPage';
import NewsHome from './NewsHome';
import AboutUs from './Cms/AboutUs';
import Careers from './Cms/Careers';
import Contact from './Cms/Contact';
import CsrPolicy from './Cms/CsrPolicy';
import Disclaimer from './Cms/Disclaimer';
import Feedback from './Cms/Feedback';
import PrivacyPolicy from './Cms/PrivacyPolicy';
import Subscriptions from './Cms/Subscriptions';
import TechnicalInfo from './Cms/TechnicalInfo';
import TermsConditions from './Cms/TermsConditions';
import AllNewsPage from './Components/News/AllNewsPage';
import { NewsView } from './Components/News/NewsView';
import Episodes from './Components/Episodes/Episodes';
import ViewMoreShow from './Components/Header/Components/ViewMoreShow';
import LatestNewsViewMore from './Components/Header/Components/LatestNewsViewMore';
import Complaint from './Cms/Complaint';


const App = () => {

  return (
    <div className='hea'      style={{minHeight:"100vh",display:"flex",flexDirection:"column",justifyContent:"space-between"}}>

<Routes>
  <Route
    path="/*"
    element={
      <>
     
      <Routes>
        
        <Route path="/" element={<>
              <Heade /> <Home />   <Footer /> </> }    />
        <Route path="/:channelseo" element={<>
              <Heade /> <Home /> <Footer /> </>} />
        <Route path="/:channelseo/news" element={<>
              <Heade /> <NewsHome /> <Footer /> </>} />
        {/* <Route path="/home/:name/:name" element={<>
              <Heade /><Home /> <Footer /> </> } /> */}
        <Route path="/schedule/:channelseo/:menuname" element={<>
              <Heade /><WeekSchedulePage />  <Footer /> </> } />
        {/* <Route path="/video/:name/:id" element={<WeekScheduleViewVideo />} /> */}
        <Route path="/video/:channelseo/:showtitle/:videtitle" element={<>
              <Heade /> <WeekScheduleViewVideo /> <Footer /> </>} />

        {/* <Route path="/shows/:name/:name/:id" element={<ShowView />} /> */}
        {/* <Route path="/:channelseo/:menuname/:showseo" element={<ShowView />} /> */}
        <Route path="/:channelseo/showview/:showseo" element={<>
              <Heade /> <ShowView /> <Footer /> </>} />
        <Route path="/shows/:id" element={<>
              <Heade /><ShowView /> <Footer /> </>} />
        <Route path="/heading/:channelseo/:seo_tag_title" element={<>
              <Heade /> <Episodes /> <Footer /> </>} />
        <Route path="/heading/:channelseo/:menuname/:seo_tag_title" element={<>
              <Heade /> <Episodes /> <Footer /> </>} />
        {/* <Route path="/heading/:name/:id" element={<Episodes />} /> */}
        {/* <Route path="/AllNewsPage/:channel_seo/:name" element={<AllNewsPage />} /> */}
        <Route path="/AllNewsPage/:channelseo/:menuname/:name" element={<>
              <Heade /> <AllNewsPage />  <Footer /> </> } />
        {/* <Route path="/NewsView/:channel_seo/:name/:videotitle" element={<NewsView />} /> */}
        <Route path="/NewsView/:channelseo/:menuname/:name/:videotitle" element={<>
              <Heade /> <NewsView />  <Footer /> </>} />

        <Route path="/WeekSchedulePage" element={<>
              <Heade /> <WeekSchedulePage />  <Footer /> </>} />
        <Route path="/WeekScheduleViewVideo" element={<>
              <Heade /> <WeekScheduleViewVideo />  <Footer /> </>} />
        <Route path="/AllShowPage" element={<>
              <Heade /> <AllShowPage /> <Footer /> </>} />
        <Route path="/NewsHome" element={<>
              <Heade /> <NewsHome /> <Footer /> </>} />
        <Route path="/:channelseo/footer/:pagename" element={<>
              <Heade /> <AboutUs /> <Footer /> </>} />    
        <Route path="/:channelseo/footer/contact/:pagename" element={<>
              <Heade /> <Contact /> <Footer /> </>} />   
        <Route path="/:channelseo/footer/technical_info/:pagename" element={<>
              <Heade /> <TechnicalInfo /> <Footer /> </>} /> 
        <Route path="/:channelseo/footer/feedback/:pagename" element={<>
              <Heade /> <Feedback /> <Footer /> </>} /> 

      <Route path="/:channelseo/footer/career/:pagename" element={<>
      <Heade /> <Careers /> <Footer /> </>} /> 

        {/* <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Careers" element={<Careers />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/CsrPolicy" element={<CsrPolicy />} />
        <Route path="/Disclaimer" element={<Disclaimer />} />
        <Route path="/Feedback" element={<Feedback />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Subscriptions" element={<Subscriptions />} />
        <Route path="/TechnicalInfo" element={<TechnicalInfo />} />
        <Route path="/TermsConditions" element={<TermsConditions />} /> */}
    
        <Route path="/NewsView" element={<>
              <Heade /> <NewsView /> <Footer /> </>} />
        <Route path="/Episodes" element={<>
              <Heade /> <Episodes />  <Footer /> </>} />
        <Route path="*" element={<Navigate to="/etv-telugu" />} />
    
        <Route  path="/:channelseo/:menuname/viewmoreshow"    element={<> <Heade />  <ViewMoreShow/> <Footer /> </>} />
         <Route  path="/:channelseo/latestnews-viewmore"  element={<> <Heade />   <LatestNewsViewMore/><Footer /> </>} />
         <Route  path="/Careers"  element={<> <Heade />  <Careers/><Footer /> </>} />
         <Route  path="/Complaint"  element={<> <Heade />    <Complaint/><Footer /> </>} />
         
         
      
        </Routes>
       

      
        </>
    }
  />
</Routes>






    </div>
  );
}

export default App;
