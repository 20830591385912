import React, { useState, useEffect,useRef } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./MainMenu.scss";
import Search from "./Search";
import { Link,useParams } from "react-router-dom";
import Events from "./Events";
import Archive from "./Archive";
import Serials from "./Serials";
import Show from "./Show";
import axios from 'axios';
import "../../../App.css"

import { get_main_menu_url,getloadheading_url,getchannelbyseoname } from "../../../globalURL";

function MainMenu({ chnlId }) {
  const channel_id = 0;
  const params = useParams();
  const channel_seo_name = params.channelseo;
//alert(JSON.stringify(channel_seo_name));
  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [showFour, setShowFour] = useState(false);
  const [ourCategories, ourCategoriesList] = useState([])
  const linkRefs = useRef([]);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  const showDropdow = (e) => {
    setShowTwo(!show);
  };
  const hideDropdow = (e) => {
    setShowTwo(false);
  };

  const showDropdows = (e) => {
    setShowThree(!show);
  };
  const hideDropdows = (e) => {
    setShowThree(false);
  };
  const showDropdowss = (e) => {
    setShowFour(!show);
  };
  const hideDropdowss = (e) => {
    setShowFour(false);
  };
  useEffect(() => {
    getChannelInfo(channel_seo_name,chnlId);
    //fetchCategoryData(chnlId);
  }, [channel_seo_name,chnlId])

  const getChannelInfo = async (channel_seo_name,chnlId) => {
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          var channel_id = resp_datalist[0].chnl_id;
          localStorage.setItem("session_chnl_id", channel_id);
           fetchCategoryData(channel_id);
        }else{
          fetchCategoryData(chnlId);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }

  const menuChangeByClick = (chnl_id,menu_cat_id) => {
    localStorage.setItem("session_chnl_id", chnl_id);
    // window.location.reload(false);
    //loadHeadings(chnl_id,menu_cat_id);
  }


function handleClick(index) {
    // alert(index);
    if (index === 0) {
        linkRefs.current[0]?.click();
    }
}

  const fetchCategoryData = async (chnlId) => {
    const responseBody = { chnl_id: chnlId };

    await axios
      .post(get_main_menu_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourCategoriesList(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  
  return <>

     
    <Navbar
      expand="lg"
      className="main-menu-bg d-none d-lg-block d-xl-block"
    >
      <Container fluid className="pad-left-80 pad-right-80">

        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0 cololink" navbarScroll>
            <input type="hidden" id="firstmenu_id" name="firstmenu_id"  />
            {ourCategories.map((item, index) => {
              if(index == 0){
                document.getElementById("firstmenu_id").value = item.menu_cat_id;
              }
             
              if (item.showslistcount > 0 ) {
                let urlshow = '/'+channel_seo_name+'/'+item.menu_cat_name+'/viewmoreshow';

                return (
                  <NavDropdown key={index}   title={item.menu_cat_name}  id=""
                    onMouseEnter={showDropdow}
                    onMouseLeave={hideDropdow} >
                    {item.showslist && item.showslist.map((subitem, subindex) => {
                      let tosuburl = '';
                      if (item.menu_cat_name == "Home" && item.chnl_type_template == "NonNews") {
                        tosuburl = "/" + item.channel_seo_url;
                      }else if (item.menu_cat_name == "Home" && item.chnl_type_template == "News") {
                        tosuburl = "/" + item.channel_seo_url + "/news";
                      } else if (item.menu_cat_name === "Schedule") {
                        tosuburl = "/schedule/" + item.channel_seo_url + "/" + item.menu_cat_name;
                      } else {
                        tosuburl = "/"+item.channel_seo_url + "/showview/"+subitem.show_name_seo_url;
                      }
                      if(subindex <= 5){
                          return (
                            <div className='showed' key={subindex}>
                              <Link  to={tosuburl}  state={{id:item.chnl_id ,menu_cat_id:item.menu_cat_id }}  onClick={() => {
                                                                    menuChangeByClick(item.chnl_id);  handleClick(index);
                                                                }}   >
                                <img src={subitem.show_image} alt={subitem.show_name} />
                                <span>{subitem.show_name}</span>
                              </Link>
                          
                            </div>
                            
                          );
                      }
                    
                      
                    })            
                    
                    }
                     
                
                    <div className="vie-style-colr">
                         <Link to={urlshow} >View More +</Link>
                    </div>
             
                    
                  </NavDropdown>
                );
                
              } else {
                      let tourl = '';
                    //  alert(JSON.stringify(item));
                      if (item.menu_cat_name == "Home" && item.chnl_type_template == "NonNews") {
                        tourl = "/" + item.channel_seo_url;
                      }else if (item.menu_cat_name == "Home" && item.chnl_type_template == "News" ) {
                        tourl = "/" + item.channel_seo_url + "/news";
                      } else if (item.menu_cat_name == "Schedule") {
                        tourl = "/schedule/" + item.channel_seo_url+ "/" + item.menu_cat_name;
                      // } else if (item.menu_cat_name == "Shows") {
                      //   tourl = "/shows/" + item.channel_seo_url+ "/" + item.menu_cat_name;
                      }else if(item.showslistcount == 0 && (item.menu_cat_name !== "Home" || item.menu_cat_name != "Schedule" || item.menu_cat_name != "Shows")){
                        tourl = "#";
                      }
                    

                  return (
                  <Link to={tourl}  state={{id:item.chnl_id, menu_cat_id:item.menu_cat_id }} onClick={() => {
                  menuChangeByClick(item.chnl_id); handleClick(index);
                  }}  key={index} >{item.menu_cat_name}</Link> 


                  );
              }
             
            })}
            




            {/* 



            <NavDropdown
              title="shows"
              id=""
              show={showTwo}
              onMouseEnter={showDropdow}
              onMouseLeave={hideDropdow}
            >
              <Show />
            </NavDropdown>
            <Link to="/WeekSchedulePage">schedule</Link>

            <NavDropdown
              title="archives"

              show={showThree}
              onMouseEnter={showDropdows}
              onMouseLeave={hideDropdows}
            >
              <Archive />
            </NavDropdown>
            <NavDropdown
              title="events"

              show={showFour}
              onMouseEnter={showDropdowss}
              onMouseLeave={hideDropdowss}
            >
              <Events />
            </NavDropdown> */}
          </Nav>
          <Search />
        </Navbar.Collapse>
      </Container>
    </Navbar>

  </>






}

export default MainMenu;
