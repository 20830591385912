import React, { useState, useEffect,useRef } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link,useParams } from 'react-router-dom';
import { FaUndo } from 'react-icons/fa';
import axios from 'axios';
import "./Show.css"
import { getchannelbyseoname,getshowall_url,getmenudataseourl } from "../../../globalURL";

const ViewMoreShow = () => {
  const params = useParams();
  const channel_seo_name = params.channelseo;
  const menuname = params.menuname;
  useEffect(() => {
    getChannelFetchInfo(channel_seo_name,menuname);
  }, [channel_seo_name,menuname])
  const [ourchannelinfo, ourchannelInfoList] = useState([]);
  const [storeMenu, setStoreMenu] = useState([]);
  
  const [ourshows, setourshows] = useState([]);
  const getChannelFetchInfo = async (channel_seo_name,menuname) => {
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {

        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourchannelInfoList(resp_datalist);
          getMenuInfo(menuname,resp_datalist[0].chnl_id);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const getMenuInfo = async (menuname, chnl_id) => {
    const responseBody = { menuname: menuname };
    await axios
      .post(getmenudataseourl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setStoreMenu(resp_datalist);
          fetchShows(resp_datalist[0].menu_cat_id, chnl_id);
     
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const fetchShows = async (menu_cat_id,chnlId) => {
    const responseBody = { chnl_id: chnlId,menu_cat_id: menu_cat_id };

    await axios
      .post(getshowall_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setourshows(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  // alert(JSON.stringify(ourshows));
  return (
    <>
    <div className='subtile'><Container><h4 className='allshiw'> All {menuname} </h4></Container></div>
    <div className='alledid'>
    <Container fluid >  
      
    {/* <Row style={{margin:"0 auto"}}>
      <Col lg={12}> */}

        <Row className="row-cols-auto" style={{margin:"0px"}}>
        {ourshows && ourshows.map((subitem, subindex) => {
           let tosuburl = "/"+channel_seo_name +"/showview/"+ subitem.show_name_seo_url;

            return (
      <Col  className='pe-0'>
    <div className='carousel-card img-size epidos-add back-style' style={{borderRadius:"0px",padding:"0px",}} >
        <Link to={tosuburl} >
          <div className='extra exa-mar'>
          <img src={subitem.show_image2} alt='' className='img-marign-clas' />
          
          </div>
         
          <h4 className='oneline-style'>{subitem.show_name}</h4>
        
        </Link>
    
    </div>
  </Col>
            )
   })     }
 

  </Row>


      {/* </Col>
  
    </Row> */}
    {/* <div className='text-center paddin-25'><Button > Load More  <FaUndo /> </Button></div> */}

    </Container>
    </div>

    </>
  )
}

export default ViewMoreShow