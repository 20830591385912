import React from 'react'
import { NavDropdown } from 'react-bootstrap'


const LiveChanels = ({ toggleMenu, mylivechannels }) => {

  return (
    <>
      {mylivechannels &&
        mylivechannels.map(function (list, index) {
          // let url = "https://www.youtube.com/watch?v="+list.youtube_link;
          let url = list.website_url;
          return (
            <NavDropdown.Item href={url}
              target='_blank' onClick={toggleMenu}>
              <img src={list.chnl_logo} alt='etv telugu' />
              <div className='live-tag'>live</div>


            </NavDropdown.Item>

          )
        })

      }

    </>
  )
}

export default LiveChanels