import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { savecomplaint_form, getchannels_list_url } from '../globalURL';

const Complaint = () => {
  const [formData, setFormData] = useState({
    title: 'Mr',
    txtname: '',
    txtsurname: '',
    txtaddress: '',
    txtmobile: '',
    txtphno: '',
    txtemail: '',
    selectedchannel: '',
    txtprogram: '',
    txtProgramme: '',
    timeofboard: '',
  });
  const handleReset = () => {
    // Reset the form data to empty values
    setFormData({
      title: '',
      txtname: '',
      txtsurname: '',
      txtaddress: '',
      txtmobile: '',
      txtphno: '',
      txtemail: '',
      selectedchannel: '',
      txtprogram: '',
      txtProgramme: '',
      timeofboard: '',
      txtourComplaint: '',
      // Reset other form fields as needed
    });
  };
  const handleDropdownChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const [ourChannels, setOurChannels] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchChannelsData();
  }, []);

  const fetchChannelsData = async () => {
    try {
      const response = await axios.post(getchannels_list_url, {});
      const responseData = response.data;

      if (responseData.status === 'true' && responseData.data) {
        setOurChannels(responseData.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' }); // Clear the error when the input changes
  };

  const validateForm = () => {
    const newErrors = {};

    if (formData.title.trim() === '') {
      newErrors.title = 'Please select Mr/Mrs/Ms';
    }

    if (formData.txtname.trim() === '') {
      newErrors.txtname = 'Please enter your First Name';
    }

    if (formData.txtsurname.trim() === '') {
      newErrors.txtsurname = 'Please enter your Surname';
    }

    if (formData.txtmobile.trim() === '' || !/^[0-9]{10}$/.test(formData.txtmobile)) {
      newErrors.txtmobile = 'Please enter a valid 10-digit Mobile No';
    }

    if (formData.txtemail.trim() === '' || !/\S+@\S+\.\S+/.test(formData.txtemail)) {
      newErrors.txtemail = 'Please enter a valid Email Id';
    }
    
    if (formData.txtaddress.trim() === '') {
      newErrors.txtaddress = 'Please enter address';
    }
 
    if (formData.selectedchannel.trim() === '') {
      newErrors.selectedchannel = 'Please select a channel';
    }

    if (formData.txtprogram.trim() === '') {
      newErrors.txtprogram = 'Please enter a program title';
    }

    if (formData.txtProgramme.trim() === '') {
      newErrors.txtProgramme = 'Please enter a program date';
    }

    if (formData.timeofboard.trim() === '') {
      newErrors.timeofboard = 'Please enter a broadcast time';
    }
    //alert(formData.txtourComplaint);
    if (formData.txtourComplaint === undefined || formData.txtourComplaint.trim() === '') {
      newErrors.txtourComplaint = 'Please enter your complaint';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Form is valid if the error object is empty
  };

  const savefeedback = () => {
  //  alert(validateForm());
    if (validateForm()) {
     // alert("After validations");

        axios
        .post(savecomplaint_form, JSON.stringify(formData), {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          console.log(res.data);
          var resp_data = res.data;
          if (resp_data.status === 'true' && resp_data != null) {
              console.log("form submitted successfully..");
              // Redirect to the same path after form submission
              alert('Successfully saved!');
             window.location.href = window.location.pathname;
                         // Display a success message (optional)
          
          }
  
        })
        .catch((error) => {
          //setIsLoading(false);
          console.log(error);
        });
      }
    
  };
  
  

  return (
    <div>
    <div className='SubpageTitleBar'>
    <Container fluid className='pad-left-80 pad-right-80'>
      <Row className='shoed'>
      <Col lg={6} md={6}>
      <h4 className='allshiw'>Complaint</h4>
        </Col>
      </Row>
    </Container> 
    </div>
    <div className='cms-content feedback-bg-clor'>
    <Container fluid className='pad-left-80 pad-right-80'>
       
          <Row className='justify-content-center'>
            <Col lg={7} md={9} sm={9} xs={10}>
            <div className='page-card form-inputr'>
              {/* <h1>{storeFooterContent[0].name}</h1> */}
              <Form>
              <div className='mb-3'>
  <Form.Label>Mr. / Mrs ./ Ms *</Form.Label>
  <Form.Select
  aria-label="Default select example"
  id="title"
  name="title"
  value={formData.title}
  onChange={handleChange}
  required
>
  <option value="" >Select</option>
  <option value="Mr">Mr</option>
  <option value="Mrs">Mrs</option>
  <option value="Ms">Ms</option>
</Form.Select>
  {errors.title && <span className='erroeclass'>*{errors.title}*</span>}
</div>
<div className='mb-3'>
  <Form.Label>First Name *</Form.Label>
  <Form.Control
    type="text"
    id="txtname"
    name="txtname"
    placeholder="First Name"
    value={formData.txtname}
    onChange={handleChange}
    required
  />
  </div>
  <div className='mb-3'>
  {errors.txtname && <span className='erroeclass'>*{errors.txtname}*</span>}
  <br></br>
  <Form.Label>Surname</Form.Label>
  <Form.Control
    type="text"
    id="txtsurname"
    name="txtsurname"
    placeholder="Surname"
    value={formData.txtsurname}
    onChange={handleChange}
  />
  {errors.txtsurname && <span className='erroeclass'>*{errors.txtsurname}*</span>}
</div>
<div className='mb-3'>
  <Form.Label>Complete address with pin code *</Form.Label>
  <Form.Control
    type="text"
    id="txtaddress"
    name="txtaddress"
    placeholder="Complete address with pin code"
    value={formData.txtaddress}
    onChange={handleChange}
  />
  {errors.txtaddress && <span className='erroeclass'>*{errors.txtaddress}*</span>}
</div>
<div className='mb-3'>
  <Form.Label>Mobile No</Form.Label>
  <Form.Control
    type="text"
    id="txtmobile"
    name="txtmobile"
    placeholder="Mobile No"
    value={formData.txtmobile}
    onChange={handleChange}
  />
{errors.txtmobile && <span className='erroeclass'>*{errors.txtmobile}*</span>}
</div>
<div className='mb-3'>

  <Form.Label>Phone No</Form.Label>
  <Form.Control
    type="text"
    id="txtphno"
    name="txtphno"
    placeholder="Phone No"
    value={formData.txtphno}
    onChange={handleChange}
  />
{errors.txtphno && <span className='erroeclass'>*{errors.txtphno}*</span>}
</div>
<div className='mb-3'>
  <Form.Label>Email Id *</Form.Label>
  <Form.Control
    type="email"
    id="txtemail"
    name="txtemail"
    placeholder="Enter email Id"
    value={formData.txtemail}
    onChange={handleChange}
    required
  />
  {errors.txtemail && <span className='erroeclass'>*{errors.txtemail}*</span>}
</div>
<div className='mb-3'>
         <div className='mb-3'>
    <Form.Label>Select Channel</Form.Label>
    <Form.Select
      aria-label="Default select example"
      id="selectedchannel"
      name="selectedchannel"
      size="lg"
      onChange={(event) => {
        handleDropdownChange(event);
        // Call your second function here
        // yourSecondFunction();
      }}
      value={formData.selectedchannel}
    >
      <option value="">Select</option>
      {ourChannels &&
                  ourChannels.map(function (list, index) {
                    return (
                        <option value={list.chnl_id} > {list.chnl_name}</option>
                    )})}
        
    </Form.Select>
  </div>
  {errors.selectedchannel && <span className='erroeclass'>*{errors.selectedchannel}*</span>}
</div>
<div className='mb-3'>
  <Form.Label>Programme Title / Broadcast Item*</Form.Label>
<Form.Control
  type="text"
  id="txtprogram"
  name="txtprogram"
  value={formData.txtprogram}
  onChange={handleChange}
  placeholder="Programme Title / Broadcast Item*"
/>
{errors.txtprogram && <span className='erroeclass'>*{errors.txtprogram}*</span>}
</div>
<div className='mb-3'>
<Form.Label>Programme Date: dd/mm/yyyy*</Form.Label>
<Form.Control
  type="date"
  id="txtProgramme"
  name="txtProgramme"
  value={formData.txtProgramme}
  onChange={handleChange}
  placeholder="Programme Date: dd/mm/yyyy*"
/>
{errors.txtProgramme && <span className='erroeclass'>*{errors.txtProgramme}*</span>}
</div>
<div className='mb-3'>
<Form.Label>Time of the broadcast (s)(HH:MM)*</Form.Label>
<Form.Control
  type="time"
  id="timeofboard"
  name="timeofboard"
  value={formData.timeofboard}
  onChange={handleChange}
  placeholder="Time of the broadcast (s)(HH:MM)*"
/>
{errors.timeofboard && <span className='erroeclass'>*{errors.timeofboard}*</span>}
</div>
<div className='mb-3'>
  <Form.Label>Complaints*</Form.Label>
  <Form.Control
    as="textarea"
    id="txtourComplaint"
    name="txtourComplaint"
    rows={3}
    placeholder='Enter Complaint here'
    value={formData.txtourComplaint}
    onChange={handleChange}
  />
  {errors.txtourComplaint && <span className='erroeclass'>*{errors.txtourComplaint}*</span>}
</div>

  <div className="pading-class">
    <Row>
    <Col lg={6} md={6} sm={6} xs={6}>
        <Button variant="primary" onClick={savefeedback} className='wid-100'>SUBMIT</Button>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6}>
        <Button variant="secondary" onClick={handleReset} className='wid-100'>RESET</Button>
        </Col>
      </Row>
    {/* <Button variant="primary" onClick={savefeedback}>
      SUBMIT
    </Button> */}
  </div>
</Form>

              
</div>
            </Col>
          </Row>
      
      </Container>
      </div>


    
</div>
  );
};

export default Complaint;
