import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import { FaDoorClosed, FaRegWindowClose, FaSearch, FaTimes, FaUndo, FaWindowClose, FaWindows } from 'react-icons/fa'
import React, { useState,useEffect } from 'react'
import PlayIcon from '../../PlayIcon';
import { Link } from 'react-router-dom';
import { searchvideourl,searchshowurl } from "../../../globalURL";
import axios from 'axios';

const Search = () => {
  
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(true);
  const [resultshow, setResultshow] = useState(true);
  const [onlydata, setOnlydata] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchShowResults, setSearchShowResults] = useState([]);
  
  const [loadmore, setLoadmore] = useState(false);
  const [ourShowData, setShowOurdata] = useState([]);
  const [ourData, setOurdata] = useState([]);

  // Function to handle search
  const handleSearch = (query) => {
    // Perform search logic here, e.g., make an API call or search within local data
    // For demonstration, let's assume we have a predefined list of data to search from
    
    const responseBody = { searchkeyword: query };

    axios
      .post(searchshowurl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setShowOurdata(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
        
      });
     axios
      .post(searchvideourl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setOurdata(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });

    

    // const data = [
    //   { id: 1, name: 'jabardhas',img:"../assets/images/show-01.jpg" },
    //   { id: 2, name: 'Racha',img:"../assets/images/show-02.jpg" },
    //   { id: 3, name: 'druva',img:"../assets/images/show-03.jpg" },
    //   { id: 4, name: 'cash',img:"../assets/images/show-04.jpg" },
    //   { id: 5, name: 'dhee',img:"../assets/images/show-05.jpg" },
    //   { id: 6, name: 'rrr',img:"../assets/images/show-06.jpg" },
    //   { id: 7, name: 'bhahubali',img:"../assets/images/show-06.jpg" },
    //   { id: 8, name: 'ega',img:"../assets/images/show-06.jpg" },
    //   { id: 9, name: 'jabardhas',img:"../assets/images/show-01.jpg" },
    //   { id: 10, name: 'Racha',img:"../assets/images/show-02.jpg" },
    //   { id: 11, name: 'druva',img:"../assets/images/show-03.jpg" },
    //   { id: 12, name: 'cash',img:"../assets/images/show-04.jpg" },
    //   { id: 13, name: 'dhee',img:"../assets/images/show-05.jpg" },
    //   { id: 14, name: 'rrr',img:"../assets/images/show-06.jpg" },
    //   { id: 15, name: 'bhahubali',img:"../assets/images/show-06.jpg" },
    //   { id: 16, name: 'ega',img:"../assets/images/show-06.jpg" },

    //   // Add more data objects here
    // ];
   //alert(JSON.stringify(filteredDataShow));
    // Filter the data based on the search query
    const filteredData = ourData.filter(item =>
      item.sv_title.toLowerCase().includes(query.toLowerCase())
    );
    const filteredDataShow = ourShowData.filter(item =>
      item.show_name.toLowerCase().includes(query.toLowerCase())
    );
    // Update the search results state
    setSearchResults(filteredData);
    setSearchShowResults(filteredDataShow);
  };

  // Function to handle modal show
  const handleShow = () => setShow(true);

  // Function to handle modal close
  const handleClose = () => setShow(false);
  const handleSearchQueryChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  
    if (query.length >= 2) {
      // Perform the search only if the query contains at least 3 characters
      handleSearch(query);
      setLoadmore(true);
      setContent(false);
      setResultshow(false);
      setOnlydata(true);
    } else {
      // Reset the search results if the query is less than 3 characters
      setSearchResults([]);
      setSearchShowResults([]);
      setLoadmore(false);
      setContent(true);
      setResultshow(true);
      setOnlydata(true);
    }
  };


  // // Function to handle search query change
  // const handleSearchQueryChange = (e) => {
  //   const query = e.target.value;
  //  // alert(query);
  //   setSearchQuery(query);
  //   handleSearch(query); // Update search results as the user types
  //   setLoadmore(true);
  //   setContent(false);
  //   setResultshow(false);
  //   if(e.target.value == ""){

  //     setOnlydata(false)
  //     setLoadmore(false);
  //     setContent(true);

  //   }
  //   else{
  //     setOnlydata(true)
  //     setLoadmore(true);
  //     setContent(false);
  //   }

  // };
  return (
    <>
    

    <Form className="search-bar" >
            <InputGroup  onClick={handleShow}>
                <Form.Control type="search" placeholder="Search Here" />
                <Button variant="" id="button-addon1">
                  <FaSearch />
                </Button>
            </InputGroup>
          </Form>
    
  <div className='popd'>


      <Modal show={show} onHide={handleClose} className='entry'> 
     
      

   
        <Modal.Header closeButton>
        <Row className='justify-content-between for-mard'>
        <Col lg={6} md={6} sm={6} xs={6}>
        <Modal.Title className='text-white serd'>Search</Modal.Title>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6} className='text-end'>
    
            <FaTimes onClick={handleClose}  className='baclod' />
         
        </Col>
      </Row>
        

        </Modal.Header>
     
        <Modal.Body>
          
          <Form>
            <Form.Group controlId="searchQuery">
              <Form.Control
                type="text"
                placeholder="Search Here"
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </Form.Group>
          </Form>
          <div className='ser'>
          {
    content ? 
            <p className='text-white text-center stye-font'>Search for Shows, Serials, Episodes, Movies, Recipes and Videos</p> : ''

        }
        </div>
          {onlydata ? 

          
        <div className='tetxt'>
        {resultshow ? '' :
 <h5 className='text-white'>{searchResults.length+searchShowResults.length} result for "{searchQuery}"</h5>
        }
       
        
          <Row>

          {searchShowResults.map(result => (

              <Col lg={2} md={3} sm={4} xs={6}> 
              <div className='carousel-card extraicon '>
              {/* /video/:channelseo/:seo_tag_title/:videtitle */}

              
            <Link to={"/"+result.channel_seo_url +"/showview/"+ result.show_name_seo_url} onClick={handleClose}>
            <div className='extra'>
              <img src={result.show_image}  alt='' />
  
              </div>
          
              
           
              <h4 key={result.show_id} className='text-white'> {result.show_name} </h4>
            </Link>
        
        </div>
        
    
              </Col>
              
          
        
            
          ))}

          {searchResults.map(result => (
              <Col lg={2} md={3} sm={4} xs={6}> 
              <div className='carousel-card extraicon '>
              {/* /video/:channelseo/:seo_tag_title/:videtitle */}
            <Link to={"/video/"+result.channel_seo_url+"/"+result.show_name_seo_url+"/"+result.sv_title_seo} onClick={handleClose}>
            <div className='extra'>

            {result.custom_youtube_type === 'Youtube' ? (
                                          <img src={result.medium_img} alt='' />
                                          ) : (
                                            <img src={result.image_path}  alt='' />

                                          )}
                <PlayIcon/>
              </div>
          
              
           
              <h4 key={result.sv_id} className='text-white'> {result.sv_title} </h4>
            </Link>
        
        </div>
        
    
              </Col>
              
          
        
            
          ))}
          </Row>
        {/* { loadmore
         ?  <div className='text-center paddin-25' ><Button > Load More  <FaUndo /> </Button></div> : ""
      } */}
      </div>
      : ""}


        </Modal.Body>
 
       
          
      
      </Modal>
     
      
         
      </div>
      </>
  )
}

export default Search

