import React,{useEffect,useState} from 'react'
import ShowVideoView from './Components/ShowView/Components/ShowVideoView'
import {useParams } from 'react-router-dom'
import TvSchedule from './Components/TvSchedules/TvSchedule'
import { getchannelbyseoname,getmenudataseourl,getshowseourl } from "./globalURL";
import axios from 'axios';

const ShowView = () => {
  const params = useParams();
  const channel_seo_name = params.channelseo;
  const menuname = params.menuname;
  const showseo = params.showseo;
  const [storechannel, setStorechannel] = useState([]);
  const [storeMenu, setStoreMenu] = useState([]);
  const [storeShow, setStoreShow] = useState([]);
  useEffect(() => {
    getChannelInfo(channel_seo_name);
    getMenuInfo(menuname);
    getShowInfo(showseo);

  }, [channel_seo_name,menuname,showseo])
  const getChannelInfo = async (channel_seo_name) => {
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setStorechannel(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const getMenuInfo = async (menuname) => {
    const responseBody = { menuname: menuname };
    await axios
      .post(getmenudataseourl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setStoreMenu(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const getShowInfo = async (showseo) => {
    const responseBody = { showseoname: showseo };
    await axios
      .post(getshowseourl, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setStoreShow(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  return (
    <div className='maind'>


 <ShowVideoView storechannel = {storechannel}  storeMenu = {storeMenu}  storeShow = {storeShow}  />
 
    </div>
  )
}

export default ShowView