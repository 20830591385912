import React from 'react'
import { useState } from 'react';



const NewPop = (tagdata) => {
  const DynamicContent = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };
const outtagdata = tagdata.outtagdata;
  return (
    <div>
        { outtagdata.imagefile != null && outtagdata.imagefile != "" && (
          <div className="newpic">
           
          <img src={outtagdata.tn_file}/>
          </div>
        )}
        
           <p>          <DynamicContent htmlContent={outtagdata.tn_desciption} />       
          </p>
    
  </div>
  )
}

export default NewPop