import React from 'react'
import ReactPlayer from 'react-player'
import "./Live.css"
// import videolive from "./Livevideo/livevidoe.mp4"
import PlayIcon from '../../PlayIcon';
import { Link } from "react-router-dom";

const Live = (props) => {

  const ourLiveData = props.ourLiveData;
 // alert(ourLiveData[0].image_url);
  if (ourLiveData.length > 0) {
    return (
      <div className='pad-30'>
        <h2 className='live'>LIVE TV</h2>
      <div className='livtv video-container'>
        
      <Link to={ourLiveData[0].website_url} target="_blank" >
                                    <img src={ourLiveData[0].image_url} />
                             
                                </Link>
        {/* <ReactPlayer  url={videolive} className="yout" width="100%" pip={true} controls={true} playing={true}/> */}
      {/* <iframe width="100%"  src={`https://www.youtube.com/embed/${ourLiveData[0].youtube_link}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
      </div>
      </div>
    )
  }
}

export default Live