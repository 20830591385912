import React from 'react'
import { Link } from 'react-router-dom'
import "./viewllSchedule.css"


const ViewAllSchedule = (props) => {
  return (
    <div className='pad-12'>
      <div className='button weekbutton'>

     <Link to="/WeekSchedulePage"  > <p>VIEW ALL SCHEDULE</p></Link>  

      </div>

    </div>
  )
}

export default ViewAllSchedule