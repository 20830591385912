import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import addimg from './header-add.png';
import { pagewiseads_url, getchannelbyseoname } from '../../globalURL';
import axios from 'axios';
import {Bling as GPT} from "react-gpt";

const NewAddsPage = (props) => {
  const { channel_seo_name, position } = props;
  const [pageFData, setPageFData] = useState([]);

  useEffect(() => {
    getChannelInfo(channel_seo_name);
  }, [channel_seo_name, position]);

  const getChannelInfo = async () => {
    try {
      const responseBody = { chnl_seo_name: channel_seo_name };
      const res = await axios.post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { 'Content-Type': 'application/json' },
      });

      const resp_data = res.data;
      if (resp_data.status === 'true' && resp_data.data != null) {
        const resp_datalist = resp_data.data;
        const chnl_id = resp_datalist[0].chnl_id;
        loadPageAds(chnl_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadPageAds = async (chnl_id) => {
    try {
      const responseBody = { channel_id: chnl_id, page: 4, position: position };
      const res = await axios.post(pagewiseads_url, JSON.stringify(responseBody), {
        headers: { 'Content-Type': 'application/json' },
      });

      const resp_data = res.data;
      if (resp_data.status === 'true' && resp_data.data != null) {
        const resp_datalist = resp_data.data;
        setPageFData(resp_datalist);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {pageFData.map((ads, index) => {
        if (ads.ad_type === 'Imageupload') {
          return (
            <div className='padin-t-b-10' key={index}>
              <div className='wid-500 text-center '>
                <Link to={ads.redirection_link}>
                  <img src={ads.image_url} alt='' className='max-wid-100' height='100' />
                </Link>
              </div>
            </div>
          );
        }else{
          return ( 

          
        <div id="idNewsHomepagead" className='w-100 text-center pb-10'  >
       
               <GPT
                adUnitPath="/21769336530/ETV_Horizontal_AD"
                sizeMapping={[
                  // { viewport: [0, 0], slot: [320, 50] },        // Mobile
                  // { viewport: [750, 0], slot: [728, 90] },      // Small tablet
                  // { viewport: [970, 0], slot: [970, 90] },      // Large tablet/small desktop
                  //
                   {viewport: [1050, 0], slot: [1024, 120]},
                  { viewport: [1024, 768], slot: [[1200, 150],[970, 90], [728, 90], [468, 60]] }, // Large desktop
                  { viewport: [750, 0], slot: [[728, 90], [468, 60]] }, // Tablet
                  { viewport: [300, 0], slot: [[300, 50]] } // Mobile
             
                ]}
            />
       </div>
          )
        }
        return null;
      })}
    </div>
  );
};

export default NewAddsPage;
