import React from 'react'

import AddBannerBottom from './Componets/AddBannerBottom'
import AddBannerMiddle from './Componets/AddBannerMiddle'
import AddBannerTop from './Componets/AddBannerTop'

const AddBanner = (props) => {
  return (
    <div>

        <AddBannerTop adwidth={300} adheight={250} whichad="rightfirstposition" topbanner={props.firstads} />
       <AddBannerMiddle  middilebanner={props.secondads} ourheadinginfo = {props.ourheadinginfo}  />
        <AddBannerBottom adwidth={300} adheight={600} whichad="rightlastposition"  bottombanner={props.lastads}  />
    </div>
  )
}

export default AddBanner