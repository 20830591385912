import React from 'react'
import Slider from "react-slick"; 
import { Link } from 'react-router-dom';
import "./Trending.css"
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';


const Trending = (props) => {
  const datadatatrending = props.datatrending;
  const video_list = datadatatrending.video_list;
  const channel_seo_name = props.channelseoname;
  const SampleNextArrow = (props)  => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}

      ><div className='dskfmdskf'>
        
       <FaChevronRight className='sfdsf' /></div> </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><div className='fdsf'>
 < FaChevronLeft  />
      </div>
       </div>
    );
  }
  
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay:false,
    delay: 3000,
    slidesToShow: 8,
    slidesToScroll:1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]


  };
  if(datadatatrending.video_list_cnt > 0){
  return (
    <div>
      
 
    <>
  

    <div className='feature-main'>
    <div className='featur'>
        
    <div className='fea-contact trending-shows'>
    <div class="row">
    <div class="col">
      <h2 class="head headd">{datadatatrending.tag_title}</h2>
      
      </div><div class="col"><a class="moed" href={`/AllNewsPage/${channel_seo_name}/${datadatatrending.menu_cat_name}/${datadatatrending.seo_tag_title}`} >More +</a></div></div>

      <Slider {...settings}>
      {video_list &&
    video_list.map(function (list, index) {
      let url = "/"+channel_seo_name+"/"+datadatatrending.menu_cat_name+"/"+list.show_name_seo_url; //"/:channelseo/:menuname/:showseo";
        return (  
      <div >
        <div className='carousel-card'>
            <Link to={url}>
              <img src={list.show_image} alt='' />
            </Link>
        </div>
      </div>
        )})}

     
   
    </Slider>
    </div>
    </div>
    </div>

    
    </>
    </div>
  )
}
}

export default Trending