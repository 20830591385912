import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { getsettingsurl,getchannelbyseoname } from "../../../globalURL";
import { useParams } from "react-router-dom";
const LogoImg = (props) => {
  const chnlId = props.chnlId;
  const [oursettings, oursettingslist] = useState([])
  const params = useParams();
  const [ourChannelinfo, ourSetChannelinfo] = useState([])

  const channel_seo_name = params.channelseo;
  useEffect(() => {
   // getSettings();
    getChannelInfo(channel_seo_name);

   // getTagsInfo();
  }, [channel_seo_name])
  const getChannelInfo = async (channel_seo_name,channelprops_name) => {
   // alert(channel_seo_name);
    if(channel_seo_name == undefined){ channel_seo_name = channelprops_name;}
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourSetChannelinfo(resp_datalist);
        }else{
        
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
 // alert(JSON.stringify(ourChannelinfo));
  // const getSettings = async () => {
  //   const responseBody = { };
  //   await axios
  //     .post(getsettingsurl, JSON.stringify(responseBody), {
  //       headers: { "Content-Type": "application/json" },
  //     })
  //     .then((res) => {
  //       //console.log(res.data);
  //       var resp_data = res.data;
  //       if (resp_data.status === 'true' && resp_data != null) {
  //         var resp_datalist = resp_data.data;
  //         oursettingslist(resp_datalist);
  //       }

  //     })
  //     .catch((error) => {
  //       //setIsLoading(false);
  //       console.log(error);
  //     });
  // }
  if(ourChannelinfo.length > 0){
    let url = "/".channel_seo_name;
   // alert(JSON.stringify(ourChannelinfo));
  return (
    <>
      <Link to={url}> <img src={ourChannelinfo[0].chnl_logo}  className='logo' alt='logo' /></Link>
    </>
  ) 
  }
}

export default LogoImg