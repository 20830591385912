import React,{useEffect,useState} from 'react'
import Heade from './Components/Header/Heade'
import { useParams } from 'react-router-dom'
import Videoblockmain from './Components/videoblock/Videoblockmain'
import TvSchedule from './Components/TvSchedules/TvSchedule'
import Slider from './Components/Slider/Slider'
import { getchannelbyseoname } from "./globalURL";
import axios from 'axios';

const Home = (props) => {
const params = useParams();
var channel_seo_name = params.channelseo;
if(channel_seo_name == undefined){
  channel_seo_name = "etv-telugu";
}
const [ourchannelinfo, ourchannelInfoList] = useState([]);
  useEffect(() => {
    getChannelFetchInfo(channel_seo_name);
  }, [channel_seo_name])


  const getChannelFetchInfo = async (channel_seo_name) => {
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
      
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
         var resp_datalist = resp_data.data;
          ourchannelInfoList(resp_datalist);
         
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  return (
    <>



      <Slider  />
      <TvSchedule />
      <Videoblockmain />



    </>
  )
}

export default Home