import React, { useEffect, useState } from 'react'

import WeekSchedule from './Components/WeekSchedule/WeekSchedule'
import WeekScheduleHeading from './Components/WeekSchedule/WeekScheduleHeading'
//import WeekScheduleVideo from './Components/WeekScheduleview/WeekScheduleVideo'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {NextArrow} from 'react-slick/lib/arrows.js'; // Import NextArrow from arrows.js

import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import { schedulelist_url, getchannelbyseoname, schedulelistall_url } from "./globalURL";
// import "../WeekScheduleVideo.css"
import { useParams } from 'react-router-dom';
const WeekSchedulePage = (props) => {
  const params = useParams();
  var channel_seo_name = params.channelseo;
  if (channel_seo_name == undefined) {
    channel_seo_name = "etu-telugu";
  }
  const [ourSchedules, setOurSchedules] = useState([]);
  const chnl_id = localStorage.getItem('session_chnl_id');
  const [ourchannelinfo, ourchannelInfoList] = useState([]);
  const [slidetoshowIndex, setSlidetoshowIndex] = useState(0);
  const [slidetoshowIndex0, setSlidetoshowIndex0] = useState(0);
  const [isScheduleLooped, SetIsScheduleLooped] = useState(false);
  // useEffect(() => {
  //   fetchSheduleInfo(chnl_id);
  // }, [chnl_id])
  useEffect(() => {
    getChannelFetchInfo(channel_seo_name);

  }, [channel_seo_name])
  const getChannelFetchInfo = async (channel_seo_name) => {
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {

        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourchannelInfoList(resp_datalist);
          //  chnl_id = resp_datalist[0].chnl_id;   
          fetchSheduleInfo(resp_datalist[0].chnl_id);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const fetchSheduleInfo = async (chnl_id) => {

    const responseBody = { show_channel_id: chnl_id };
    await axios
      .post(schedulelistall_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {

        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          // console.log(resp_datalist);
          setOurSchedules(resp_datalist);

          // funTagsWise(tagslist,resp_datalist[0].sv_show_id,resp_datalist[0].sv_id);

        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const SamplePrevArrowCurrent = (props) => {
    const { className, style, onClick, currentSlide, slidesToScroll } = props;
    const targetSlide = currentSlide - slidesToScroll; // Calculate the target slide index
  
    const handlePrevArrowClick = (e) => {
      e.preventDefault();
  
      // Call the onClick function with the target slide index
      if (typeof onClick === 'function') {
        onClick(e, targetSlide >= 0 ? targetSlide : 0); // Ensure targetSlide is not negative
      }
    };
  
    return (
      <div className={className} style={style} onClick={handlePrevArrowClick}>
        <div className='fdsf'>
          <FaChevronLeft />
        </div>
      </div>
    );
  };
  
  const SampleNextArrowCurrent = (props) => {
    if(props){
    const { className, style, onClick, currentSlide,slideCount,slidesToScroll } = props;
    //setSlidetoshowIndex0(currentSlide);

    const handleNextArrowClick = (options, e) => {
      if (e) {
        e.preventDefault();
      }
     // Calculate the target slide index
  let targetSlide = currentSlide; // Use slidesToScroll instead of a fixed value
  if (targetSlide >= slideCount) {
    targetSlide = slideCount - 1; // Set targetSlide to the last slide index if exceeding slideCount
  }

  // Call the onClick function with the target slide index
  if (typeof onClick === 'function') {
    onClick(e, targetSlide);
  }
    };

    return (
      <div className={className} style={style}    onClick={handleNextArrowClick} >
        <div className='dskfmdskf'>
          <FaChevronRight className='sfdsf' />
       
        </div>
      </div>
    );
  }
  };


  const SampleNextArrow = (props) => {
    const [manuallyClicked, setManuallyClicked] = useState(false);

  if(props){  
    const { className, style, onClick, currentSlide,slideCount,initialSlide } = props;
    setSlidetoshowIndex(currentSlide);

    const handleNextArrowClick = (options, e) => {
      if (e) {
        e.preventDefault();
      }
      setManuallyClicked(true);

       // Calculate the target slide index
       let targetSlide = currentSlide + 8;
       if (targetSlide >= slideCount) {
         targetSlide = currentSlide; // Do not wrap around, stay at the current slide
       }
     //  let isLastIndex = currentSlide+8;
    // Call the onClick function with the target slide index
      if (currentSlide !== undefined ) {
        if (typeof onClick === 'function') {

          onClick(e, targetSlide);
        }
      }
    };

    return (
      <div className={className} style={style}    onClick={handleNextArrowClick} >
        <div className='dskfmdskf'>
          <FaChevronRight className='sfdsf' />
       
        </div>
      </div>
    );
  }

  };

 

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><div className='fdsf'>
          < FaChevronLeft />
        </div>
      </div>
    );
  }
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 8,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    //initialSlide: 0, // Set initialSlide to a default value
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  
  const settingsCurrent = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 8,
    // slidesToScroll: slidetoshowIndex0,
    nextArrow: <SampleNextArrowCurrent />,
    prevArrow: <SamplePrevArrowCurrent />,

    //initialSlide: 0, // Set initialSlide to a default value
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };



  const getNextDateTime = (day) => {
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const today = new Date();
    const currentDay = today.getDay();
    const targetDayIndex = weekdays.indexOf(day);

    // Calculate the number of days to add to the current date
    let daysToAdd;
    if (targetDayIndex >= currentDay) {
      daysToAdd = targetDayIndex - currentDay;
    } else {
      daysToAdd = targetDayIndex + 7 - currentDay;
    }

    // Calculate the next date
    const nextDate = new Date();
    nextDate.setDate(today.getDate() + daysToAdd);

    // Set the desired time for the next date
    nextDate.setHours(12);
    nextDate.setMinutes(0);
    nextDate.setSeconds(0);
    nextDate.setMilliseconds(0);

    // Format the date string
    var dayName = weekdays[nextDate.getDay()];
    // if(currentDay == dayName){
    //   dayName = "Today";
    // }

    const monthName = months[nextDate.getMonth()];
    const date = nextDate.getDate();
    const year = nextDate.getFullYear();
   // const formattedDate = `${dayName} - ${monthName} ${date}`;
    const formattedDate = `${dayName} - ${monthName} ${date}, ${year}`;

    return formattedDate;
  };
  
  const isCurrentTimeInRange = (fromTime, toTime) => {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentPeriod = currentHours >= 12 ? 'pm' : 'am';
  
    const [fromHours, fromMinutes, fromPeriod] = fromTime.split(/:|\s/);
    const [toHours, toMinutes, toPeriod] = toTime.split(/:|\s/);
  
    const fromHour = parseInt(fromHours, 10);
    const fromMinute = parseInt(fromMinutes, 10);
    const toHour = parseInt(toHours, 10);
    const toMinute = parseInt(toMinutes, 10);
  
    // Convert 12-hour format to 24-hour format
    const convertedFromHour = fromPeriod === 'pm' ? fromHour + 12 : fromHour;
    const convertedToHour = toPeriod === 'pm' ? toHour + 12 : toHour;
  
    if (
      (currentPeriod === 'am' && fromPeriod === 'am' && toPeriod === 'am') || // All times are in AM
      (currentPeriod === 'pm' && fromPeriod === 'pm' && toPeriod === 'pm') || // All times are in PM
      (currentPeriod === 'am' && fromPeriod === 'am' && toPeriod === 'pm' && currentHours < 12) || // AM to PM range
      (currentPeriod === 'pm' && fromPeriod === 'am' && toPeriod === 'pm' && currentHours >= 12) // AM to PM range
    ) {
      if (
        (currentHours > convertedFromHour || (currentHours === convertedFromHour && currentMinutes >= fromMinute)) &&
        (currentHours < convertedToHour || (currentHours === convertedToHour && currentMinutes <= toMinute))
      ) {
        return true;
      }
    }
  
    return false;
  };
  
  

  return (
    <>

      <WeekScheduleHeading />

      <div className='fordf'>
      {ourSchedules &&
      ourSchedules.sort((a, b) => {

        // const daysOfWeekA = a.day;
        const capitalizedDay = a.day.charAt(0).toUpperCase() + a.day.slice(1);
        const daysOfWeekA = capitalizedDay;
        const daysOfWeekB = b.day.charAt(0).toUpperCase() + b.day.slice(1);
        //const daysOfWeekB = b.day;
        const nextDateTimeA = new Date(getNextDateTime(daysOfWeekA));
        const nextDateTimeB = new Date(getNextDateTime(daysOfWeekB));
        return nextDateTimeA - nextDateTimeB;
      }).map(function (list, index) {
      if(index === 0){
        const ourweeklydata = list[list.day];
        const day = list.day;
        const capitalizedDay = list.day.charAt(0).toUpperCase() + day.slice(1);
        const daysOfWeek = capitalizedDay;
        const nextDateTime = getNextDateTime(daysOfWeek); 
        let foundIndexAfter6AM = -1; // Initialize to -1 to indicate not found
        const dayMap = {}; // Initialize a map to track selected days
        let initialSlide = 2;
        const currentWeekday = new Date().toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();
        const isToday = day.toLowerCase() === currentWeekday;
        if(isToday && index === 0){
          {ourweeklydata &&
            ourweeklydata.forEach(function (weekdata, weekindex) {
              if(weekdata.iscurrentdaytime == true){
                initialSlide = list.active_slider_index;
                return true; 
              }
              // let isNowPlaying = false;
              // if (isToday && index === 0) {
              //   // Check if the day has already been selected
              //   if (!dayMap[currentWeekday]) {
              //     isNowPlaying = isCurrentTimeInRange(weekdata.fromtime, weekdata.totime);
              //     if (isNowPlaying) {
              //       initialSlide = weekindex;
              //       dayMap[currentWeekday] = true; // Mark the day as selected
              //       return true; // Exit the loop
              //     }
              //   }
              //  } 
            })}
          
            if (index === 0) {
              if (!isScheduleLooped && list.active_slider_index > 0) { // Check if the action is not performed and the condition is met
                console.log("active_slider_index", list.active_slider_index);
                console.log("index", list.active_slider_index);
              // setSlidetoshowIndex0(list.active_slider_index);
             //   setcurrentSlideIndex(list.active_slider_index);
             SetIsScheduleLooped(true); // Set 
              }
            }
        }else if (ourweeklydata) {
          const foundWeekdata = ourweeklydata.find((weekdata, weekindex) => {
              if (weekdata.ourindex === 1) {
                
                  initialSlide = weekindex;

                  return true; // Stop searching after the first occurrence
              }
              return false;
          });
      
          if (!foundWeekdata) {
              // Handle the case where no item with ourindex equal to 1 was found
          }
      }
     settingsCurrent.initialSlide = initialSlide;



        return (
          <div>
            <Container fluid className='pad-left-80 pad-right-80' key={index}>
              <div className='fridy'>
                <h2 className='head'> {isToday ? (
  <div >Today ( {nextDateTime.toLocaleString()} )</div>
) : (
  <div>{nextDateTime.toLocaleString()} </div>
)} </h2>
                <div className='feature-main'>
                  <div className='feature'>
                    <div className='fea-contact stydpro'>
                      
                    <Slider {...settingsCurrent}  ref={slider => {
        slider?.slickGoTo(initialSlide)
      }}   >
                    {ourweeklydata &&
ourweeklydata.map(function (weekdata, weekindex) {
  const item = weekdata.list;
  var tosuburl = "#";
  if(item.typeschedule == "show"){
   tosuburl = "/" + item.channel_seo_url + "/showview/"+ item.show_name_seo_url;
  }
  let from_time = weekdata.fromtime;
  let to_time = weekdata.totime;
  const timeArray = from_time.split(',');
  const totimeArray = to_time.split(',');
  const ispremierArray = weekdata.ispremier.split(',');
  const currentWeekday = new Date().toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();
  const isToday = item.weekday.toLowerCase() === currentWeekday;
  let isNowPlaying = false;    
  if (isToday) {
    isNowPlaying = isCurrentTimeInRange(from_time, to_time);
   
  }

 

  //
  return (
    timeArray.map(function (timeArraydata, timeindex) {
     
  // alert(JSON.stringify(weekdata.iscurrentdaytime));
     

    return (
      <div className='carousel-card carksk' key={timeindex}>
        <Link to={tosuburl}  style={{margin: "9px",
display: "block"}}>
        <div className='posv' >
          <img src={item.show_image} alt=''style={{width:"100%"}}/>
          <div className='timeing'><span>{from_time} </span></div>
        </div>
      
        <div className='d-flex align-items-center justify-content-between'>
          {item.typeschedule === "show" ? (
            <h4 className='font-st'>{item.show_name}  </h4>
          ) : (
            <h4 className='font-st'>{item.specialshowname}  </h4>
          )}
          {weekdata.iscurrentdaytime && <div className='watchType'> now showing  </div>  } 
          {weekdata.ispremier == 1 ? (
            <div className='watchType'>R</div>
          ) : (
            <div className='watchType'></div>
          )}
        </div>
        </Link>
      </div>
    );
    })
  );
})}

</Slider>

                 
                        </div>
                      </div>
                    </div>



                  </div>

                </Container>
              </div>
            )
          }
          })};

    {ourSchedules &&
      ourSchedules.sort((a, b) => {
        // const daysOfWeekA = a.day;
        const capitalizedDay = a.day.charAt(0).toUpperCase() + a.day.slice(1);
        const daysOfWeekA = capitalizedDay;
        const daysOfWeekB = b.day.charAt(0).toUpperCase() + b.day.slice(1);
        //const daysOfWeekB = b.day;
        const nextDateTimeA = new Date(getNextDateTime(daysOfWeekA));
        const nextDateTimeB = new Date(getNextDateTime(daysOfWeekB));
        return nextDateTimeA - nextDateTimeB;
      }).map(function (list, index) {
        if(index >= 1){
        const ourweeklydata = list[list.day];
        const day = list.day;
        const capitalizedDay = list.day.charAt(0).toUpperCase() + day.slice(1);
        const daysOfWeek = capitalizedDay;
        const nextDateTime = getNextDateTime(daysOfWeek); 
        let foundIndexAfter6AM = -1; // Initialize to -1 to indicate not found
        const dayMap = {}; // Initialize a map to track selected days
        let initialSlide = 2;
        const currentWeekday = new Date().toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();
        const isToday = day.toLowerCase() === currentWeekday;
        if(isToday && index === 0){
          {ourweeklydata &&
            ourweeklydata.forEach(function (weekdata, weekindex) {
              if(weekdata.iscurrentdaytime == true){
                initialSlide = list.active_slider_index;

              }
              // let isNowPlaying = false;
              // if (isToday && index === 0) {
              //   // Check if the day has already been selected
              //   if (!dayMap[currentWeekday]) {
              //     isNowPlaying = isCurrentTimeInRange(weekdata.fromtime, weekdata.totime);
              //     if (isNowPlaying) {
              //       initialSlide = weekindex;
              //       dayMap[currentWeekday] = true; // Mark the day as selected
              //       return true; // Exit the loop
              //     }
              //   }
              //  } 
            })}
            settings.initialSlide = initialSlide;
        }else if (ourweeklydata) {
          const foundWeekdata = ourweeklydata.find((weekdata, weekindex) => {
              if (weekdata.ourindex === 1) {
                
                  initialSlide = weekindex;
                  return true; // Stop searching after the first occurrence
              }
              return false;
          });
      
          if (!foundWeekdata) {
              // Handle the case where no item with ourindex equal to 1 was found
          }
      }
     // alert(initialSlide);
          settings.initialSlide = initialSlide;

        return (
          <div>
            <Container fluid className='pad-left-80 pad-right-80' key={index}>
              <div className='fridy'>
                <h2 className='head'> {isToday ? (
  <div >Today ( {nextDateTime.toLocaleString()} )</div>
) : (
  <div>{nextDateTime.toLocaleString()} </div>
)} </h2>
                <div className='feature-main'>
                  <div className='feature'>
                    <div className='fea-contact stydpro'>
                      
                    <Slider {...(index === 0 ? settingsCurrent :settings )} ref={slider => {
        slider?.slickGoTo(initialSlide)
      }}>

                    {ourweeklydata &&
ourweeklydata.map(function (weekdata, weekindex) {
  const item = weekdata.list;
  var tosuburl = "#";
  if(item.typeschedule == "show"){
   tosuburl = "/" + item.channel_seo_url + "/showview/"+ item.show_name_seo_url;
  }
  let from_time = weekdata.fromtime;
  let to_time = weekdata.totime;
  const timeArray = from_time.split(',');
  const totimeArray = to_time.split(',');
  const ispremierArray = weekdata.ispremier.split(',');
  const currentWeekday = new Date().toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();
  const isToday = item.weekday.toLowerCase() === currentWeekday;
  let isNowPlaying = false;    
  if (isToday) {
    isNowPlaying = isCurrentTimeInRange(from_time, to_time);
   
  }

 

  //
  return (
    timeArray.map(function (timeArraydata, timeindex) {
     
  // alert(JSON.stringify(weekdata.iscurrentdaytime));
     

    return (
      <div className='carousel-card carksk' key={timeindex}>
        <Link to={tosuburl}  style={{margin: "9px",
display: "block"}}>
        <div className='posv' >
          <img src={item.show_image} alt=''style={{width:"100%"}}/>
          <div className='timeing'><span>{from_time} </span></div>
        </div>
      
        <div className='d-flex align-items-center justify-content-between'>
          {item.typeschedule === "show" ? (
            <h4 className='font-st'>{item.show_name}  </h4>
          ) : (
            <h4 className='font-st'>{item.specialshowname}   </h4>
          )}
          {weekdata.iscurrentdaytime && <div className='watchType'> now showing </div>  } 
          {weekdata.ispremier == 1 ? (
            <div className='watchType'>R</div>
          ) : (
            <div className='watchType'></div>
          )}
        </div>
        </Link>
      </div>
    );
    })
  );
  
})}

</Slider>

                 
                        </div>
                      </div>
                    </div>



                  </div>

                </Container>
              </div>
            )
       }   })};

      </div>
      
    </>
  )
}

export default WeekSchedulePage