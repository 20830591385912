import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "./Politics.css"
import NewPlayIcon from '../../NewPlayIcon'
import PlayIcon from '../../PlayIcon';

const Politics = (obj) => {
  const ourpoliticsdata = obj.ourpoliticsdata;
  const video_list = ourpoliticsdata.video_list;
  const channel_seo_name = obj.channelseoname;
  const zeroindex = []; const uptofourindex = []; const uptofinaldata = [];
  if (ourpoliticsdata.video_list_cnt > 0) {
    for (let index = 0; index < ourpoliticsdata.video_list_cnt; index++) {
      //rows.push(ObjectRow());
      if (index == 0) {
        zeroindex.push(video_list[index]);
      } else if (index >= 1 && index <= 4) {
        uptofourindex.push(video_list[index]);

      } else if (index >= 5) {
        uptofinaldata.push(video_list[index]);
      }
    }
  }
  // console.log(zeroindex);
  // console.log("zeroindex");
  // console.log(uptofourindex);
  // console.log("uptofourindex");
  // console.log(uptofinaldata);
  // console.log("uptofinaldata");
  if (ourpoliticsdata.video_list_cnt > 0) {
    return (
      <div className='poli'>
        <div className='policits'>
          <Row><Col><h2 className="head headd">{ourpoliticsdata.tag_title}</h2></Col><Col><Link to={`/AllNewsPage/${channel_seo_name}/${ourpoliticsdata.menu_cat_name}/${ourpoliticsdata.seo_tag_title}`} className='moed'>More +</Link></Col></Row>

          <Row>
            <Col lg={6} >
              <Row>
                <Col lg={12}>
                  <div className='topn ' >
                    <Link to={`/NewsView/${channel_seo_name}/${ourpoliticsdata.menu_cat_name}/${ourpoliticsdata.seo_tag_title}/${zeroindex[0].sv_title_seo}`}>
                      <div className='newsf responive-img'>
                        <img src={zeroindex[0].medium_img} alt="News" height="148px"/>
                        <PlayIcon />
                      </div>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>

                {uptofourindex &&
                  uptofourindex.map(function (listdata, index) {
                    return (
                      <Col lg={6} md={6} sm={6} xs={6}>
                        <div className='topnew'>
                          <Link to={`/NewsView/${channel_seo_name}/${ourpoliticsdata.menu_cat_name}/${ourpoliticsdata.seo_tag_title}/${listdata.sv_title_seo}`}  >
                            <div className='newsf'>
                              <img src={listdata.medium_img} />
                              <PlayIcon />
                            </div>
                            <h4>{listdata.sv_title}</h4>
                          </Link>
                        </div>
                      </Col>
                    )
                  })}


              </Row>

            </Col>
            <Col lg={6}>
              <div className='newsLinks'>
                <ul>
                  {uptofinaldata &&
                    uptofinaldata.map(function (listdata, index) {
                      if(index <= 12){
                      return (
                        <li>
                          <Link to={`/NewsView/${channel_seo_name}/${ourpoliticsdata.menu_cat_name}/${ourpoliticsdata.seo_tag_title}/${listdata.sv_title_seo}`}  >{listdata.sv_title}</Link>
                        </li>
                      )
                      }
                    })}
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
export default Politics