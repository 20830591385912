import React from 'react'

import TvSchedule from './Components/TvSchedules/TvSchedule'
import News from './Components/News/News'


const NewsHome = () => {
  return (
    <div>
        <TvSchedule/>
      <News/>

    </div>
  )
}

export default NewsHome