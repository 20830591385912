import React from 'react'
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick"; 
import "./AddSlider.css"






const AddSlider = (props) => {
  
      
      var setting = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll:1,
    
        
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
    
    
      };
  return (
    <div className='sum shope'>
    
    <Slider {...setting}>
      <div>
        <div className='schedule-card'>
        <Link to="" className='mb-3 d-block'>
        <img src="../assets/images/add-01.png" alt="addd"/> 
      </Link>
        </div>
      </div>
      <div>
        <div className='schedule-card'>     
        <Link to="" className='mb-3 d-block'>
        <img src="../assets/images/add-01.png" alt=""/> 
      </Link>
        </div>
      </div>
      
      
   
    </Slider>
    
    </div>
  )
}

export default AddSlider