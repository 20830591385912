import React, { useState, useEffect } from 'react'
import AllNews from '../AllNews/Componets/AllNews'
import { useParams } from "react-router-dom";
import { getchannelbyseoname,getnewsHeadingsseomore } from "../../globalURL";
import axios from 'axios';
const AllNewsPage = () => {
const params = useParams();

const seo_tag_title = params.name;
const menuname = params.menuname;
const channel_seo_name = params.channelseo;
const [storeAllTags, setStoreAllTags] = useState([]);
var currentPage = 1;
useEffect(() => {
  getChannelInfo();
 // getTagsInfo();
}, [])
const getChannelInfo = async () => {
  const responseBody = { chnl_seo_name: channel_seo_name };
  await axios
    .post(getchannelbyseoname, JSON.stringify(responseBody), {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
    
      var resp_data = res.data;
      if (resp_data.status === 'true' && resp_data != null) {
        var resp_datalist = resp_data.data;
        var channel_id = resp_datalist[0].chnl_id;
        getTagsInfo(channel_id,seo_tag_title,currentPage); 
      }

    })
    .catch((error) => {
      //setIsLoading(false);
      console.log(error);
    });
}
const getTagsInfo = async (channel_id,seo_tag_title,currentPage) => {
  const responseBody = { channel_id:channel_id,seo_tag_title: seo_tag_title,page:currentPage };
  console.log(responseBody);
  await axios
    .post(getnewsHeadingsseomore, JSON.stringify(responseBody), {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
    
      var resp_data = res.data;
      if (resp_data.status === 'true' && resp_data != null) {
        var resp_datalist = resp_data.data;
        setStoreAllTags(resp_datalist); 
      }

    })
    .catch((error) => {
      //setIsLoading(false);
      console.log(error);
    });
}
  return (
    <div><AllNews name="andhra news"  ourdatatags = {storeAllTags}   /></div>
  )

  
}

export default AllNewsPage