import React, { useState, useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./ByGenere.css"
import { Col, Container, Row } from 'react-bootstrap';
import { Link, json, useParams } from "react-router-dom";
import { getchannelbyseoname, getmenudataseourl, getnewsHeadingsGenere } from "../../../globalURL";
import axios from 'axios';
import NewPlayIcon from '../../NewPlayIcon';

import AllNewsView from './AllNewsView';
import PlayIcon from '../../PlayIcon';

const ByGenereNews = (props,{ setSelectedValue }) => {
  const ourallheadings = props.ourallheadings;
  const channel_seo_name = props.channel_seo_name;
  //const onSelect = props.onSelect;
  const [selectedOption, setSelectedOption] = useState('');
  // const handleSelection = (event) => {
  //   const selectedValue = event.target.value;
  //   alert(selectedValue);
  //   setSelectedOption(selectedValue);
  //   //alert(selectedValue);

  //   // Call the callback function to send data to the parent component
  //   onSelect(selectedValue);
  // };
  const menuGeneClick = (title) => {
     
    //localStorage.setItem("session_chnl_id", chnl_id);
    // window.location.reload(false);
    setSelectedValue(title);
   // alert(JSON.stringify(chnl_id));
  }
  return (
    <div className='colored'>
 
        <Tabs >





          <TabList className="title comedy"   >
            <Row>
              <Col lg={4}></Col>
            </Row>
            {ourallheadings &&
              ourallheadings.map(function (list, subindex) {
                return (
                  <Tab  value={list.tag_title}  onClick={() => {
                    menuGeneClick(list.tag_title); 
                }}  >
                    {list.tag_title} <span>{list.video_list_cnt}</span>
                    
                  </Tab>
                )
              })}

          </TabList>


          {ourallheadings &&
            ourallheadings.map(function (list, subindex) {
              const video_list = list.video_list;


              return (
                <TabPanel key={subindex}>


                  <div className='shiwd'>


                    <Row>
                      {video_list &&
                        video_list.map(function (subdata, subindex) {
                          const seo_tag_titles = list.seo_tag_title;
                          return (
                            <Col lg={2} md={2} sm={4} xs={6}>
                              <div className='topnew'>
                                <Link to={`/NewsView/${channel_seo_name}/${list.menu_cat_name}/${seo_tag_titles}/${subdata.sv_title_seo}`} >
                                  <div className='newsf'>
                                    <img src={subdata.medium_img} />
                                    <PlayIcon />
                                  </div>
                                  <div className='contant-limit'>
                                  <h4>{subdata.sv_title}</h4>
                                  </div>
                                </Link>

                              </div>
                            </Col>
                          )
                        })}

                    </Row>
                    {/* <div className='text-center paddin-25'><Button > Load More  <FaUndo /> </Button></div> */}
                  </div>

                </TabPanel>

              )
            })}

          {/* 
<TabPanel>
<AllNewsView/>
</TabPanel>
<TabPanel>
<AllNewsView/>
</TabPanel> */}


        </Tabs>


    </div>
  )
}

export default ByGenereNews