import React from 'react'

import { Link } from 'react-router-dom'
function Archive({toggleMenu}) {
  return (
    <div className='showed'>

    <Link to="/Showview" onClick={toggleMenu}>
      <img src='../assets/images/show-01.jpg' alt='' />
      <span>Aadaalla Majaaka</span>
    </Link>
    <Link to="/Showview" onClick={toggleMenu}>
      <img src='../assets/images/show-02.jpg' alt='' />
      <span>Aadade Aadharam</span>
    </Link>
    <Link to="/Showview" onClick={toggleMenu}>
      <img src='../assets/images/show-03.jpg' alt='' />
      <span>Aataina Paataina</span>
    </Link>
    <Link to="/Showview" onClick={toggleMenu}>
      <img src='../assets/images/show-04.jpg' alt='' />
      <span>Abhimaani</span>
    </Link>
    <Link to="/Showview" onClick={toggleMenu}>
      <img src='../assets/images/show-05.jpg' alt='' />
      <span>Abhiruchi</span>
    </Link>

     
    </div>
  )
}

export default Archive