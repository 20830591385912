import React from 'react'
import AllShow from './Components/AllShow/Componets/AllShow'

const AllShowPage = () => {
  return (
    <div>
        
        <AllShow name="Shows"/>
    </div>
  )
}

export default AllShowPage