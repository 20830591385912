import React from 'react'
import "./Entertainment.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import NewPlayIcon from '../../NewPlayIcon';
import PlayIcon from '../../PlayIcon';
const Entertainment = (props) => {
  const myentertainment = props.myentertainment;
  const video_list = myentertainment.video_list;
  const channel_seo_name = props.channelseoname;
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}

      ><div className='dskfmdskf'>

          <FaChevronRight className='sfdsf' /></div> </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><div className='fdsf'>
          < FaChevronLeft />
        </div>
      </div>
    );
  }



  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    delay: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]


  };
  if (myentertainment.video_list_cnt > 0) {
    return (
      <div className='entertaiment'>
        <div className='entertaiment-in'>
          <div>


            <>


              <div className='feature-main'>
                <div className='feature entertral'>
                  <div className='fea-contact extracolo'>

                    <div class="row"><div class="col"><h2 class="head headd">{myentertainment.tag_title}</h2></div><div class="col"><a class="moed" href={`/AllNewsPage/${channel_seo_name}/${myentertainment.menu_cat_name}/${myentertainment.seo_tag_title}`} >More +</a></div></div>
                    <Slider {...settings}>
                      {video_list &&
                        video_list.map(function (listdata, index) {
                          return (
                            <div >
                              <div className='carousel-card'>
                                <Link to={`/NewsView/${channel_seo_name}/${myentertainment.menu_cat_name}/${myentertainment.seo_tag_title}/${listdata.sv_title_seo}`} >
                                  <div className='newsf'>
                                    <img src={listdata.medium_img} />
                                    <PlayIcon />
                                  </div>



                                  <Row>

                                    <Col lg={12}>
                                      <div className='date adddat'>
                                        <Link to={`/NewsView/${channel_seo_name}/${myentertainment.menu_cat_name}/${myentertainment.seo_tag_title}/${listdata.sv_title_seo}`}  >
                                          <h4>{listdata.sv_title}</h4>
                                        </Link>

                                      </div>
                                    </Col>

                                  </Row>

                                </Link>
                              </div>
                            </div>
                          )
                        })}

                    </Slider>
                  </div>
                </div>
              </div>


            </>
          </div>
        </div>
      </div>
    )
  }
}

export default Entertainment