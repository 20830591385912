import React from 'react'
import { useState } from 'react';



const Dropdown = () => {
    

    // const [selectedOption, setSelectedOption] = useState('');

    // const handleOptionChange = (event) => {
    //   setSelectedOption(event.target.value);
    // };

  return (
    <div className='drop-sean'>
        
      {/* <label htmlFor="dropdown">Select an option: </label> */}
      <select id="dropdown" className="custom-dropdown" style={{ backgroundColor: 'lightgray', border: '1px solid gray', borderRadius: '4px' }}>
        <option value="">Season 1 </option>
        <option value="option1">Season 2</option>
        <option value="option2">Season 3</option>
        <option value="option3">Season 4</option>
      </select>
    
    </div>
  )
}

export default Dropdown