import React from 'react'
import { FaChevronLeft,FaChevronRight, FaPlay, FaPlayCircle } from 'react-icons/fa'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick"; 
import { Link } from 'react-router-dom';
import NewPlayIcon from '../NewPlayIcon';


const TrendingNews = (props) => {
  console.log(props);
  //alert(JSON.stringify(props));
  const ourdatarelated = props.myrelatednews;
  const showing_sv_id = props.videoid;
  const channel_seo_name = props.channel_seo_name

    const SampleNextArrow = (props)  => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            onClick={onClick}
    
          ><div className='dskfmdskf'>
            
           <FaChevronRight className='sfdsf' /></div> </div>
        );
      }
    
      const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            onClick={onClick}
          ><div className='fdsf'>
     < FaChevronLeft  />
          </div>
           </div>
        );
      }
      
      var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        autoplay:true,
        delay: 3000,
        slidesToShow: 4,
        slidesToScroll:1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        
        
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
    
    
      };
  return (
 
      
 
    <>
    <h2 className='head'>related news</h2>

    <div className='feature-main'>
    <div className='feature'>
    <div className='fea-contact'>
      <Slider {...settings}>

      
      {
          ourdatarelated[0].video_list && ourdatarelated[0].video_list.map(function (sublist, index) {
            if(showing_sv_id!=sublist.sv_id){
            return (
            
      <div >
        <div className='carousel-card extraicon fontfamild'>
            <Link to={`/NewsView/${channel_seo_name}/${ourdatarelated[0].menu_cat_name}/${ourdatarelated[0].seo_tag_title}/${sublist.sv_title_seo}`} >
            <div className='newsf'>
                    <img src={sublist.image_path}/>
                    <NewPlayIcon/>
                    </div>
                  
              <h4>{sublist.sv_title}</h4>
            </Link>
          
        </div>
      </div>

            )
            }
            })}
  
   
    </Slider>
    </div>
    </div>
    </div>

    
    </>
 
  )
}

export default TrendingNews