import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import vide from "./Video/video.mp4"
import "./VideoView.css";
import { Player, ControlBar } from 'video-react';
import 'video-react/dist/video-react.css'; // import the styles



// import ReactPlayer from 'react-player'

 


const VideoView = () => {


     

  return (
    <>
      <div className='backcolo'> 
      <div className='videod'>
      {/* <Player>
        <source src={vide} />
        <ControlBar autoHide={true} />
      </Player> */}
{/* <iframe width="560" height="315" src="https://www.youtube.com/embed/7JBPq28O0sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      
       */}
       <img src="../assets/images/dheepic.jpg"/>
      </div>
     
  
   <Row className="sara">
    <Col lg={6} md={6} sm={6} xs={12}> 

   
      
        <h1 className='ali'>ALITHO SARADAGA</h1>
        <div class="showtime">

        <p className='shiw'> TV SHOW</p>
        <span>MON - SAT , 08:00 PM</span>

        </div>
       
    </Col>
    <Col lg={6} md={6} sm={6} xs={12}>
        <div className='watch'>
        <div className='watch-in'>
        <Row className='edio'>
          <Col lg={5} md={6} sm={6} xs={6}>
          <p>Watch Full <br/><span>Content on</span></p>
          </Col>
          <Col lg={7}  md={6} sm={6} xs={6}>
            <Row>
              <Col lg={4}  md={4} sm={4} xs={6} className="telugu">
              <div className='etv-telugu'><img src="./assets/images/etv-win.png" width="30"></img></div>
              </Col>
              <Col lg={8} md={8} sm={8} xs={6}  className="telugu">
              <Link to="https://www.etvwin.com/shows/cash" target='_blank' class="clic">Click Here</Link>
                </Col>
            </Row>
        
                
          
          </Col>
        </Row>
          
          
        </div>
        </div>
    </Col>
   </Row>
      <div className='backcolo-in'> 
       <div className='des'>
        <h4>Description</h4>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
remaining essentially unchanged.</p>

       </div>
       </div>

     </div>
    
    </>
  
  )
}

export default VideoView