import React, { useState, useEffect } from "react";
import  "./LogoBar.css";
import { Col, Container, Row } from "react-bootstrap";
import LogoImg from "./LogoImg";
import HeaderAdd from "./HeaderAdd";
import axios from 'axios';
import { get_main_menu_url,getloadheading_url,getchannelbyseoname } from "../../../globalURL";
import Search from "./Search";
import { useParams } from "react-router-dom";
const LogoBar = (props) => {
  const params = useParams();
  const channel_seo_name = params.channelseo;
  const channelprops_name = props.channel_seo_name;
  const chnlId = props.chnlId;
  // if(channel_seo_name == undefined){
  //   channel_seo_name = channelprops;
  // }
  const [ourChannelinfo, ourSetChannelinfo] = useState([])
  //alert(JSON.stringify(channel_seo_name));
  useEffect(() => {
    getChannelInfo(channel_seo_name,channelprops_name);
    //fetchCategoryData(chnlId);
  }, [channel_seo_name,channelprops_name])

  const getChannelInfo = async (channel_seo_name,channelprops_name) => {
    if(channel_seo_name == undefined){ channel_seo_name = channelprops_name;}
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          ourSetChannelinfo(resp_datalist);
        }else{
        
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  return (
    <div> 
      <div className="logo-bar">
        <Container fluid className="pad-left-80 pad-right-80">
          <Row className="justify-content-between align-items-center">
            <Col xs={2} className="d-block d-lg-none d-xl-none"></Col>

            <Col lg={2} md={3} xs={3}>
              <LogoImg  chnlId = {chnlId} />
            </Col>
            <Col lg={7} className="d-none d-lg-block d-xl-block">
              <HeaderAdd adwidth={970} adheight={90} whichad="headerad"/>
            </Col>
            <Col lg={3} md={3} xs={8} className="d-none d-lg-block d-xl-block">
              {/* <AppLinks/> */}
            </Col>

            <Col lg={3} md={3} xs={7} className="d-block d-lg-none d-xl-none">
              <Search/>
            </Col>
          </Row>
        </Container>

      </div>


</div>
  )
}

export default LogoBar