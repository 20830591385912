import React, { useState, useEffect } from 'react'
import Live from './Components/Live'
import "./News.css"
import { Container, Row, Col } from 'react-bootstrap'
import TopNews from './Components/TopNews'
import TodayShedules from './Components/TodayShedules'
import Politics from './Components/Politics'
import InterestingStories from './Components/InterestingStories'
import Entertainment from './Components/Entertainment'
import Business from './Components/Business'
import General from './Components/General'
import GeneralNext from './Components/GeneralNext'
import Trending from './Components/Trending'
import { Link, useParams } from "react-router-dom";
import { pagewiseads_url,getHeadingsBypagenews_url, getchannelbyseoname, getnewsHeadings_url, getlivechannel, getloadmenuadsurl } from "../../globalURL";
import axios from 'axios';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import Slider from 'react-slick'
// import "./TopNews.css"
import './Components/TopNews.css'
import './Components/TodayShedules.css'
import PlayIcon from '../PlayIcon';
import NewPlayIcon from '../NewPlayIcon';
import NewAddsPage from './NewAddsPage'


const News = () => {
  const [storeLiveData, setstoreLiveData] = useState([]);
  const [storeHeading1, setstoreHeading1] = useState([]);
  const [storeHeading2, setstoreHeading2] = useState([]);
  const [storeHeading3, setstoreHeading3] = useState([]);
  const [storeHeading4, setstoreHeading4] = useState([]);
  const [storeHeading5, setstoreHeading5] = useState([]);
  const [storeHeading6, setstoreHeading6] = useState([]);
  const [storeHeading7, setstoreHeading7] = useState([]);
  const [storeHeading8, setstoreHeading8] = useState([]);
  const [menuwiseAds, setMenuwiseAds] = useState([]);
  const [ourhometags, setOurHeadings] = useState([]);
  const channel_id = 0;
  const params = useParams();

  const channel_seo_name = params.channelseo;

  useEffect(() => {
    getChannelInfo(channel_seo_name);
  }, [channel_seo_name])

  const getChannelInfo = async () => {
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {

        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          var channel_id = resp_datalist[0].chnl_id;
          var menucatidsdata = resp_datalist[0].chnl_menu_cat_ids;
          var firstmenu_id = menucatidsdata[0];
          fetchData(channel_id, firstmenu_id);
          getlivechannelsdata(channel_id)
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const getlivechannelsdata = async (channel_id) => {
    const responseBody = { channel_id: channel_id };
    await axios
      .post(getlivechannel, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {

        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setstoreLiveData(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  function fetchData(channel_id, firstmenu_id) {

    getOurHeadings(channel_id, firstmenu_id, 1);
    getOurHeadings(channel_id, firstmenu_id, 2);
    getOurHeadings(channel_id, firstmenu_id, 3);
    getOurHeadings(channel_id, firstmenu_id, 4);
    getOurHeadings(channel_id, firstmenu_id, 5);
    getOurHeadings(channel_id, firstmenu_id, 6);
    getOurHeadings(channel_id, firstmenu_id, 7);
    getOurHeadings(channel_id, firstmenu_id, 8);
    loadMenuhomewiseAds(firstmenu_id, channel_id);
    loadHeadings(firstmenu_id, channel_id);
  }
  const loadMenuhomewiseAds = async (menu_cat_id, chnl_id) => {
   // const responseBody = { menu_cat_name: menu_cat_id, channel_id: chnl_id };
   const responseBody = { channel_id: chnl_id, page: 4, position: 5 };

    await axios
      .post(pagewiseads_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setMenuwiseAds(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };

  const loadHeadings = async (menu_cat_id, chnl_id) => {
    const responseBody = { tag_menu_id: "", pageid: 1, channel_id: chnl_id };
    await axios
      .post(getHeadingsBypagenews_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          setOurHeadings(resp_datalist);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };

  const getOurHeadings = async (channel_id, firstmenu_id, position) => {
    const responseBody = { channel_id: channel_id, tag_menu_id: firstmenu_id, position: position };
    await axios
      .post(getnewsHeadings_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          if (position == 1) {
            setstoreHeading1(resp_datalist);
          }
          if (position == 2) {
              setstoreHeading2(resp_datalist);
          }
          if (position == 3) {
            setstoreHeading3(resp_datalist);
          }
          if (position == 4) {
            setstoreHeading4(resp_datalist);
          }
          if (position == 5) {
            setstoreHeading5(resp_datalist);
          }
          if (position == 6) {
            setstoreHeading6(resp_datalist);
          }
          if (position == 7) {
            setstoreHeading7(resp_datalist);
          }
          if (position == 8) {
            setstoreHeading8(resp_datalist);
          }


        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  //alert(JSON.stringify(ourhometags));

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}

      ><div className='dskfmdskf'>

          <FaChevronRight className='sfdsf' /></div> </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><div className='fdsf'>
          < FaChevronLeft />
        </div>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    delay: 3000,
    slidesToShow: 8,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]


  };
  var settingsshows = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    delay: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]


  };
  var settingsvertical = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    delay: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      }
    ]

  };
  return (
    <div className='top'>
      <Container fluid className='pad-left-80 pad-right-80'>
        <Row>

          <Col lg={6} md={12}>
            <Live ourLiveData={storeLiveData} />
          </Col>
          <Col lg={6} md={12}>
            {storeHeading1 &&
              storeHeading1.map(function (list, index) {
                //   alert(JSON.stringify(list));

                if (list.video_list_cnt > 0) {
                  return (
                    <div>

                      <Row>
                        <Col> <h2 className="head headd">{list.tag_title}</h2></Col>
                      <Col><Link to={`/AllNewsPage/${channel_seo_name}/${list.menu_cat_name}/${list.seo_tag_title}`} className='moed'>More +</Link></Col>
                      </Row>
                      <Row>
                        {list.video_list &&
                          list.video_list.map(function (sublist, subindex) {
                            if (subindex <= 5) {
                            return (
                              <Col lg={4} md={4} sm={4} xs={6}>
                                <div className='topnew'>
                                  <Link to={`/NewsView/${channel_seo_name}/${list.menu_cat_name}/${list.seo_tag_title}/${sublist.sv_title_seo}`}>
                                    <div className='newsf'>
                                      <img src={sublist.medium_img} />
                                      <PlayIcon />
                                    </div>
                                    <p></p>
                                    <h4>{sublist.sv_title}</h4>

                                  </Link>




                                </div>
                              </Col>
                            )
                            }
                          })}
                      </Row>

                    </div>
                  )
                }
              })}

          </Col>
        </Row>

        <Row>

          {storeHeading2 &&
            storeHeading2.map(function (list, index) {
              if (list.video_list_cnt > 0) {
                return (
                  <TodayShedules ourtodayheadlines={list} channelseoname={channel_seo_name} />
              
              )
                // return (
                //   <div>


                //     <>
                //       <Row><Col><h2 className="head headdtoday">{list.tag_title}</h2></Col><Col><Link to={`/AllNewsPage/${channel_seo_name}/${list.menu_cat_name}/${list.seo_tag_title}`} className='moed'>More +</Link></Col></Row>


                //       <div className='feature-main'>
                //         <div className='feat'>
                //           <div className='fea-contact iconrood'>
                //             <Slider {...settings}>




                //               {list.video_list &&
                //                 list.video_list.map(function (sublist, subindex) {
                                 

                //                   return (
                //                     <div >
                //                       <div className='carousel-card extraicon'>
                //                         <div className='colid-in'>
                //                           <Row className='colodbackfunf'>


                //                             <Col lg={12} className='pad-0d'>
                //                               <Link to={`/NewsView/${channel_seo_name}/${list.menu_cat_name}/${list.seo_tag_title}/${sublist.sv_title_seo}`}>
                //                                 <div className='newsf'>
                //                                   <img src={sublist.medium_img} />
                //                                   <PlayIcon />
                //                                 </div>

                //                               </Link>
                                          
                //                               <Link to={`/NewsView/${channel_seo_name}/${list.menu_cat_name}/${list.seo_tag_title}/${sublist.sv_title_seo}`} className='fortextc'>
                //                                 <h4 style={{margin:'0px !important'}}>{sublist.sv_title}</h4>
                //                               </Link>
                //                             </Col>
                //                           </Row>
                //                         </div>


                //                       </div>
                //                     </div>
                //                   )
                                  
                //                 })
                //               }



                //             </Slider>
                //           </div>
                //         </div>
                //       </div>


                //     </>

                //   </div>
                // )
              }
            })}
        
        </Row>
       
   
        <div className='intereting'>
        <Row>
          <Col lg={12}>
          <NewAddsPage  channel_seo_name={channel_seo_name} position={4}/>
          </Col>
        </Row>
       
          <Row>

            <Col lg={8} >
              {storeHeading3 &&
                storeHeading3.map(function (listdata, index) {
                  return (
                    <Politics ourpoliticsdata={listdata} channelseoname={channel_seo_name} />
                  )
                })}
            </Col>
            <Col lg={4}>
              {storeHeading4 &&
                storeHeading4.map(function (list, index) {
                  return (

                    <InterestingStories ourdata={list} channelseoname={channel_seo_name} />
                  )
                })}

            </Col>

          </Row>
          {storeHeading5 &&
            storeHeading5.map(function (list, index) {
              return (
                <Entertainment myentertainment={list} channelseoname={channel_seo_name} />
              )
            })}
          {storeHeading6 &&
            storeHeading6.map(function (list, index) {
              return (
              <div>
                 {/* <NewAddsPage/> */}
                 <Business databussiness={list} mymenuads={menuwiseAds} channelseoname={channel_seo_name} />
              </div>
                
                
                
              )
            })}
          {storeHeading7 &&
            storeHeading7.map(function (list, index) {
              return (
                <General datageneral={list} channelseoname={channel_seo_name} />
              )
            })}

             {/* {storeHeading8 &&
            storeHeading8.map(function (listg, index) {
              return (
                <GeneralNext datageneralnext={listg} channelseoname={channel_seo_name} />
              )
            })} */}

          {/* {storeHeading8 &&
    storeHeading8.map(function (list, index) {
        return (  
<Trending  datatrending={list}  channelseoname = {channel_seo_name} />

)})} */}


          {ourhometags &&
            ourhometags.map(function (list, index) {
             // alert(JSON.stringify(list));
              const tourl = "/heading/" + list.channel_seo_url + "/" + list.seo_tag_title;
              if (list.count_videos > 0) {
                if (list.tag_type == "show") {
                  return (

                    <div>


                      <>


                        <div className='feature-main'>
                          <div className='featur'>

                            <div className='fea-contact fea-arrow'>
                              <div class="row">
                                <div class="col"><h2 class="head headd">{list.tag_title}</h2></div>
                                <div class="col"><a class="moed" href={tourl} > More +</a></div></div>

                              <Slider {...(list.layout_type === "horizontal" ? settingsshows : settings)} >
                                {list.getVideosList &&
                                  list.getVideosList.map(function (videolist, videoindex) {
                                    //  let suburl = "/"+videolist.channel_seo_url+"/"+list.menu_cat_name+"/"+videolist.show_name_seo_url;
                                    let suburl = "/" + videolist.channel_seo_url + "/showview/" + videolist.show_name_seo_url;

                                    return (
                                      <div >
                                        <div className='carousel-card'>
                                          <Link to={suburl}>
                                            {list.layout_type === "horizontal" ? (
                                              <img src={videolist.show_image} alt='' />
                                            ) : (
                                              <img src={videolist.show_image2} alt='' />
                                            )}

                                          </Link>
                                        </div>
                                      </div>
                                    )
                                  })}

                              </Slider>
                            </div>
                          </div>
                        </div>


                      </>
                    </div>




                  );
                } else {

                  if (list.tag_template == "NonNews") {
                  return (


                    <div key={list.tag_id}>


                      <>

                      


                        <div className='feature-main'>
                          <div className='featur'>

                            <div className='fea-contact'>
                              <div class="row">
                                <div class="col"><h2 class="head headd">{list.tag_title}</h2></div>
                                <div class="col"><a class="moed" href={tourl} >More +</a></div></div>

                                <Slider {...(list.layout_type === "horizontal" ? settings : settingsvertical)} >
                                {list.getVideosList &&
                                  list.getVideosList.map(function (videolist, videoindex) {
                                    //  let tosuburl = "/video/"+videolist.channel_seo_url +"/"+ videolist.seo_tag_title+"/"+ videolist.sv_title_seo;
                                    let tosuburl = "/video/" + videolist.channel_seo_url + "/" + videolist.seo_tag_title + "/" + videolist.sv_title_seo;

                                    return (
                                      <div key={videoindex} >
                                        <div className='carousel-card extraicon'>
                                          <Link to={tosuburl} >
                                            <div className='extra' >
                                            {/* {list.layout_type === "horizontal" ? (
                                                <img src={videolist.image_path} alt='' />
                                              ) : (
                                                <img src={videolist.vertical_image} alt='' />
                                              )} */}
                                           {videolist.custom_youtube_type === 'Youtube' ? (
                                          <img src={videolist.medium_img} alt='' />
                                          ) : (
                                          list.layout_type === 'horizontal' ? (
                                          <img src={videolist.image_path} alt='' />
                                          ) : (
                                          <img src={videolist.vertical_image} alt='' />
                                          )
                                          )}
                                              
                                              <PlayIcon />
                                            </div>
                                            <h4>{videolist.sv_title}</h4>
                                          </Link>

                                        </div>
                                      </div>

                                    );

                                  })}


                              </Slider>
                            </div>
                          </div>
                        </div>


                      </>
                    </div>







                  );
                                } else{
                                  return (


                                    <div key={list.tag_id}>
                
                
                                      <>
                                      <div className='nadnda'>
                                      <div class="row">
                                                <div class="col">
                                                  <h2 class="head headd">{list.tag_title}</h2>
                                                  </div>
                                                  <div class="col"><a class="moed" href={tourl} >More +</a>
                                                  </div>
                                                </div>
          <div className='fea-contact fea-gender'>
         
          <Slider {...(list.layout_type === "horizontal" ? settings : settingsvertical)} >
                                                {list.getVideosList &&
                                                  list.getVideosList.map(function (videolist, videoindex) {
                                                    //  let tosuburl = "/video/"+videolist.channel_seo_url +"/"+ videolist.seo_tag_title+"/"+ videolist.sv_title_seo;
                                                   // let tosuburl = "/video/" + videolist.channel_seo_url + "/" + videolist.seo_tag_title + "/" + videolist.sv_title_seo;
                
                                                    return (
                                                      <div key={videoindex} >
                                                        <div className='carousel-card extraicon'>
                                                          <Link to={`/NewsView/${videolist.channel_seo_url}/${list.menu_cat_name}/${list.seo_tag_title}/${videolist.sv_title_seo}`} >
                                                            <div className='extra' >
                                                              {list.layout_type === "horizontal" ? (
                                                                <img src={videolist.medium_img} alt='' />
                                                              ) : (
                                                                <img src={videolist.medium_img} alt='' />
                                                              )}
                                                              <PlayIcon />
                                                            </div>
                                                            <h4>{videolist.sv_title}</h4>
                                                          </Link>
                
                                                        </div>
                                                      </div>
                
                                                    );
                
                                                  })}
                
                
                                              </Slider>
                   </div>
                      </div>
       
                
                
                                        {/* <div className='feature-main'>
                                          <div className='featur'>
                
                                            <div className='fea-contact trending-shows'>
                                              <div class="row">
                                                <div class="col">
                                                  <h2 class="head headd">{list.tag_title}</h2>
                                                  </div>
                                                  <div class="col"><a class="moed" href={tourl} >More +</a>
                                                  </div>
                                                </div>
                
                                              <Slider {...settings}>
                                                {list.getVideosList &&
                                                  list.getVideosList.map(function (videolist, videoindex) {
                                                    //  let tosuburl = "/video/"+videolist.channel_seo_url +"/"+ videolist.seo_tag_title+"/"+ videolist.sv_title_seo;
                                                   // let tosuburl = "/video/" + videolist.channel_seo_url + "/" + videolist.seo_tag_title + "/" + videolist.sv_title_seo;
                
                                                    return (
                                                      <div key={videoindex} >
                                                        <div className='carousel-card extraicon'>
                                                          <Link to={`/NewsView/${videolist.channel_seo_url}/${list.menu_cat_name}/${list.seo_tag_title}/${videolist.sv_title_seo}`} >
                                                            <div className='extra' >
                                                              {list.layout_type === "horizontal" ? (
                                                                <img src={videolist.medium_img} alt='' />
                                                              ) : (
                                                                <img src={videolist.medium_img} alt='' />
                                                              )}
                                                              <PlayIcon />
                                                            </div>
                                                            <h4>{videolist.sv_title}</h4>
                                                          </Link>
                
                                                        </div>
                                                      </div>
                
                                                    );
                
                                                  })}
                
                
                                              </Slider>
                                            </div>
                                          </div>
                                        </div> */}
                
                
                                      </>
                                    </div>
                
                
                
                
                
                
                
                                  );

                                }

                }
              }

            })}
            <NewAddsPage channel_seo_name={channel_seo_name} position={6} />
            

        </div>

    

      </Container>


    </div>


  )
}

export default News