import React from 'react'
import { FaChevronLeft,FaChevronRight,} from 'react-icons/fa'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick"; 
import { Link } from 'react-router-dom';
import PlayIcon from '../PlayIcon';


const NewsRelated = (props) => {

    //
    var detailedpage = props.detailedpage;
    var ourhometags = props.otherchanneltagdetails;
 //   console.log(ourhometags);
    const SampleNextArrow = (props)  => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            onClick={onClick}
    
          ><div className='dskfmdskf'>
            
           <FaChevronRight className='sfdsf' /></div> </div>
        );
      }
    
      const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            onClick={onClick}
          ><div className='fdsf'>
     < FaChevronLeft  />
          </div>
           </div>
        );
      }
      
      var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        autoplay:false,
        delay: 3000,
        slidesToShow: 6,
        slidesToScroll:1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        
        
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
    
    
      };
      var settingsvertical = {
        dots: false,
        infinite: false,
        speed: 500,
        autoplay: false,
        delay: 3000,
        slidesToShow: 8,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    
    
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
    
      };





  return (
<div>
          
     
          <>

          {/* <div class="row"><div class="col-lg-6 col-md-6 col-6"><h2 class="head">Top News</h2></div><div class="text-end col-lg-6 col-md-6 col-3"><Link class="more" to="#" >More +</Link></div></div>

      
          <div className='feature-main'>
          <div className='feature'>
          <div className='fea-contact '>
            <Slider {...settings}>
            <div >
              <div className='carousel-card extraicon'>
                  <Link to="/WeekScheduleViewVideo" >
                  <div className='extra'>
                    <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/1690280621ALITHO_PROMO_19July23_384x216.jpg" alt='' />
                      <PlayIcon/>
                    </div>
                    
                 
                    <h4>3 PM | 5th August 2023 | Ghantaravam | News Headlines | ETV Andhra Pradesh</h4>
                  </Link>
              
              </div>
            </div>
            <div >
              <div className='carousel-card extraicon'>
              <Link to="/WeekScheduleViewVideo">
              <div className='extra'>
                    <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/1690280106ZAB-PROMO-384x216.jpg" alt='' />
                      <PlayIcon/>
                    </div>
          
                  <h4>3 PM | 5th August 2023 | Ghantaravam | News Headlines | ETV Andhra Pradesh </h4>
                </Link>
                
              </div>
            </div>
            <div >
              <div className='carousel-card'>
              <Link to="/WeekScheduleViewVideo">
              <div className='extra'>
                    <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/1690279868PT-PROMO_25July23_384x216.jpg" alt='' />
                      <PlayIcon/>
                    </div>
          
                    <h4>3 PM | 5th August 2023 | Ghantaravam | News Headlines | ETV Andhra Pradesh </h4>
                  </Link>
              </div>
            </div>
            <div >
              <div className='carousel-card'>
              <Link to="/WeekScheduleViewVideo">
              <div className='extra'>
                    <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/1690279706DHEE_PROMO_30June23_384x216.jpg" alt='' />
                      <PlayIcon/>
                    </div>
                    <h4>3 PM | 5th August 2023 | Ghantaravam | News Headlines | ETV Andhra Pradesh</h4>
                </Link>
              </div>
            </div>
            <div >
              <div className='carousel-card'>
              <Link to="/WeekScheduleViewVideo">
              <div className='extra'>
                    <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/1690279706DHEE_PROMO_30June23_384x216.jpg" alt='' />
                      <PlayIcon/>
                    </div>
                    <h4>3 PM | 5th August 2023 | Ghantaravam | News Headlines | ETV Andhra Pradesh </h4>
                </Link>
              </div>
            </div>
            <div >
              <div className='carousel-card'>
              <Link to="/WeekScheduleViewVideo">
              <div className='extra'>
                    <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/1690279706DHEE_PROMO_30June23_384x216.jpg" alt='' />
                      <PlayIcon/>
                    </div>
                    <h4>3 PM | 5th August 2023 | Ghantaravam | News Headlines | ETV Andhra Pradesh </h4>
                </Link>
              </div>
            </div>
            <div >
              <div className='carousel-card'>
              <Link to="/WeekScheduleViewVideo">
              <div className='extra'>
                    <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/1690279706DHEE_PROMO_30June23_384x216.jpg" alt='' />
                      <PlayIcon/>
                    </div>
                    <h4>3 PM | 5th August 2023 | Ghantaravam | News Headlines | ETV Andhra Pradesh</h4>
                </Link>
              </div>
            </div>
            <div >
              <div className='carousel-card'>
              <Link to="/WeekScheduleViewVideo">
              <div className='extra'>
                    <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/1690279706DHEE_PROMO_30June23_384x216.jpg" alt='' />
                      <PlayIcon/>
                    </div>
                    <h4>3 PM | 5th August 2023 | Ghantaravam | News Headlines | ETV Andhra Pradesh </h4>
                </Link>
              </div>
            </div>
            <div >
              <div className='carousel-card'>
              <Link to="/WeekScheduleViewVideo">
              <div className='extra'>
                    <img src="https://devetvcoin.s3.ap-south-1.amazonaws.com/uploads/1690279706DHEE_PROMO_30June23_384x216.jpg" alt='' />
                      <PlayIcon/>
                    </div>
                    <h4>3 PM | 5th August 2023 | Ghantaravam | News Headlines | ETV Andhra Pradesh </h4>
                </Link>
              </div>
            </div>
         
          </Slider>
          </div>
          </div>
          </div>
       */}
      {detailedpage &&
      detailedpage.map(function (list, index) {
      const tourl = "/heading/" + list.channel_seo_url + "/" + list.seo_tag_title;
      if (list.tag_type != "show") {
        // alert(JSON.stringify(list));
      return (

        <div key={list.tag_id}>

          <>
            {/* <div class="row" ><div class="col-lg-6 col-md-6 col-9"><h2 class="head">{list.tag_title}</h2></div><div class="text-end col-lg-6 col-md-6 col-3"><Link class="more" to={tourl}> More +</Link></div></div> */}

            <div class="row" >
              <div class="col-lg-6 col-md-6 col-9">
                <h2 class="head" style={{display:"inline-block"}}>{list.tag_title}   </h2>
                <div className='styl-count'>{list.video_list.length}</div>
                </div>
                <div class="text-end col-lg-6 col-md-6 col-3"><Link class="more" to={tourl}>More +</Link>
                </div>
              </div>
            <div className='feature-main'>
              <div className='feature'>
                <div className='fea-contact '>
                  {/* <Slider {...settings}> */}
                  <Slider
      {...(list.layout_type === "horizontal" || list.layout_type === "" ? settings : settingsvertical)}                                    >



                    {list.video_list &&
                      list.video_list.map(function (videolist, videoindex) {
                      
                      let tosuburl = "/NewsView/" + videolist.channel_seo_url + "/"  + list.menu_cat_name + "/"+ list.seo_tag_title + "/" + videolist.sv_title_seo;

                        return (

                          <div key={videoindex} >
                            <div className='carousel-card extraicon'>
                              <Link to={tosuburl} >
                                <div className='extra'>
                                  {list.tag_type === "video" && list.tag_template==="News" ? (

                                        <img src={videolist.medium_img} alt='' />
                                  ):
                                  (
                                  list.layout_type === "horizontal" ? (
                                    <img src={videolist.image_path} alt='' />
                                  ) : (
                                    <img src={videolist.vertical_image} alt='' />
                                  )
                                  )
                                  
                                  }
                                  
                                  <PlayIcon />
                                </div>
                                <h4>{videolist.sv_title}</h4>
                              </Link>

                            </div>
                          </div>

                        );

                      })}
                  </Slider>

                </div>

              </div>
            </div>


          </>
        </div>

      );

      }


      })}
 {ourhometags &&
            ourhometags.map(function (list, index) {
             // alert(JSON.stringify(list));
              const tourl = "/heading/" + list.channel_seo_url + "/" + list.seo_tag_title;
              if (list.count_videos > 0) {
                if (list.tag_type == "show") {
                  return (

                    <div>


                      <>


                        <div className='feature-main'>
                          <div className='featur'>

                            <div className='fea-contact fea-arrow'>
                              {/* <div class="row">
                                <div class="col"><h2 class="head headd">{list.tag_title}</h2></div>
                                <div class="col"><a class="moed" href={tourl} > More +</a></div>
                                </div> */}

                                      <div class="row">
                                      <div class="col"><h2 class="head headd">{list.tag_title}</h2>
                                      <div className='styl-count'>{list.getVideosList.length}</div>

                                      </div>
                                      <div class="col"><a class="moed" href={tourl} > More +</a></div>
                                      </div>


                              <Slider {...(list.layout_type === "horizontal" || list.layout_type === "" ? settings : settingsvertical)}        >
  
                                {list.getVideosList &&
                                  list.getVideosList.map(function (videolist, videoindex) {
                                    //  let suburl = "/"+videolist.channel_seo_url+"/"+list.menu_cat_name+"/"+videolist.show_name_seo_url;
                                    let suburl = "/" + videolist.channel_seo_url + "/showview/" + videolist.show_name_seo_url;

                                    return (
                                      <div >
                                        <div className='carousel-card'>
                                          <Link to={suburl}>
                                            {list.layout_type === "horizontal" ? (
                                              <img src={videolist.show_image} alt='' />
                                            ) : (
                                              <img src={videolist.show_image2} alt='' />
                                            )}

                                          </Link>
                                        </div>
                                      </div>
                                    )
                                  })}

                              </Slider>
                            </div>
                          </div>
                        </div>


                      </>
                    </div>




                  );
                } else {
                      
                  if (list.tag_template == "NonNews") {
                  return (


                    <div key={list.tag_id}>


                      <>

                      


                        <div className='feature-main'>
                          <div className='featur'>

                            <div className='fea-contact'>
                              <div class="row">
                                <div class="col"><h2 class="head headd">{list.tag_title} </h2>
                                
                                <div className='styl-count'>{list.getVideosList.length}</div>

                                </div>
                                <div class="col"><a class="moed" href={tourl} >More +</a></div></div>

                              <Slider {...(list.layout_type === "horizontal" || list.layout_type === "" ? settings : settingsvertical)}     >
 
                                {list.getVideosList &&
                                  list.getVideosList.map(function (videolist, videoindex) {
                                    //  let tosuburl = "/video/"+videolist.channel_seo_url +"/"+ videolist.seo_tag_title+"/"+ videolist.sv_title_seo;
                                    let tosuburl = "/video/" + videolist.channel_seo_url + "/" + videolist.seo_tag_title + "/" + videolist.sv_title_seo;

                                    return (
                                      <div key={videoindex} >
                                        <div className='carousel-card extraicon'>
                                          <Link to={tosuburl} >
                                            <div className='extra' >
                                            {/* {list.layout_type === "horizontal" ? (
                                                <img src={videolist.image_path} alt='' />
                                              ) : (
                                                <img src={videolist.vertical_image} alt='' />
                                              )} */}
                                               {videolist.custom_youtube_type === 'Youtube' ? (
                                          <img src={videolist.medium_img} alt='' />
                                          ) : (
                                          list.layout_type === 'horizontal' ? (
                                          <img src={videolist.image_path} alt='' />
                                          ) : (
                                          <img src={videolist.vertical_image} alt='' />
                                          )
                                          )}
                                              <PlayIcon />
                                            </div>
                                            <h4>{videolist.sv_title}</h4>
                                          </Link>

                                        </div>
                                      </div>

                                    );

                                  })}


                              </Slider>
                            </div>
                          </div>
                        </div>


                      </>
                    </div>







                  );
                                } else{
                                  return (


                                    <div key={list.tag_id}>
                
                
                                      <>
                                      <div className='nadnda'>
                                      <div class="row">
                                                <div class="col">
                                                  <h2 class="head headd">{list.tag_title}
                                                  <div className='styl-count'>{list.getVideosList.length}</div>

                                                  </h2>
                                                  </div>
                                                  <div class="col"><a class="moed" href={tourl} >More +</a>
                                                  </div>
                                                </div>
          <div className='fea-contact fea-gender'>
         
          <Slider {...settings}>
                                                {list.getVideosList &&
                                                  list.getVideosList.map(function (videolist, videoindex) {
                                                    //  let tosuburl = "/video/"+videolist.channel_seo_url +"/"+ videolist.seo_tag_title+"/"+ videolist.sv_title_seo;
                                                   // let tosuburl = "/video/" + videolist.channel_seo_url + "/" + videolist.seo_tag_title + "/" + videolist.sv_title_seo;
                
                                                    return (
                                                      <div key={videoindex} >
                                                        <div className='carousel-card extraicon'>
                                                          <Link to={`/NewsView/${videolist.channel_seo_url}/${list.menu_cat_name}/${list.seo_tag_title}/${videolist.sv_title_seo}`} >
                                                            <div className='extra' >
                                                              {list.layout_type === "horizontal" ? (
                                                                <img src={videolist.medium_img} alt='' />
                                                              ) : (
                                                                <img src={videolist.medium_img} alt='' />
                                                              )}
                                                              <PlayIcon />
                                                            </div>
                                                            <h4>{videolist.sv_title}</h4>
                                                          </Link>
                
                                                        </div>
                                                      </div>
                
                                                    );
                
                                                  })}
                
                
                                              </Slider>
                   </div>
                      </div>
       
                                      </>
                                    </div>
                
                
                
                
                
                
                
                                  );

                                }

                }
              }

            })}
          </>
          </div>
  )
}

export default NewsRelated