import React, { useEffect, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link, useLocation, useParams } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import "./ModaySchedule.css"
import { get_tvschedules, getchannelbyseoname, schedulelistall_url,tvshedules_url } from "../../globalURL";
import axios from 'axios';

const ModaySchedule = (props) => {
  const location = useLocation();
  //onst chnl_id = location.state;
  var chnl_id = localStorage.getItem('session_chnl_id');
  const params = useParams();
  var channel_seo_name = params.channelseo;
  if (channel_seo_name == undefined) {
    channel_seo_name = "etv-telugu";
  }
  const [ourShowtimings, ourChannelsList] = useState([]);
  const [montimings, ourmontimings] = useState([]);
 var objslidersetting = props.objslidersetting;
 const [finalinitialslider, setFinalinitialslider] = useState(0);
   //console.log(objslidersetting);
  const getChannelFetchInfo = async (channel_seo_name) => {
    const responseBody = { chnl_seo_name: channel_seo_name };
    await axios
      .post(getchannelbyseoname, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {

        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          chnl_id = resp_datalist[0].chnl_id;
          fetchShedules(props.val, chnl_id);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  }
  const isCurrentTimeInRange = (fromTime, toTime) => {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentPeriod = currentHours >= 12 ? 'pm' : 'am';
  
    const [fromHours, fromMinutes, fromPeriod] = fromTime.split(/:|\s/);
    const [toHours, toMinutes, toPeriod] = toTime.split(/:|\s/);
  
    const fromHour = parseInt(fromHours, 10);
    const fromMinute = parseInt(fromMinutes, 10);
    const toHour = parseInt(toHours, 10);
    const toMinute = parseInt(toMinutes, 10);
  
    // Convert 12-hour format to 24-hour format
    const convertedFromHour = fromPeriod === 'pm' ? fromHour + 12 : fromHour;
    const convertedToHour = toPeriod === 'pm' ? toHour + 12 : toHour;
  
    if (
      (currentPeriod === 'am' && fromPeriod === 'am' && toPeriod === 'am') || // All times are in AM
      (currentPeriod === 'pm' && fromPeriod === 'pm' && toPeriod === 'pm') || // All times are in PM
      (currentPeriod === 'am' && fromPeriod === 'am' && toPeriod === 'pm' && currentHours < 12) || // AM to PM range
      (currentPeriod === 'pm' && fromPeriod === 'am' && toPeriod === 'pm' && currentHours >= 12) // AM to PM range
    ) {
      if (
        (currentHours > convertedFromHour || (currentHours === convertedFromHour && currentMinutes >= fromMinute)) &&
        (currentHours < convertedToHour || (currentHours === convertedToHour && currentMinutes <= toMinute))
      ) {
        return true;
      }
    }
  
    return false;
  };

  const fetchShedules = async (propstvchannelid, initialchannelid) => {
    //   setIsLoading(true);
    
    if (propstvchannelid == undefined) { chnl_id = initialchannelid; } else {
      chnl_id = propstvchannelid;
    }
    //alert(chnl_id);
    const responseBody = { show_channel_id: chnl_id, weekday: props.weekdays };
    await axios
      .post(tvshedules_url, JSON.stringify(responseBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        console.log(res.data);
        var resp_data = res.data;
        if (resp_data.status === 'true' && resp_data != null) {
          var resp_datalist = resp_data.data;
          //  alert(JSON.stringify(resp_datalist));

          resp_datalist.forEach((list, index) => {

            // Perform any desired operation with each item
            const ourweeklydata = list[list.day];
       
            if (list.day == props.weekdays) {
             // finalinitialslider = list.active_slider_index;
              setFinalinitialslider(list.active_slider_index);
              ourmontimings(ourweeklydata);
            
            }
           

          });
        }else{
          ourmontimings([]);
        }

      })
      .catch((error) => {
        //setIsLoading(false);
        console.log(error);
      });
  };
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}

      ><div className='sfdsf'>

          <FaChevronRight className='sfdsf' /></div> </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><div className='sfdsf'>
          < FaChevronLeft className='sfdsf' />
        </div>
      </div>
    );
  }

//alert(JSON.stringify(ourmontimings));
//console.log(`initialSlide: ${initialSlide}`);

// 
// // // Find the first object in the array that matches the condition
// const matchingSetting = objslidersetting.find(oursetting => oursetting.weekday === props.weekdays);

// if (matchingSetting) {
//   finalinitialslider = matchingSetting.initialSlide;
// }
//console.log(initialSlide);
var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: false,
  delay: 3000,
  slidesToShow: 4,
  slidesToScroll: 1,
 // initialSlide:0,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },

    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]


};

useEffect(() => {
  
  setTimeout(()=>{
    getChannelFetchInfo(channel_seo_name, props.val);

   }, 1500)
}, [props.val, channel_seo_name]);
 // Replace with the number of clicks you want



//alert(JSON.stringify(finalinitialslider));initialSlide={0}
if(montimings.length > 0){
return (
  <>
   <Slider {...settings  }  initialSlide={0} >

      {montimings &&
        montimings.map(function (weekdata, index) {
         
          const item = weekdata.list;
          // let isNowPlaying = false;    
          // const currentWeekday = new Date().toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();
          // const isToday = props.weekdays.toLowerCase() === currentWeekday;
          // if (isToday) {
          //   isNowPlaying = isCurrentTimeInRange(weekdata.fromtime, weekdata.totime);
           
          // }
         // console.log(item);
        //  alert(JSON.stringify(weekdata.iscurrentdaytime));
          return (
            <div key={index}>
              <div className='schedule-card'>
                {item.typeschedule === "show" ? (
                  <h3 className='head-line-style'>{item.show_name}</h3>
                ) : (
                  <h3>{item.specialshowname}</h3>
                )}
                <p>{weekdata.fromtime} - {weekdata.totime}  
              
                {weekdata.iscurrentdaytime == true && <b className='watchType'> now showing </b>  } 
                {weekdata.ispremier == 1 ? (
            <div className='watchType'>R</div>
          ) : (
            <div className='watchType'></div>
          )}

                 </p>
              </div>
            </div>
          );
        })}
    </Slider>
  </>
);
      }else{

        return (
          <>
          <div>Loading...</div>
          </>
        );

      }
}

export default ModaySchedule