import React from 'react'
import {  Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "./TopNews.css"

import NewPlayIcon from '../../NewPlayIcon';


const TopNews = () => {
  return (
    <div>
     
        <Row><Col><h2 className="head headd">TOP NEWS</h2></Col><Col><Link to="/AllNewsPage" className='moed'>More +</Link></Col></Row>
        <Row>
            <Col lg={4} md={4} sm={4} xs={6}>
            <div className='topnew'>
                <Link to="/NewsView">
                    <div className='newsf'>
                    <img src="../assets/images/news-01.jpg"/>
                    <NewPlayIcon/>
                    </div>
               <h4>గతనెల 30వ తేదీన తన ఫేస్ బుక్ ఖాతా ద్వారా అయ్యప్పస్వామిపై </h4>
                
                </Link>
             
                
             
          
            </div>
            </Col>
            <Col lg={4} md={4} sm={4} xs={6}>
            <div className='topnew'>
                <Link to="/NewsView">
                <div className='newsf'>
                    <img src="../assets/images/news-02.jpg"/>
                    <NewPlayIcon/>
                    </div><h4>మహిళను కిడ్నాప్ చేయబోయిన దుండగుల</h4>
                </Link>
             
          
            </div>
            </Col>
            <Col lg={4} md={4} sm={4} xs={6}>
            <div className='topnew'>
                <Link to="/NewsView">
                <div className='newsf'>
                    <img src="../assets/images/news-03.jpg"/>
                    <NewPlayIcon/>
                    </div>
                
                <h4>హైదరాబాద్ లోని ఐటీకారిడార్ లో ట్రాఫిక్ సమస్యను</h4>
                </Link>
             
          
            </div>
            </Col>
            <Col lg={4} md={4} sm={4} xs={6}>
            <div className='topnew'>
                <Link to="/NewsView">
                <div className='newsf'>
                    <img src="../assets/images/news-04.jpg"/>
                    <NewPlayIcon/>
                    </div><h4>కరీంనగర్‌లో వింత వ్యాధి కలకలం,ఓకుటుంబంలో వరుస</h4>
                </Link>
             
          
            </div>
            </Col>
            <Col lg={4} md={4} sm={4} xs={6}>
            <div className='topnew'>
                <Link to="/NewsView">
                <div className='newsf'>
                    <img src="../assets/images/news-05.jpg"/>
                    <NewPlayIcon/>
                    </div><h4>యుద్ధంలో రష్యాకు ఉక్రెయిన్ లొంగబోదన్న జెలెన్ స్కీ</h4>
                </Link>
             
          
            </div>
            </Col>
            <Col lg={4} md={4} sm={4} xs={6}>
            <div className='topnew'>
                <Link to="/NewsView">
                <div className='newsf'>
                    <img src="../assets/images/news-06.jpg"/>
                    <NewPlayIcon/>
                    </div>
                <h4>స్టార్ ఆంధ్రప్రదేశ్ సీజన్ -1 పోటీల్లో భాగంగా విశాఖలో అందాల</h4>
                </Link>
             
          
            </div>
            </Col>
        </Row>
       
    </div>
  )
}

export default TopNews